
/**
 * Module dependencies.
 */

import { fetchPopularCollections } from './fetch-popular-collections';
import { useQuery } from 'react-query';
import { useRouter } from 'next/router';

/**
 * Action type.
 */

export const actionType = ({ locale }) => {
  return `${locale}-popular-collections`;
};

/**
 * `usePopularCollections` hook.
 */

function usePopularCollections() {
  const { locale } = useRouter();
  const {
    data,
    isLoading,
    isSuccess
  } = useQuery([actionType({ locale }), { locale }], () => {
    return fetchPopularCollections({ locale });
  });

  return {
    data,
    isLoading,
    isSuccess
  };
}

/**
 * Export `usePopularCollections` hook.
 */

export default usePopularCollections;
