
/**
 * Module dependencies.
 */

import { SimpleInterpolation, css } from 'styled-components';

/**
 * Export states.
 */

export const states = {
  action: (styles: TemplateStringsArray, ...interpolations: SimpleInterpolation[]) => css`
    &,
    &:active,
    &:focus,
    &:hover,
    &:visited {
      ${css(styles, ...interpolations)}
    }
  `,
  interaction: (styles: TemplateStringsArray, ...interpolations: SimpleInterpolation[]) => css`
    &.active,
    &:active,
    &:focus,
    &:hover {
      ${css(styles, ...interpolations)}
    }
  `
};
