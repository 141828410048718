
/**
 * Module dependencies.
 */

import { QueryObserverBaseResult, UseQueryOptions, useQuery } from 'react-query';
import { fetchConfigurations } from './fetch-configurations';

/**
 * Export `ConfigurationsData` type.
 */

export type ConfigurationsData = {
  complainBookUrl: string | null | undefined,
  complimentBookUrl: string | null | undefined,
  emailSupport: string,
  facebook: string | null | undefined,
  instagram: string | null | undefined,
  issuu: string | null | undefined,
  linkedin: string | null | undefined,
  ogImage: { fileUrl: string } | null | undefined,
  openingHours: string | null | undefined,
  ratingCount: string | null | undefined,
  ratingValue: string | null | undefined,
  wikipedia: string | null | undefined,
  youtube: string | null | undefined
};

/**
 * `Prpos` type.
 */

type Props = QueryObserverBaseResult<ConfigurationsData, unknown>;

/**
 * `Options` type.
 */

type Options = UseQueryOptions<any, unknown, any, "site-configurations">

/**
 * Action type.
 */

const actionType = 'site-configurations';

/**
 * `useConfigurations` hook.
 */

function useConfigurations(options?: Options): Props {
  const result = useQuery(actionType, () => fetchConfigurations(), options);

  return result;
}

/**
 * Export `useConfigurations` hook.
 */

export default useConfigurations;
