
/**
 * Module dependencies.
 */

import { addProjectClient } from './add-project-client';
import { addProjectFavorite } from './add-project-favorite';
import { createProject } from './create-project';
import { myRevigresRoutes } from 'src/core/routes';
import { removeProject } from './remove-project';
import { removeProjectClient } from './remove-project-client';
import { removeProjectFavorite } from './remove-project-favorite';
import { updateProject } from './update-project';
import { useAuth } from 'src/context/auth/context';
import { useCallback } from 'react';
import { useMutation } from 'react-query';
import { useRouter } from 'next/router';
import { useSnackbar } from 'src/context/snackbar/context';
import { useTranslate } from 'src/core/utils/translator';

/**
 * `ProjectFormData` type.
 */

type ProjectFormData = {
  description?: string,
  image?: string,
  title: string
}

/**
 * `ProjectFavoriteFormData` type.
 */

type ProjectFavoriteFormData = {
  favoriteId: string,
  productName: string,
  projectId: string,
  projectTitle: string
}

/**
 * `ProjectClientFormData` type.
 */

type ProjectClientFormData = {
  clientId: string,
  clientTitle: string,
  projectId: string,
  projectTitle: string
}

/**
 * Export `useProjectActions` hook.
 */

export function useProjectActions() {
  const { locale, translate } = useTranslate();
  const router = useRouter();
  const { showMessage } = useSnackbar();
  const { token, updateUser } = useAuth();
  const {
    isLoading: isCreatingProject,
    mutateAsync: createProjectMutation
  } = useMutation(createProject);

  const {
    isLoading: isUpdatingProject,
    mutateAsync: updateProjectMutation
  } = useMutation(updateProject);

  const { mutateAsync: removeProjectMutation } = useMutation(removeProject);
  const { mutateAsync: addProjectFavoriteMutation } = useMutation(addProjectFavorite);
  const { mutateAsync: removeProjectFavoriteMutation } = useMutation(removeProjectFavorite);
  const { mutateAsync: addProjectClientMutation } = useMutation(addProjectClient);
  const { mutateAsync: removeProjectClientMutation } = useMutation(removeProjectClient);
  const handleCreateProject = useCallback((formData: ProjectFormData) => {
    return createProjectMutation({ formData, token }, {
      onError: () => {
        showMessage(
          translate('my-revigres:projects.addProject.errorMessage'), {
            appearance: 'error'
          });
      },
      onSuccess: () => {
        showMessage(
          translate('my-revigres:projects.addProject.successMessage'), {
            appearance: 'success'
          });

        updateUser();
      }
    });
  }, [createProjectMutation, showMessage, token, translate, updateUser]);

  const handleUpdateProject = useCallback((formData, id) => {
    return updateProjectMutation({ formData, id, token }, {
      onError: () => {
        showMessage(translate('my-revigres:projects.editProject.errorMessage'), {
          appearance: 'error'
        });
      },
      onSuccess: () => {
        showMessage(translate('my-revigres:projects.editProject.successMessage'), {
          appearance: 'success'
        });
      }
    });
  }, [showMessage, token, translate, updateProjectMutation]);

  const handleRemoveProject = useCallback((id: string) => {
    return removeProjectMutation({ id, token }, {
      onError: () => {
        showMessage(translate('my-revigres:projects.removeProject.errorMessage'), {
          appearance: 'error'
        });
      },
      onSuccess: () => {
        showMessage(translate('my-revigres:projects.removeProject.successMessage'), {
          appearance: 'success'
        });

        router.push(myRevigresRoutes.projects, undefined, { locale });
        updateUser();
      }
    });
  }, [locale, removeProjectMutation, router, showMessage, token, translate, updateUser]);

  const handleAddProjectFavorite = useCallback((formData: ProjectFavoriteFormData) => {
    const { favoriteId, productName, projectId, projectTitle } = formData;

    return addProjectFavoriteMutation({ favoriteId, projectId, token }, {
      onError: () => {
        showMessage(
          translate('my-revigres:projects.addFavorite.errorMessage', {
            productName,
            projectTitle
          }), { appearance: 'error' });
      },
      onSuccess: () => {
        showMessage(
          translate('my-revigres:projects.addFavorite.successMessage', {
            productName,
            projectTitle
          }), { appearance: 'success' });
      }
    });
  }, [addProjectFavoriteMutation, showMessage, token, translate]);

  const handleRemoveProjectFavorite = useCallback((formData: ProjectFavoriteFormData) => {
    const { favoriteId, productName, projectId, projectTitle } = formData;

    return removeProjectFavoriteMutation({ favoriteId, projectId, token }, {
      onError: () => {
        showMessage(
          translate('my-revigres:projects.removeFavorite.errorMessage', {
            productName,
            projectTitle
          }), { appearance: 'error' });
      },
      onSuccess: () => {
        showMessage(
          translate('my-revigres:projects.removeFavorite.successMessage', {
            productName,
            projectTitle
          }), { appearance: 'success' });
      }
    });
  }, [removeProjectFavoriteMutation, showMessage, token, translate]);

  const handleAddProjectClient = useCallback((formData: ProjectClientFormData) => {
    const { clientId, clientTitle, projectId, projectTitle } = formData;

    return addProjectClientMutation({ clientId, projectId, token }, {
      onError: () => {
        showMessage(
          translate('my-revigres:projects.addClient.errorMessage', {
            clientTitle,
            projectTitle
          }), { appearance: 'error' });
      },
      onSuccess: () => {
        showMessage(
          translate('my-revigres:projects.addClient.successMessage', {
            clientTitle,
            projectTitle
          }), { appearance: 'success' });
      }
    });
  }, [addProjectClientMutation, showMessage, token, translate]);

  const handleRemoveProjectClient = useCallback((formData: ProjectClientFormData) => {
    const { clientId, clientTitle, projectId, projectTitle } = formData;

    return removeProjectClientMutation({ clientId, projectId, token }, {
      onError: () => {
        showMessage(
          translate('my-revigres:projects.removeClient.errorMessage', {
            clientTitle,
            projectTitle
          }), { appearance: 'error' });
      },
      onSuccess: () => {
        showMessage(
          translate('my-revigres:projects.removeClient.successMessage', {
            clientTitle,
            projectTitle
          }), { appearance: 'success' });
      }
    });
  }, [removeProjectClientMutation, showMessage, token, translate]);

  return {
    isCreatingProject,
    isUpdatingProject,
    onAddProjectClient: handleAddProjectClient,
    onAddProjectFavorite: handleAddProjectFavorite,
    onCreateProject: handleCreateProject,
    onRemoveProject: handleRemoveProject,
    onRemoveProjectClient: handleRemoveProjectClient,
    onRemoveProjectFavorite: handleRemoveProjectFavorite,
    onUpdateProject: handleUpdateProject
  };
}
