
/**
 * Module dependencies.
 */

import { color, units } from 'src/styles/utils';
import { ifProp, theme } from 'styled-tools';
import styled, { css } from 'styled-components';

/**
 * Export `FormGroup` styled component.
 */

export const FormGroup = styled.div<{ disabled?: boolean }>`
  margin-bottom: ${units(6)};
  position: relative;
  width: 100%;

  ${ifProp('disabled', css`
    cursor: default;
    pointer-events: none;
  `)}

  * {
    outline: none;
  }
`;

/**
 * Export `Label` styled component.
 */

export const Label = styled.label`
  color: ${color('grey900')};
  font-family: ${theme('typography.fontFamily.serif')};
  font-size: ${units(2.5)};
  font-weight: 400;
  line-height: ${units(4)};
  pointer-events: none;
  white-space: nowrap;
`;

/**
 * Export `FormControl` styled component.
 */

export const FormControl = styled.input<{
  as: any,
  disabled?: boolean,
  hasError?: boolean
}>`
  ${theme('typography.styles.p')}

  appearance: none;
  background: none;
  border: 0;
  border-bottom: 1px solid ${color('grey400')};
  border-radius: 0;
  box-shadow: none;
  color: ${color('grey900')};
  display: block;
  height: ${units(7)};
  padding: 13px 0;
  position: relative;
  transition: ${theme('animations.defaultTransition')};
  transition-property: border-color, color;
  width: 100%;

  &::placeholder {
    color: ${color('grey400')};
    transition: color ${theme('animations.defaultTransition')};
  }

  &:hover {
    border-bottom-color: ${color('grey900')};

    &::placeholder {
      color: ${color('grey900')};
    }
  }

  &:focus {
    border-bottom-color: ${color('brown400')};
    color: ${color('brown400')};

    &::placeholder {
      color: ${color('brown400')};
    }
  }

  ${ifProp('disabled', css`
    -webkit-text-fill-color: ${color('grey600')};
    border-bottom-color: ${color('grey600')};
    color: ${color('grey600')};

    &::placeholder {
      color: ${color('grey600')};
    }
  `)}

  ${ifProp('hasError', css`
    border-bottom-color: ${color('formError')};
    color: ${color('formError')};

    &::placeholder {
      color: ${color('formError')};
    }
  `)}

  ${ifProp({ as: 'textarea' }, css`
    height: auto;
    resize: vertical;
  `)}
`;

/**
 * Export `HelpText` styled component.
 */

export const HelpText = styled.div<{ hasError?: boolean }>`
  ${theme('typography.styles.small')}

  color: ${color('grey300')};
  padding-top: ${units(1)};

  ${ifProp('hasError', css`
    color: ${color('formError')};
  `)}
`;
