
/**
 * Module dependencies.
 */

import { Theme } from 'src/types/theme';
import { baseBreakpoint, orderedBreakpointsKeys } from 'src/styles/utils/breakpoints';
import { css } from 'styled-components';
import { media } from 'src/styles/utils/media';
import camelCase from 'lodash/camelCase';
import get from 'lodash/get';
import head from 'lodash/head';
import initial from 'lodash/initial';
import intersection from 'lodash/intersection';
import isNil from 'lodash/isNil';
import kebabCase from 'lodash/kebabCase';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import pull from 'lodash/pull';
import reduce from 'lodash/reduce';

/**
 * Directions properties.
 */

const directions = {
  horizontal: ['left', 'right'],
  vertical: ['bottom', 'top']
};

/**
 * Omissible DOM properties.
 */

const omissibleDOMProps = [
  'as',
  'children',
  'className',
  'forwardedComponent',
  'forwardedRef',
  'href',
  'id',
  'ref',
  'style',
  'theme'
];

/**
 * Omit event handlers.
 */

function omitEventHandlers(props: { [key: string]: string }) {
  return omitBy(props, (value, key: string) => {
    return /^on[A-Z][a-zA-Z]+$/.test(key);
  });
}

/**
 * Get first element in arrays intersection.
 */

function getIntersectionFirstElement(first: Array<string>, second: Array<string>, defaultUnmatched: string | boolean) {
  const matches = intersection(first, second);

  return matches.length ? head(matches) : defaultUnmatched;
}

/**
 * Convert camel case string to array of strings.
 */

const camelCaseToArray = (properties: string): Array<string> => kebabCase(properties).split('-');

/**
 * Create directional styles.
 */

function createDirectionalStyles(property: string, value: string, directions: Array<string>) {
  return directions.reduce((style, direction: string) => ({
    ...style,
    [`${property}-${direction}`]: value
  }), {});
}

/**
 * Get styles by breakpoint.
 *
 * This method creates an object with the properties agregated by breakpoint.
 * It also creates the directional fields if provided by prop.
 */

function getStylesByBreakpoint(properties, orderedBreakpoints: Array<string>) {
  return Object
    .keys(properties)
    .map(camelCaseToArray)
    .reduce((group, item: Array<string>) => {
      const value = get(properties, camelCase(item.toString()));

      if (isNil(value)) {
        return group;
      }

      const breakpoint = getIntersectionFirstElement(item, orderedBreakpoints, baseBreakpoint);
      const propertiesArray = breakpoint === baseBreakpoint ? item : initial(item);
      const property = kebabCase(propertiesArray.toString());
      const directionalFields = Object.keys(directions);
      const direction = getIntersectionFirstElement(item, directionalFields, false);
      let styleProps = { [property]: value };

      // Mutate `styleProps` with directions styles if `direction` is provided.
      if (direction) {
        const [property] = pull(item, direction, ...orderedBreakpoints);
        const propDirections = get(directions, direction);

        styleProps = createDirectionalStyles(property, value, propDirections);
      }

      return {
        ...group,
        [breakpoint]: {
          ...group[breakpoint],
          ...styleProps
        }
      };
    }, {});
}

/**
 * `StyleProp` type.
 */

type StyleProp = string | null | undefined;

/**
 * Render style object to styled string.
 */

function renderToStyle(styleObject: { [key: string]: StyleProp }, breakpoint: StyleProp) {
  const style = reduce(styleObject, (result: Array<string>, value: string, key: string) => {
    return [
      ...result,
      ...css`${key}: ${value};`
    ];
  }, []);

  if (!breakpoint) {
    return css`${style}`;
  }

  return media.min(breakpoint)`${style}`;
}

/**
 * Export `setStyledProps`.
 */

export const setStyledProps = (props: Theme, propsToOmit: Array<string> = []) => {
  const breakpoints = get(props, 'theme.breakpoints');

  if (!breakpoints) {
    throw new Error(`🚨 No breakpoints provided to the theme configuration.`);
  }

  const orderedBreakpoints = orderedBreakpointsKeys(breakpoints);
  const styleProps = omitEventHandlers(omit(props, [...omissibleDOMProps, ...propsToOmit]));
  const stylesByBreakpoint = getStylesByBreakpoint(styleProps, orderedBreakpoints);

  return orderedBreakpoints.reduce((previousStyle, key: string) => {
    const breakpoint = key !== baseBreakpoint ? key : null;
    const styleBreakpoint = get(stylesByBreakpoint, key);

    if (!styleBreakpoint) {
      return previousStyle;
    }

    return [
      ...previousStyle,
      ...renderToStyle(styleBreakpoint, breakpoint)
    ];
  }, []);
};
