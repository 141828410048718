
/**
 * Module dependencies.
 */

import {
  Name as MenuName,
  Size as MenuSize,
  Menu as MenuType,
  useMenu
} from 'src/core/menu';

import { color, media, units } from 'src/styles/utils';
import { ifNotProp, ifProp, prop, switchProp, theme } from 'styled-tools';
import { setFontStyle } from 'src/styles/utils/typography';
import { typography } from 'src/styles/type';
import { useTranslate } from 'src/core/utils/translator';
import React, { Fragment, ReactElement, useState } from 'react';
import RouterLink from 'src/components/core/links/router-link';
import styled, { css } from 'styled-components';

/**
 * Menu type sizes.
 */

const menuTypeSizes = {
  medium: {
    fontFamily: typography.fontFamily.serif,
    fontSize: 56,
    fontSizeMax: 56,
    fontSizeMin: 40,
    fontWeight: 300,
    letterSpacing: 0,
    lineHeight: 64
  },
  mediumSubmenu: {
    fontFamily: typography.fontFamily.serif,
    fontSize: 32,
    fontSizeMax: 32,
    fontSizeMin: 20,
    fontWeight: 300,
    letterSpacing: 0,
    lineHeight: 40
  },
  small: {
    fontFamily: typography.fontFamily.serif,
    fontSize: 32,
    fontSizeMax: 32,
    fontSizeMin: 24,
    fontWeight: 300,
    letterSpacing: 0,
    lineHeight: 40
  },
  smallSubmenu: {
    fontFamily: typography.fontFamily.serif,
    fontSize: 16,
    fontWeight: 300,
    letterSpacing: 0,
    lineHeight: 24
  }
};

/**
 * Delay.
 */

const delay = 150;

/**
 * `Props` type.
 */

type Props = {
  isMobile: boolean,
  onClickMobile: (name: MenuName) => void,
  startDelay: number,
  visible: boolean
};

/**
 * `Grid` styled component.
 */

const Grid = styled.div<{ menuActive: MenuName | null }>`
  display: grid;
  grid-gap: ${units(4)};
  grid-template-areas: 'list mobileSubmenu';
  grid-template-columns: repeat(2, 100%);
  max-width: ${theme('breakpoints.lg')}px;
  transition: ${theme('animations.defaultTransition')};

  ${media.min('md')`
    grid-template-areas: 'list .';
    grid-template-columns: 60% 40%;
  `}

  ${media.min('xl')`
    grid-template-columns: repeat(2, 50%);
  `}

  ${switchProp('menuActive', {
    revigres: css`
      ${media.min('md')`
        margin-bottom: ${units(25)};
      `}
    `,
    tools: css`
      ${media.min('md')`
        margin-bottom: ${units(25)};
      `}
    `
  })}
`;

/**
 * `List` styled components.
 */

const List = styled.ul`
  grid-area: list;
`;

/**
 * `ListItem` styled component.
 */

const ListItem = styled.li<{
  delay: number,
  visible: boolean
}>`
  opacity: ${ifProp('visible', 1, 0)};
  position: relative;
  transform: translateX(${ifProp('visible', 0, '-50%')});
  transition: ${theme('animations.menuTransition')};

  ${ifProp('visible', css`
    transition-delay: ${prop('delay', 0)}ms;
  `)}
`;

/**
 * `ListItemLink` styled component.
 */

const ListItemLink = styled(RouterLink)<{
  inactive?: boolean,
  visible: boolean
}>`
  align-items: center;
  color: ${color('white')};
  cursor: pointer;
  display: grid;
  grid-template-areas: 'title . line .';
  grid-template-columns: max-content ${units(4)} 1fr ${units(4)};
  padding: ${units(1.5)} 0;
  text-decoration: none;
  transition: color ${theme('animations.defaultTransition')};
  white-space: nowrap;

  ${ifProp('inactive', css`
    color: ${color.transparentize('white', 0.4)};
  `)}

  ${ifNotProp('visible', css`
    cursor: default;
    pointer-events: none;
  `)}

  ${media.min('lg')`
    padding: ${units(2)} 0;
  `}
`;

/**
 * `ListItemLinkTitle` styled component.
 */

const ListItemLinkTitle = styled.span<{ size: MenuSize }>`
  grid-area: title;

  ${switchProp('size', {
    medium: css`
      ${setFontStyle(menuTypeSizes.medium)}
    `,
    small: css`
      ${setFontStyle(menuTypeSizes.small)}
    `
  })}
`;

/**
 * `ListItemLinkLine` styled component.
 */

const ListItemLinkLine = styled.span<{ visible: boolean }>`
  background-color: ${color('green300')};
  display: block;
  grid-area: line;
  height: 1px;
  position: relative;
  transform: scaleX(0);
  transform-origin: left;
  transition: ${theme('animations.defaultTransition')};
  width: 100%;

  ${ifProp('visible', css`
    transform: scaleX(1);
  `)}
`;

/**
 * `SubmenuList` styled component.
 */

const SubmenuListWrapper = styled.div<{
  active: boolean,
  size: MenuSize
}>`
  left: 0;
  pointer-events: none;
  position: absolute;
  visibility: hidden;
  width: 200%;

  ${switchProp('size', {
    medium: css`
      top: 22px;

      ${media.min('lg')`
        top: 30px;
      `}
    `,
    small: css`
      top: ${units(2)};

      ${media.min('lg')`
        top: 25px;
      `}
    `
  })}

  ${ifProp('active', css`
    pointer-events: all;
    visibility: visible;
  `)}
`;

/**
 * `SubmenuList` styled component.
 */

const SubmenuList = styled.ul`
  left: 0;
  position: absolute;
  top: 0;
  transform: translateX(100%);
  width: 50%;
`;

/**
 * `SubmenuListItem` styled component.
 */

const SubmenuListItem = styled.li<{
  delay: number,
  size: MenuSize,
  visible: boolean
}>`
  opacity: ${ifProp('visible', 1, 0)};
  position: relative;
  transform: translateX(${ifProp('visible', 0, units(-6))});
  transition: ${theme('animations.menuTransition')};

  ${ifProp('visible', css`
    transition-delay: ${prop('delay', 0)}ms;
  `)}

  ${switchProp('size', {
    medium: css`
      &:not(:last-child) {
        margin-bottom: ${units(2)};
      }

      ${media.min('lg')`
        &:not(:last-child) {
          margin-bottom: ${units(3)};
        }
      `}

      ${media.min('xl')`
        &:not(:last-child) {
          margin-bottom: ${units(4)};
        }
      `}
    `,
    small: css`
      &:not(:last-child) {
        margin-bottom: ${units(1)};
      }
    `
  })}
`;

/**
 * `SubmenuListItemLink` styled component.
 */

const SubmenuListItemLink = styled(RouterLink)<{
  inactive?: boolean,
  size: MenuSize,
  visible: boolean
}>`
  color: ${color.transparentize('white', 0.4)};
  cursor: pointer;
  text-decoration: none;
  transition: color ${theme('animations.defaultTransition')};

  &:focus,
  &:hover {
    color: ${color('white')};
  }

  ${ifNotProp('visible', css`
    cursor: default;
    pointer-events: none;
  `)}

  ${switchProp('size', {
    medium: css`
      ${setFontStyle(menuTypeSizes.mediumSubmenu)}
    `,
    small: css`
      ${setFontStyle(menuTypeSizes.smallSubmenu)}
    `
  })}
`;

/**
 * `MainMenu` component.
 */

const MainMenu = (props: Props): ReactElement => {
  const { isMobile, onClickMobile, startDelay, visible } = props;
  const { locale, translate } = useTranslate();
  const [active, setActive] = useState<MenuName | null>(null);
  const menu = useMenu();

  return (
    <Grid menuActive={active}>
      <List>
        {menu.map(({ href, name, size, submenu, title }: MenuType, index: number) => (
          <ListItem
            delay={(index + 1) * delay + startDelay}
            key={name + index}
            onMouseEnter={() => setActive(name)}
            visible={visible}
          >
            <ListItemLink
              {...href ? { href, locale } : {
                as: 'div',
                ...isMobile ? { onClick: () => onClickMobile(name) } : {}
              }}
              inactive={active !== name && active !== null}
              visible={visible}
            >
              <ListItemLinkTitle size={size ?? 'medium'}>
                {translate(title)}
              </ListItemLinkTitle>

              {submenu && !isMobile && (
                <ListItemLinkLine visible={active === name} />
              )}
            </ListItemLink>

            {submenu && !isMobile && (
              <SubmenuListWrapper
                active={active === name}
                size={size ?? 'medium'}
              >
                <SubmenuList>
                  {submenu.map(({ href: sHref, title: sTitle }, sIndex: number) => (
                    <Fragment key={sTitle + sIndex}>
                      {sHref && (
                        <SubmenuListItem
                          delay={(sIndex + 1) * delay + 350}
                          size={size ?? 'medium'}
                          visible={active === name}
                        >
                          <SubmenuListItemLink
                            href={sHref}
                            locale={locale}
                            size={size ?? 'medium'}
                            visible={visible}
                          >
                            {translate(sTitle)}
                          </SubmenuListItemLink>

                        </SubmenuListItem>
                      )}
                    </Fragment>
                  ))}
                </SubmenuList>
              </SubmenuListWrapper>
            )}
          </ListItem>
        ))}
      </List>
    </Grid>
  );
};

/**
 * Export `MainMenu` component.
 */

export default MainMenu;
