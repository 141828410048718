
/**
 * Module dependencies.
 */

import { QueryObserverBaseResult, useQuery } from 'react-query';
import { fetchProduct } from './fetch-product';
import { useMemo } from 'react';
import { useRouter } from 'next/router';
import get from 'lodash/get';

/**
 * `Props` type.
 */

type Props<P> = QueryObserverBaseResult<P, unknown>;

/**
 * `Options` type.
 */

type Options<P> = {
  enabled?: boolean,
  initialData?: P | null,
  slug: string
};

/**
 * Action type.
 */

const actionType = ({ locale, slug }): string => {
  return `${locale}-product-${slug}`;
};

/**
 * `useProduct` hook.
 */

function useProduct<P>(options: Options<P>): Props<P> {
  const { locale, query } = useRouter();
  const variables = useMemo(() => ({
    hasPreview: get(query, 'preview'),
    locale,
    slug: options?.slug
  }), [locale, options.slug, query]);

  const result = useQuery([actionType(variables), variables], () => {
    return fetchProduct(variables);
  }, {
    enabled: options?.enabled,
    initialData: options?.initialData
  });

  return result;
}

/**
 * Export `useProduct` hook.
 */

export default useProduct;
