
/**
 * Module dependencies.
 */

import { ComparatorProduct } from 'src/types/products';
import { color, units } from 'src/styles/utils';
import { kioskRoutes } from 'src/core/routes';
import { routeTemplate, useLocalizedRoute } from 'src/core/utils/routes';
import { theme } from 'styled-tools';
import { useTranslate } from 'src/core/utils/translator';
import IconButton from 'src/components/core/buttons/icon-button';
import Image from 'src/components/core/image';
import RawHtml from 'src/components/core/raw-html';
import React, { ReactElement, useCallback } from 'react';
import RouterLink from 'src/components/core/links/router-link';
import Type from 'src/components/core/typography';
import closeIcon from 'src/assets/svg/close.svg';
import styled from 'styled-components';
import useBreakpoint from 'src/hooks/use-breakpoint';

/**
 * `Props` type.
 */

type Props = ComparatorProduct & {
  className?: string,
  isKioskCard?: boolean,
  onRemove?: () => void
};

/**
 * `Card` styled component.
 */

const Card = styled(RouterLink)`
  align-items: flex-start;
  background-color: ${color('white')};
  color: ${color('textColor')};
  display: grid;
  grid-template-areas:
    'image    . details . close'
    'image    . details . .';
  grid-template-columns: 42px 6px 1fr 6px 18px;
  grid-template-rows: max-content ${units(4)};
  padding-right: ${units(1.5)};
  transition: color ${theme('animations.defaultTransition')};

  &:hover,
  &:focus {
    color: ${color('brown400')};
  }
`;

/**
 * `ImageWrapper` styled component.
 */

const ImageWrapper = styled.div`
  grid-area: image;
  overflow: hidden;
  padding-bottom: 100%;
  position: relative;
`;

/**
 * `DetailsWrapper` styled component.
 */

const DetailsWrapper = styled.div`
  grid-area: details;
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.Small)`
  font-size: 10.5px;
  line-height: 12px;
`;

/**
 * `SpecsWrapper` styled component.
 */

const SpecsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: details;
`;

/**
 * `Format` styled component.
 */

const Format = styled(Title)`
  color: ${color('grey300')};
  font-size: 9px;
  margin: 0 !important;
  overflow-x: visible;
  white-space: nowrap;
`;

/**
 * `Finish` styled component.
 */

const Finish = styled(Format)`
  text-transform: uppercase;
`;

/**
 * `CloseIcon` styled component.
 */

const CloseIcon = styled(IconButton).attrs({
  icon: closeIcon,
  iconSize: units(1.5)
})`
  grid-area: close;
  transition: color ${theme('animations.defaultTransition')};

  &:hover,
  &:focus {
    color: ${color('brown400')};
  }
`;

/**
 * `ProductCard` component.
 */

const ProductCard = (props: Props): ReactElement => {
  const { locale, translate } = useTranslate();
  const routeResolver = useLocalizedRoute();
  const isMobile = useBreakpoint('ms', 'max');
  const {
    className,
    finish,
    format,
    formatApproximatedValue,
    imageUrl,
    isKioskCard,
    name,
    onRemove,
    slug
  } = props;

  const route = routeResolver('productDetails', { slug });
  const kioskRoute = routeTemplate(kioskRoutes.productDetails, { slug });
  const handleRemove = useCallback((event: Event) => {
    event.stopPropagation();
    event.preventDefault();
    onRemove();
  }, [onRemove]);

  return (
    <>
      <Card
        className={className}
        href={isKioskCard ? kioskRoute : route}
        locale={locale}
      >
        <ImageWrapper>
          <Image
            alt={name}
            layout={'fill'}
            objectFit={'cover'}
            src={imageUrl}
          />
        </ImageWrapper>

        <DetailsWrapper>
          <Title>
            {name}
          </Title>

          <SpecsWrapper>
            <RawHtml element={Format}>
              {`${format.name}${isMobile ? '<br>' : ' '}${formatApproximatedValue}` }
            </RawHtml>

            <Finish>
              {translate(`collections:finishes.${finish}`)}
            </Finish>
          </SpecsWrapper>
        </DetailsWrapper>

        <CloseIcon onClick={handleRemove} />
      </Card>
    </>
  );
};

/**
 * Export `ProductCard` component.
 */

export default ProductCard;
