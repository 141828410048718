
/**
 * Module dependencies.
 */

import Footer from 'src/components/footer';
import KioskNavbar from 'src/components/kiosk/navbar';
import Navbar from 'src/components/navbar';
import PageTransition from 'src/components/page-transition';
import React, { ReactElement, ReactNode } from 'react';

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode,
  isKiosk?: boolean
};

/**
 * `Layout` component.
 */

const Layout = ({ children, isKiosk }: Props): ReactElement => (
  <>
    {isKiosk ? <KioskNavbar /> : <Navbar />}

    <PageTransition>
      {children}

      {!isKiosk && (
        <Footer />
      )}
    </PageTransition>
  </>
);

/**
 * Export `Layout` component.
 */

export default Layout;
