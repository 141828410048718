
/**
 * Module dependencies.
 */

import { color, units } from 'src/styles/utils';
import { theme } from 'styled-tools';
import React, { ReactElement } from 'react';
import Svg from 'src/components/core/svg';
import arrowRightIcon from 'src/assets/svg/arrow-right.svg';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  name: string,
  onClick: () => void
};

/**
 * `Icon` styled component.
 */

const Icon = styled(Svg).attrs({ icon: arrowRightIcon, size: units(2.5) })`
  color: ${color('beige200')};
  margin-right: 26px;
  transition: ${theme('animations.defaultTransition')};
  transition-property: color, transform;
`;

/**
 * `Name` styled component.
 */

const Name = styled.span`
  ${theme('typography.styles.p')}

  transition: transform ${theme('animations.defaultTransition')};
`;

/**
 * `Link` styled component.
 */

const Link = styled.a`
  align-items: center;
  color: ${color('grey400')};
  cursor: pointer;
  display: flex;
  padding: ${units(3.5)} 0;
  transition: color ${theme('animations.defaultTransition')};
  white-space: nowrap;

  &:focus,
  &:hover {
    color: ${color('black')};

    ${Icon} {
      color: ${color('black')};
      transform: translateX(${units(1.5)});
    }

    ${Name} {
      transform: translateX(${units(0.75)});
    }
  }
`;

/**
 * `RecentSearchLink` component.
 */

const RecentSearchLink = ({ name, onClick }: Props): ReactElement => (
  <Link onClick={onClick}>
    <Icon />

    <Name>
      {name}
    </Name>
  </Link>
);

/**
 * Export `RecentSearchLink` component.
 */

export default RecentSearchLink;
