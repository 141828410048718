
/**
 * Module dependencies.
 */

import { breakpoints } from 'src/styles/breakpoints';
import useMediaQuery from './use-media-query';

/**
 * `Breakpoint` type.
 */

type BreakpointType = typeof breakpoints;
type Breakpoint = keyof BreakpointType;

/**
 * `Type` type.
 */

type Type = 'min' | 'max';

/**
 * Get breakpoint value.
 */

function getBreakpointValue(breakpoint: Breakpoint, type: Type): number {
  const value = breakpoints[breakpoint];

  return type === 'max' ? value - 1 : value;
}

/**
 * `useBreakpoint` hook.
 */

function useBreakpoint(breakpoint: Breakpoint, type: Type = 'min'): boolean {
  const breakpointValue = getBreakpointValue(breakpoint, type);
  const query = `(${type}-width: ${breakpointValue}px)`;

  return useMediaQuery(query);
}

/**
 * Export `useBreakpoint` hook.
 */

export default useBreakpoint;

