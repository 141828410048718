
/**
 * Module dependencies.
 */

import { color, units } from 'src/styles/utils';
import { fetchComparatorEmail } from 'src/api/app/products/fetch-comparator-email';
import { ifProp, theme } from 'styled-tools';
import { regexes } from 'src/core/utils/regexes';
import { useComparator } from 'src/context/comparator/context';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'src/context/snackbar/context';
import { useTranslate } from 'src/core/utils/translator';
import Button from 'src/components/core/buttons/button';
import Fill from 'src/components/core/layout/fill';
import IconButton from 'src/components/core/buttons/icon-button';
import Input from 'src/components/core/forms/fields/input';
import React, { ReactElement, useCallback } from 'react';
import arrowLeftIcon from 'src/assets/svg/arrow-left.svg';
import arrowRightIcon from 'src/assets/svg/arrow-right.svg';
import map from 'lodash/map';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  className?: string,
  isOpen?: boolean,
  onClose: () => void
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled(Fill)<{ isVisible?: boolean }>`
  background-color: ${color('grey100')};
  opacity: ${ifProp('isVisible', 1, 0)};
  padding: ${units(6)} 0;
  pointer-events: ${ifProp('isVisible', 'auto', 'none')};
  transition: opacity ${theme('animations.defaultTransition')};
  z-index: ${theme('zIndex.comparatorModal')};
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  display: grid;
  grid-template-areas:
    '. button button .     .'
    '. .      form   form  .';
  grid-template-columns: 1fr 2fr 1fr 5fr 3fr;
`;

/**
 * `StyledButton` styled component.
 */

const StyledButton = styled(Button)`
  grid-area: button;
  margin-bottom: 58px;
  width: max-content;
`;

/**
 * `Form` styled component.
 */

const Form = styled.form`
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-area: form;
  position: relative;
`;

/**
 * `SubmitButton` styled component.
 */

const SubmitButton = styled(IconButton).attrs({
  icon: arrowRightIcon,
  iconSize: units(3),
  type: 'submit'
})`
  color: ${color('textColor')};
  cursor: pointer;
  opacity: 1;
  position: absolute;
  right: 2px;
  text-decoration: none;
  top: ${units(1)};
  transition: ${theme('animations.defaultTransition')};
  transition-property: color, opacity;
`;

/**
 * `EmailModal` component.
 */

const EmailModal = (props: Props): ReactElement => {
  const { className, isOpen, onClose } = props;
  const { locale, translate } = useTranslate();
  const { products } = useComparator();
  const { showMessage } = useSnackbar();
  const {
    errors,
    formState,
    handleSubmit,
    register,
    reset
  } = useForm({
    defaultValues: { email: '' },
    mode: 'onBlur'
  });

  const onSubmit = useCallback(({ email }) => {
    const params = {
      email,
      products: map(products, ({ id }) => id)
    };

    fetchComparatorEmail({ locale, params })
      .then(() => {
        showMessage(translate('common:comparator.messages.success'), {
          appearance: 'success'
        });

        reset();
        onClose();
      }).catch(() => {
        showMessage(translate('common:comparator.messages.error'), {
          appearance: 'error'
        });
      });
  }, [locale, onClose, products, reset, showMessage, translate]);

  return (
    <Wrapper
      className={className}
      isVisible={isOpen}
    >
      <Grid>
        <StyledButton
          colorTheme={'white'}
          icon={arrowLeftIcon}
          onClick={onClose}
        >
          {translate('common:comparator.returnToComparator')}
        </StyledButton>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <Input
            error={errors?.email}
            id={'comparator-email'}
            name={'email'}
            placeholder={translate('forms:emailPlaceholder')}
            ref={register<any>({
              pattern: {
                message: translate('forms:emailPatternValidation'),
                value: regexes.email
              },
              required: {
                message: translate('forms:requiredField'),
                value: true
              }
            })}
            type={'email'}
          />

          <SubmitButton disabled={!formState.isDirty} />
        </Form>
      </Grid>
    </Wrapper>
  );
};

/**
 * Export `EmailModal` component.
 */

export default EmailModal;
