
/**
 * Module dependencies.
 */

import { color, units } from 'src/styles/utils';
import { isExternalRoute } from 'src/core/utils/routes';
import { useRouter } from 'next/router';
import React, { ReactElement, ReactNode } from 'react';
import RouterLink from 'src/components/core/links/router-link';
import Type from 'src/components/core/typography/index';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  description: ReactNode | null,
  href: string,
  title: string
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.a.attrs(({ href }) => ({
  as: href && !isExternalRoute(href) && RouterLink || 'a'
}))`
  align-items: center;
  cursor: pointer;
  display: grid;
  grid-template-columns: 100%;
  padding: ${units(2.5)} ${units(2)};
  text-decoration: none;

  &:focus,
  &:hover {
    background-color: ${color('white')};
  }
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.Small).attrs({ as: 'span', xSmall: true })`
  color: ${color('grey300')};
  text-transform: uppercase;
`;

/**
 * `Description` styled component.
 */

const Description = styled(Type.Paragraph).attrs({ as: 'span' })`
  color: ${color('grey400')};
`;

/**
 * `OtherCard` component.
 */

const OtherCard = (props: Props): ReactElement => {
  const { description, href, title } = props;
  const { locale } = useRouter();

  return (
    <Wrapper
      href={href}
      {...!isExternalRoute(href) ? { locale } : {
        rel: 'noopener',
        target: '_black'
      }}
    >
      <Title>
        {title}
      </Title>

      {description && (
        <Description>
          {description}
        </Description>
      )}
    </Wrapper>
  );
};

/**
 * Export `OtherCard` component.
 */

export default OtherCard;
