
/**
 * Module dependencies.
 */

import { color, media, units } from 'src/styles/utils';
import { switchProp, theme } from 'styled-tools';
import IconButton from 'src/components/core/buttons/icon-button';
import React, { ReactElement, ReactNode } from 'react';
import closeIcon from 'src/assets/svg/close.svg';
import styled, { css } from 'styled-components';

/**
 * Export `Appearance` type.
 */

export type Appearance = 'default' | 'error' | 'info' | 'success' | 'warning';

/**
 * `Props` type.
 */

type Props = {
  appearance?: Appearance,
  children: ReactNode,
  className?: string,
  onClose?: (event: any) => void
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div<{ appearance: Appearance }>`
  align-items: center;
  color: ${color('grey400')};
  cursor: pointer;
  display: grid;
  grid-gap: ${units(2)};
  grid-template-areas: 'content closeButton';
  grid-template-columns: 1fr ${units(4)};
  padding: ${units(3)} ${units(2)};
  position: relative;

  ${media.min('ms')`
    padding: 38px ${units(3)};
  `}

  ${switchProp('appearance', {
    default: css`
      background-color: ${color('grey400')};
      color: ${color('white')};
    `,
    error: css`
      background-color: ${color('error')};
    `,
    info: css`
      background-color: ${color('info')};
    `,
    success: css`
      background-color: ${color('success')};
    `,
    warning: css`
      background-color: ${color('warning')};
    `
  })}
`;

/**
 * `Content` styled component.
 */

const Content = styled.div`
  ${theme('typography.styles.p')}

  grid-area: content;
`;

/**
 * `CloseButton` styled component.
 */

const CloseButton = styled(IconButton)`
  grid-area: closeButton;
`;

/**
 * `Message` component.
 */

const Message = (props: Props): ReactElement => {
  const { appearance, children, className, onClose } = props;

  return (
    <Wrapper
      appearance={appearance}
      className={className}
      onClick={event => onClose(event)}
    >
      <Content>
        {children}
      </Content>

      {onClose && (
        <CloseButton
          colorTheme={appearance !== 'default' ? 'grey400' : 'white'}
          icon={closeIcon}
          iconSize={units(4)}
        />
      )}
    </Wrapper>
  );
};

/**
 * Default props.
 */

Message.defaultProps = {
  appearance: 'default'
};

/**
 * Export `Message` component.
 */

export default Message;
