
/**
 * Module dependencies.
 */

import { ReactNode, createPortal } from 'react-dom';
import { canUseDOM } from 'src/core/utils/dom';

/**
 * Export `createReactPortal`.
 */

export function createReactPortal(children: ReactNode, elementId: string) {
  if (!canUseDOM()) {
    return null;
  }

  return createPortal(children, document.getElementById(elementId));
}
