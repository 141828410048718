
/**
 * Module dependencies.
 */

import { units } from 'src/styles/utils';
import { useTranslate } from 'src/core/utils/translator';
import CollectionCard from 'src/components/search/search-results/cards/collection-card';
import Loading from 'src/components/core/loading';
import React, { ReactElement } from 'react';
import Type from 'src/components/core/typography';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import usePopularCollections from 'src/api/app/collections/use-popular-collections';

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  min-height: 250px;
  position: relative;
`;

/**
 * `StyledLoading` styled component.
 */

const StyledLoading = styled(Loading)`
  left: ${units(4)};
  top: ${units(4)};
`;

/**
 * `ListItem` styled component.
 */

const ListItem = styled.li`
  &:not(:last-child) {
    margin-bottom: ${units(2)};
  }
`;

/**
 * `EmptyList` styled component.
 */

const EmptyList = styled(Type.H6)`
  font-size: 10.5px;
  letter-spacing: 0.75px;
  line-height: 18px;
`;

/**
 * `PopularList` component.
 */

const PopularList = (): ReactElement => {
  const { data, isLoading, isSuccess } = usePopularCollections();
  const { translate } = useTranslate();

  return (
    <Wrapper>
      <StyledLoading active={isLoading} />

      {isSuccess && isEmpty(data) && (
        <EmptyList>
          {translate('common:search.popularSearches.noResults')}
        </EmptyList>
      )}

      {isSuccess && !isEmpty(data) && (
        <ol>
          {data.map((collection, index: number) => (
            <ListItem key={index}>
              <CollectionCard
                {...collection}
                isKioskCard
              />
            </ListItem>
          ))}
        </ol>
      )}
    </Wrapper>
  );
};

/**
 * Export `PopularList` component.
 */

export default PopularList;
