
/**
 * Module dependencies.
 */
import { color, media, transparentize, units } from 'src/styles/utils';
import { colors } from 'src/styles/colors';
import { useTranslate } from 'src/core/utils/translator';
import React, { ReactElement } from 'react';
import Svg from 'src/components/core/svg';
import Type from 'src/components/core/typography';
import imageIcon from 'src/assets/svg/image.svg';
import styled from 'styled-components';
import useBreakpoint from 'src/hooks/use-breakpoint';

/**
 * `Props` type.
 */

type Props = {
  className?: string
};

/**
 * `Card` styled component.
 */

const Card = styled.div`
  background-color: ${color('white')};
  display: grid;
  grid-template-areas: 'image details';
  grid-template-columns: ${units(3)} 1fr;
  grid-template-rows: max-content;

  ${media.min('md')`
    grid-template-columns: ${units(7)} 1fr;
  `}
`;

/**
 * `ImageWrapper` styled component.
 */

const ImageWrapper = styled.div`
  align-items: center;
  align-self: start;
  background-color: ${color('grey100')};
  display: flex;
  grid-area: image;
  height: ${units(3)};
  justify-content: center;
  position: relative;

  ${media.min('md')`
    height: ${units(7)};
  `}
`;

/**
 * `TextWrapper` styled component.
 */

const TextWrapper = styled.div`
  align-items: center;
  display: flex;
  grid-area: details;
  padding: ${units(0.5)} ${units(1)};
`;

/**
 * `Text` styled component.
 */

const Text = styled(Type.Small).attrs({ xSmall: true })`
  color: ${color('grey300')};
  line-height: 16px;
`;

/**
 * `EmptyProductCard` component.
 */

const EmptyProductCard = (props: Props): ReactElement => {
  const { translate } = useTranslate();
  const isMobile = useBreakpoint('ms', 'max');

  return (
    <Card className={props?.className}>
      <ImageWrapper>
        <Svg
          color={transparentize(colors.black, 0.16)}
          icon={imageIcon}
          size={!isMobile ? units(4) : units(2)}
        />
      </ImageWrapper>

      <TextWrapper>
        <Text>
          {translate('common:comparator.selectOtherProduct')}
        </Text>
      </TextWrapper>
    </Card>
  );
};

/**
 * Export `EmptyProductCard` component.
 */

export default EmptyProductCard;
