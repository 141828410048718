
/**
 * Module dependencies.
 */

import { ProductProps } from 'src/types/products';
import { color, units } from 'src/styles/utils';
import { ifProp } from 'styled-tools';
import Loading from 'src/components/core/loading';
import RawHtml from 'src/components/core/raw-html';
import React, { ReactElement } from 'react';
import Svg from 'src/components/core/svg';
import Type from 'src/components/core/typography';
import styled, { css } from 'styled-components';
import useProduct from 'src/api/app/products/use-product';
import useProductSpecs, { SpecProps } from 'src/hooks/use-product-specs';

/**
 * `Props` type.
 */

type Props = Pick<ProductProps, 'slug'> & {
  className?: string
  isVisible: boolean
};

/**
 * `Card` styled component.
 */

const Card = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

/**
 * `StyledLoading` styled component.
 */

const StyledLoading = styled(Loading)`
  padding-top: 100px;
  transform: translateX(-50%);
`;

/**
 * `SpecGroup` styled component.
 */

const SpecGroup = styled.div`
  display: grid;
  grid-template-columns: 54px 1fr;
  margin-bottom: ${units(1.5)};
`;

/**
 * `SpecInfo` styled component.
 */

const SpecInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 ${units(1.5)};
`;

/**
 * `SpecValue` styled component.
 */

const SpecValue = styled(Type.Small).attrs({ as: 'span' })<{ uppercase?: boolean }>`
  color: ${color('textColor')};
  font-size: 10.5px;
  letter-spacing: 0.38px;
  line-height: 12px;

  ${ifProp('uppercase', css`
    text-transform: uppercase;
  `)}
`;

/**
 * `SpecName` styled component.
 */

const SpecName = styled(SpecValue)`
  color: ${color('grey300')};
  text-transform: uppercase;
`;

/**
 * `ProductSpecsCard` component.
 */

const ProductSpecsCard = (props: Props): ReactElement => {
  const { className, isVisible } = props;
  const {
    data,
    isLoading
  } = useProduct<ProductProps>({
    enabled: isVisible,
    slug: props?.slug
  });

  const { normalizedProductSpecs } = useProductSpecs(data);

  return (
    <Card className={className}>
      <StyledLoading active={isLoading} />

      {normalizedProductSpecs && normalizedProductSpecs.map((specs: SpecProps, index: number) => {
        const { active, icon, name, uppercase, value } = specs;

        if (!active) {
          return null;
        }

        return (
          <SpecGroup key={index}>
            <Svg
              icon={icon}
              size={'52px'}
            />

            <SpecInfo>
              {name && (
                <SpecName>
                  {name}
                </SpecName>
              )}

              <SpecValue uppercase={uppercase}>
                <RawHtml>
                  {value}
                </RawHtml>
              </SpecValue>
            </SpecInfo>
          </SpecGroup>
        );
      })}
    </Card>
  );
};

/**
 * Export `ProductSpecsCard` component.
 */

export default ProductSpecsCard;
