
/**
 * `kioskApiPages`.
 */

const kioskApiPages = {
  kioskHome: 'kiosk-homepage'
};

/**
 * Export `es` api page routes.
 */

export const es = {
  ...kioskApiPages,
  aboutUs: 'sobre-nosotros',
  aboutUsSgiPolicy: 'politica-del-sistema-de-gestion-integrado',
  careers: 'contratacion',
  collection: null,
  collections: 'colecciones',
  contacts: 'contacto',
  cookiesPolicy: 'politica-de-cookies',
  downloads: 'descargas',
  environment: 'medioambiente',
  faq: null,
  faqs: 'faq',
  fundedProjects: null,
  highlights: 'lo-mas-destacado',
  history: 'historia',
  home: 'homepage',
  innovation: 'innovacion',
  inspiration: 'inspiracion',
  inspirationDetails: 'inspiracion-detalle',
  media: 'medios',
  portfolio: 'cartera',
  portfolioDetails: 'cartera-detalle',
  privacyPolicy: 'politica-de-privacidad',
  productContact: 'producto-contacto-nosotros',
  productDetails: null,
  recommend: 'recomendador',
  recommendResults: null,
  reportingChannel: 'canal-de-denuncia',
  socialResponsability: 'responsabilidad-social',
  socialResponsabilityAspects: null,
  socialResponsabilityCollaboratingStandards: null,
  socialResponsabilitySuppliers: null,
  storytiling: 'storytiling',
  storytilingDetails: 'storytiling-detalle',
  termsConditions: 'terminos-y-condiciones',
  toolsBimObjects: 'bim',
  toolsEnvironmentSimulator: 'simulador-ambiente',
  toolsProductComparator: 'comparator',
  toolsQuantitiesCalculator: 'calculadora',
  toolsSystem3d: '3d-system',
  whereToBuy: 'donde-comprar',
  whereToBuyResults: 'donde-comprar-resultados'
};
