
/**
 * Module dependencies.
 */

import { ActiveResults } from 'src/types/search';
import { color, units } from 'src/styles/utils';
import { prop, switchProp, theme } from 'styled-tools';
import { useTranslate } from 'src/core/utils/translator';
import React, { ReactElement } from 'react';
import Type from 'src/components/core/typography';
import styled, { css } from 'styled-components';
import useDimensions from 'react-cool-dimensions';

/**
 * `Props` type.
 */

type Props = {
  active: ActiveResults,
  onToggle: (name: ActiveResults) => void
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  background-color: ${color('beige200')};
  border-radius: ${units(3.5)};
  display: grid;
  grid-template-areas: '.      recent .      .      popular .';
  grid-template-columns: ${units(3)} max-content repeat(2, ${units(3)}) max-content ${units(3)};
  grid-template-rows: 50px;
  padding: 3px;
  position: relative;
  width: max-content;
`;

/**
 * `Label` styled component.
 */

const Label = styled(Type.Small).attrs({ as: 'span' })<{ gridArea: string }>`
  align-items: center;
  color: ${color('grey400')};
  cursor: pointer;
  display: flex;
  grid-area: ${prop('gridArea')};
  grid-row: 1;
  height: 100%;
  letter-spacing: 1px;
  position: relative;
  text-transform: uppercase;
  z-index: 1;
`;

/**
 * `Active` styled component.
 */

const Active = styled.span<{
  active: ActiveResults,
  widthPopular: number,
  widthRecent: number
}>`
  background-color: ${color('white')};
  border-radius: ${units(3.5)};
  bottom: 3px;
  left: 3px;
  position: absolute;
  top: 3px;
  transition: ${theme('animations.defaultTransition')};

  ${switchProp('active', {
    popular: css`
      transform: translateX(calc(100% - 12px));
      width: calc(${units(6)} + ${prop('widthPopular')}px);
    `,
    recent: css`
      transform: translateX(0);
      width: calc(${units(6)} + ${prop('widthRecent')}px);
    `
  })}
`;

/**
 * `SwitchButton` component.
 */

const SwitchButton = ({ active, onToggle }: Props): ReactElement => {
  const { translate } = useTranslate();
  const { ref: refRecent, width: widthRecent } = useDimensions();
  const { ref: refPopular, width: widthPopular } = useDimensions();

  return (
    <Wrapper>
      <Label
        gridArea={'recent'}
        onClick={() => onToggle('recent')}
        ref={refRecent}
      >
        {translate('common:search.labels.recent')}
      </Label>

      <Label
        gridArea={'popular'}
        onClick={() => onToggle('popular')}
        ref={refPopular}
      >
        {translate('common:search.labels.popular')}
      </Label>

      <Active
        active={active}
        widthPopular={widthPopular}
        widthRecent={widthRecent}
      />
    </Wrapper>
  );
};

/**
 * Export `SwitchButton` component.
 */

export default SwitchButton;
