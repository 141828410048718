
/**
 * Slip resistance values.
 */

const dim51130 = ['r9', 'r10', 'r11', 'r12', 'r13'];
const dim51097 = ['a', 'b', 'c'];
const ansiA137Dcof = ['≥ 0.42', '≥ 0.60', '≥ 0.65'];
const uneEnv126332003 = ['class 0', 'class 1', 'class 2', 'class 3'];
const pei = ['pei ii', 'pei iii', 'pei iv', 'pei v'];
const bs797622002 = ['ptv 0-24', 'ptv 23-35', 'ptv 36+', 'ptv 40+', 'ptv 75+'];
const upec = ['u3p3e3c2', 'u4p3e3c2', 'u4p4e3c2', 'u4p4se3c3', 'u4p2e3c2', 'u4p4+e3c2'];

/**
 * Export `slipResistanceKeys`.
 */

export const slipResistanceKeys = {
  ansiA137Dcof: 'ansi_a137_dcof',
  bs797622002: 'bs7976-2:2002',
  dim51097: 'dim_51097',
  dim51130: 'dim_51130',
  pei: 'pei',
  uneEnv126332003: 'une_env_126332003',
  upec: 'upec'
} as const;

/**
 * Export `slipResistanceValues`.
 */

export const slipResistanceValues = {
  [slipResistanceKeys.ansiA137Dcof]: ansiA137Dcof,
  [slipResistanceKeys.bs797622002]: bs797622002,
  [slipResistanceKeys.dim51097]: dim51097,
  [slipResistanceKeys.dim51130]: dim51130,
  [slipResistanceKeys.pei]: pei,
  [slipResistanceKeys.uneEnv126332003]: uneEnv126332003,
  [slipResistanceKeys.upec]: upec
};

/**
 * Export `slipResistanceIcons`.
 */

export const slipResistanceIcons = {
  ansiA137Dcof: require('src/assets/svg/slip-resistance/ansi-a137-dcof.svg'),
  bs797622002: require('src/assets/svg/slip-resistance/une-env-126332003.svg'),
  dim51097: require('src/assets/svg/slip-resistance/dim-51097.svg'),
  dim51130: require('src/assets/svg/slip-resistance/dim-51130.svg'),
  pei: require('src/assets/svg/slip-resistance/pei.svg'),
  uneEnv126332003: require('src/assets/svg/slip-resistance/une-env-126332003.svg'),
  upec: require('src/assets/svg/slip-resistance/upec.svg')
};

/**
 * Export `SlipResistanceKey` type.
 */

export type SlipResistanceKey = keyof typeof slipResistanceKeys;

/**
 * Export `SlipResistance` type.
 */

export type SlipResistance = {
  icon: string,
  key: SlipResistanceKey,
  values: string[]
};

/**
 * Export `slipResistance`.
 */

export const slipResistance: SlipResistance[] = [{
  icon: slipResistanceIcons.dim51130,
  key: 'dim51130',
  values: dim51130
}, {
  icon: slipResistanceIcons.dim51097,
  key: 'dim51097',
  values: dim51097
}, {
  icon: slipResistanceIcons.ansiA137Dcof,
  key: 'ansiA137Dcof',
  values: ansiA137Dcof
}, {
  icon: slipResistanceIcons.uneEnv126332003,
  key: 'uneEnv126332003',
  values: uneEnv126332003
}, {
  icon: slipResistanceIcons.pei,
  key: 'pei',
  values: pei
}, {
  icon: slipResistanceIcons.bs797622002,
  key: 'bs797622002',
  values: bs797622002
}, {
  icon: slipResistanceIcons.upec,
  key: 'upec',
  values: upec
}];
