
/**
 * Module dependencies.
 */
import { color, units } from 'src/styles/utils';
import { ifNotProp, ifProp, theme } from 'styled-tools';
import { kioskRoutes } from 'src/core/routes';
import { useComparator } from 'src/context/comparator/context';
import { useRouter } from 'next/router';
import { useTranslate } from 'src/core/utils/translator';
import Button from 'src/components/core/buttons/button';
import Collapse from 'src/components/core/collapse';
import Container from 'src/components/core/layout/container';
import EmailModal from './email-modal';
import EmptyProductCard from './empty-product-card';
import Fill from 'src/components/core/layout/fill';
import IconButton from 'src/components/core/buttons/icon-button';
import ProductCard from './product-card';
import ProductSpecsCard from './product-specs-card';
import React, {
  Fragment,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';

import Type from 'src/components/core/typography';
import arrowDownIcon from 'src/assets/svg/arrow-down.svg';
import arrowUpIcon from 'src/assets/svg/arrow-up.svg';
import emailIcon from 'src/assets/svg/email.svg';
import map from 'lodash/map';
import range from 'lodash/range';
import size from 'lodash/size';
import styled, { css } from 'styled-components';
import useBodyScroll from 'src/hooks/use-body-scroll';
import useBreakpoint from 'src/hooks/use-breakpoint';

/**
 * `Overlay` styled component.
 */

const Overlay = styled(Fill)<{ isOpen: boolean }>`
  position: fixed !important;
  transition: ${theme('animations.defaultTransition')};
  transition-property: background-color, visibility;
  z-index: ${theme('zIndex.comparatorOverlay')};

  ${ifNotProp('isOpen', css`
    background-color: transparent;
    pointer-events: none;
    visibility: hidden;
  `, css`
    background-color: ${color.transparentize('black', 0.3)};
    visibility: visible;
  `)}
`;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div<{ visible: boolean }>`
  background-color: ${color('white')};
  bottom: ${theme('dimensions.kioskNavbarHeight')}px;
  box-shadow: ${units(1)} 0 ${units(2)} 0 ${color.transparentize('black', 0.16)};
  left: 0;
  position: fixed;
  right: 0;
  transition: visibility ${theme('animations.defaultTransition')};
  z-index: ${theme('zIndex.comparator')};

  ${ifProp('visible', css`
    animation: ${theme('keyframes.slideInDown')} ${theme('animations.defaultTransition')} both;
    visibility: visible;
  `, css`
    animation: ${theme('keyframes.slideInUp')} ${theme('animations.defaultTransition')} both;
    pointer-events: none;
    visibility: hidden;
  `)}
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  display: grid;
  grid-template-areas:
    '. header   .'
    '. collapse .';
  grid-template-columns: 1fr 10fr 1fr;
`;

/**
 * `HeaderWrapper` styled component.
 */

const HeaderWrapper = styled.div`
  align-items: center;
  display: flex;
  grid-area: header;
  justify-content: space-between;
  padding: ${units(2.5)} 0;
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.Paragraph).attrs({ as: 'h3' })`
  font-size: 18px;
  line-height: 18px;
`;

/**
 * `MainListCollapse` styled component.
 */

const MainListCollapse = styled(Collapse)`
  grid-area: collapse;
`;

/**
 * `ListWrapper` styled component.
 */

const ListWrapper = styled.div`
  align-items: center;
  display: grid;
  grid-template-areas:
    'list  .     button'
    'specs specs specs';
  grid-template-columns: 8fr 1fr 3fr;
  grid-template-rows: repeat(2, max-content);
  margin-bottom: ${units(1.5)};
`;

/**
 * `List` styled component.
 */

const List = styled.div`
  display: grid;
  grid-area: list;
  grid-column-gap: ${units(1.5)};
  grid-template-columns: repeat(3, ${units(23)});
  margin-bottom: ${units(1)};
  padding-top: ${units(1)};
`;

/**
 * `ButtonWrapper` styled component.
 */

const ButtonWrapper = styled.div`
  align-self: flex-start;
  grid-area: button;
  margin-bottom: ${units(1)};
  position: relative;
`;

/**
 * `StyledButton` styled component.
 */

const StyledButton = styled(Button)<{ visible?: boolean }>`
  justify-self: flex-end;
  left: 0;
  opacity: ${ifProp('visible', 1, 0)};
  pointer-events: ${ifProp('visible', 'auto', 'none')};
  position: absolute;
  top: 0;
  transition: opacity ${theme('animations.fastTransition')};
  width: max-content;
`;

/**
 * `StyledProductCard` styled component.
 */

const StyledProductCard = styled(ProductCard)`
  &:not(:last-child) {
    border-right: 1px solid ${color('grey100')};
  }

  &:last-child {
    padding-right: 0;
  }
`;

/**
 * `StyledEmptyProductCard` styled component.
 */

const StyledEmptyProductCard = styled(EmptyProductCard)`
  &:not(:last-child) {
    border-right: 1px solid ${color('grey100')};
  }
`;

/**
 * `SpecsCollapse` styled component.
 */

const SpecsCollapse = styled(Collapse)`
  grid-area: specs;

  > div {
    border-top: 1px solid ${color('grey100')};
    display: grid;
    grid-template-areas: 'specsList . .';
    grid-template-columns: 8fr 1fr 3fr;
    max-height: ${units(110)};
    min-height: ${units(110)};
    overflow-x: hidden;
    overflow-y: auto;
    padding-top: ${units(4)};
  }
`;

/**
 * `SpecsList` styled component.
 */

const SpecsList = styled(List)`
  grid-area: specsList;
  padding-bottom: 86px;
`;

/**
 * `StyledProductSpecsCard` styled component.
 */

const StyledProductSpecsCard = styled(ProductSpecsCard)`
  border-right: 1px solid ${color('grey100')};
`;

/**
 * `KioskComparator` component.
 */

const KioskComparator = (): ReactElement | null => {
  const { translate } = useTranslate();
  const router = useRouter();
  const [isExpanded, setIsExpanded] = useState<boolean>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>();
  const { isOpen, onClose, onOpen, onRemove, products } = useComparator();
  const title = translate('common:comparator.compareProducts');
  const isMobile = useBreakpoint('ms', 'max');
  const maxCards = isMobile ? 2 : 3;
  const total = size(products);
  const totalEmptyCards = useMemo(() => {
    const emptyCards = maxCards - total;

    return emptyCards > 0 ? emptyCards : 0;
  }, [maxCards, total]);

  const handleCollapseComparator = useCallback(() => {
    if (isOpen) {
      setIsExpanded(false);
      onClose();

      return;
    }

    onOpen();
  }, [isOpen, onClose, onOpen]);

  const handleOpenSpecsArea = useCallback(() => {
    setIsExpanded(true);
  }, []);

  const handleClickOutside = useCallback(event => {
    event.stopPropagation();
    setIsModalOpen(false);
    handleCollapseComparator();
  }, [handleCollapseComparator]);

  useEffect(() => {
    if (total < 2) {
      setIsExpanded(false);
    }
  }, [total]);

  useBodyScroll({ off: isExpanded });

  if (router.pathname === kioskRoutes.toolsEnvironmentSimulator) {
    return null;
  }

  return (
    <>
      <Overlay
        isOpen={isExpanded}
        onClick={handleClickOutside}
      />

      <Wrapper visible={total > 0}>
        <Container hasPadding={false}>
          <Grid>
            <HeaderWrapper>
              <Title>
                {title}
              </Title>

              <IconButton
                icon={isOpen ? arrowDownIcon : arrowUpIcon}
                iconSize={units(3.5)}
                onClick={handleCollapseComparator}
              />
            </HeaderWrapper>

            <MainListCollapse isOpen={isOpen}>
              <ListWrapper>
                <List>
                  {map(products, product => (
                    <StyledProductCard
                      isKioskCard
                      key={product.id}
                      {...product}
                      onRemove={() => onRemove(product?.id)}
                    />
                  ))}

                  {map(range(0, totalEmptyCards), (index: number) => (
                    <StyledEmptyProductCard key={index} />
                  ))}
                </List>

                <ButtonWrapper>
                  <StyledButton
                    disabled={total < 2}
                    onClick={handleOpenSpecsArea}
                    visible={!isExpanded}
                  >
                    {translate('common:actions.compare')}
                  </StyledButton>

                  <StyledButton
                    colorTheme={'primary'}
                    disabled={total < 2}
                    icon={emailIcon}
                    onClick={() => setIsModalOpen(true)}
                    variant={'outlined'}
                    visible={isExpanded}
                  >
                    {translate('common:comparator.sendEmail')}
                  </StyledButton>
                </ButtonWrapper>

                <SpecsCollapse isOpen={isExpanded}>
                  <SpecsList>
                    {map(products, ({ slug }) => (
                      <StyledProductSpecsCard
                        isVisible={isExpanded}
                        key={slug}
                        slug={slug}
                      />
                    ))}

                    {map(range(0, totalEmptyCards), (index: number) => (
                      <Fragment key={index} />
                    ))}
                  </SpecsList>
                </SpecsCollapse>
              </ListWrapper>
            </MainListCollapse>
          </Grid>

          <EmailModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
          />
        </Container>
      </Wrapper>
    </>
  );
};

/**
 * Export `KioskComparator` component.
 */

export default KioskComparator;
