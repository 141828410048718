
/**
 * Module dependencies.
 */

import {
  Context,
  Dispatch,
  SetStateAction,
  createContext,
  useContext
} from 'react';

import { NavbarProps } from 'src/types/navbar';

/**
 * `Props` type.
 */

type Props = {
  setTheme?: Dispatch<SetStateAction<NavbarProps>>;
  theme?: NavbarProps;
}

/**
 * Export `NavbarThemeContext` context.
 */

export const NavbarThemeContext: Context<Props> = createContext({});

/**
 * Export `useNavbarThemeContext` hook.
 */

export const useNavbarThemeContext = () => useContext(NavbarThemeContext);
