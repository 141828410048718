
/**
 * Module dependencies.
 */

import { QueryObserverBaseResult, useQuery } from 'react-query';
import { convertCountriesToLocale } from 'src/core/utils/countries';
import { fetchCountries } from './fetch-countries';
import { useMemo } from 'react';
import { useRouter } from 'next/router';
import lowerCase from 'lodash/lowerCase';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';

/**
 * `Props` type.
 */

type Props<P> = QueryObserverBaseResult<P, unknown>;

/**
 * `Options` type.
 */

type Options<P> = {
  initialData?: P | null
};

/**
 * Action type.
 */

export const actionType = ({ locale }): string => {
  return `${locale}-countries`;
};

/**
 * `useCountries` hook.
 */

function useCountries<P>(options?: Options<P>): Props<P> {
  const { locale } = useRouter();
  const variables = useMemo(() => ({ locale }), [locale]);
  const result = useQuery([actionType(variables), variables], () => {
    return fetchCountries(variables);
  }, {
    initialData: options?.initialData
  });

  const normalizedAlpha2Codes: string[] = map(result?.data, ({ alpha2 }) => alpha2);
  const translatedCountries = convertCountriesToLocale(normalizedAlpha2Codes, locale);
  const orderedOptions = orderBy(translatedCountries, [({ name }) => lowerCase(name)], ['asc']);

  return {
    ...result,
    data: orderedOptions
  };
}

/**
 * Export `useCountries` hook.
 */

export default useCountries;
