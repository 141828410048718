
/**
 * Module dependencies.
 */

import { css } from 'styled-components';
import { ifProp } from 'styled-tools';
import { setFontStyle } from 'src/styles/utils/typography';

/**
 * Export font families.
 */

export const fontFamily = {
  sansSerif: '"Overpass", sans-serif',
  serif: '"Martel", serif'
};

/**
 * Type sizes.
 */

const typeSizes = {
  h1: {
    fontFamily: fontFamily.serif,
    fontSize: 128,
    fontSizeMax: 128,
    fontSizeMin: 44,
    fontWeight: 300,
    letterSpacing: 0,
    lineHeight: 128
  },
  h2: {
    fontFamily: fontFamily.serif,
    fontSize: 80,
    fontSizeMax: 80,
    fontSizeMin: 40,
    fontWeight: 300,
    letterSpacing: 0,
    lineHeight: 88
  },
  h3: {
    fontFamily: fontFamily.serif,
    fontSize: 56,
    fontSizeMax: 56,
    fontSizeMin: 32,
    fontWeight: 300,
    letterSpacing: 0,
    lineHeight: 64
  },
  h4: {
    fontFamily: fontFamily.serif,
    fontSize: 48,
    fontSizeMax: 48,
    fontSizeMin: 24,
    fontWeight: 300,
    letterSpacing: 0,
    lineHeight: 56
  },
  h5: {
    fontFamily: fontFamily.serif,
    fontSize: 32,
    fontSizeMax: 32,
    fontSizeMin: 20,
    fontWeight: 300,
    letterSpacing: 0,
    lineHeight: 40
  },
  h6: {
    fontFamily: fontFamily.serif,
    fontSize: 24,
    fontSizeMax: 24,
    fontSizeMin: 16,
    fontWeight: 400,
    letterSpacing: 0,
    lineHeight: 32
  },
  paragraph: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 16,
    fontWeight: 400,
    letterSpacing: 0,
    lineHeight: 24
  },
  small: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0,
    lineHeight: 20
  },
  smallH6: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 20,
    fontSizeMax: 20,
    fontSizeMin: 16,
    fontWeight: 300,
    letterSpacing: 0,
    lineHeight: 32
  },
  xSmall: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: 0,
    lineHeight: 20
  }
};

/**
 * `h1` style.
 */

const h1 = css`
  ${setFontStyle(typeSizes.h1)}
`;

/**
 * `h2` style.
 */

const h2 = css`
  ${setFontStyle(typeSizes.h2)}
`;

/**
 * `h3` style.
 */

const h3 = css`
  ${setFontStyle(typeSizes.h3)}
`;

/**
 * `h4` style.
 */

const h4 = css`
  ${setFontStyle(typeSizes.h4)}
`;

/**
 * `h5` style.
 */

const h5 = css`
  ${setFontStyle(typeSizes.h5)}
`;

/**
 * `h6` style.
 */

const h6 = css`
  ${ifProp('small', css`
    ${setFontStyle(typeSizes.smallH6)}
  `, css`
    ${setFontStyle(typeSizes.h6)}
  `)}
`;

/**
 * `paragraph` style.
 */

const paragraph = css`
  ${setFontStyle(typeSizes.paragraph)}
`;

/**
 * `small` style.
 */

const small = css`
  ${ifProp('xSmall', css`
    ${setFontStyle(typeSizes.xSmall)}
  `, css`
    ${setFontStyle(typeSizes.small)}
  `)}
`;

/**
 * Export types to be generated.
 */

const styles = {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p: paragraph, // eslint-disable-line id-length
  small
};

/**
 * Export `type`.
 */

export const typography = {
  fontFamily,
  styles,
  typeSizes
};
