
/**
 * Module dependencies.
 */

import { Theme } from 'src/types/theme';

/**
 * Export `baseUnit`.
 */

export const baseUnit = 8;

/**
 * Export `units`.
 */

export const units = (unit: number) => (props: Theme): string => {
  return `${unit * props.theme.baseUnit}px`;
};
