
/**
 * Module dependencies.
 */

import { color, units } from 'src/styles/utils';
import { theme } from 'styled-tools';
import RawHtml from 'src/components/core/raw-html';
import React, { ReactElement } from 'react';
import Trans from 'next-translate/Trans';
import styled from 'styled-components';

/**
 * Export `ContactLink` styled component.
 */

export const ContactLink = styled.a`
  color: ${color('grey400')};
  display: inline-block;
  line-height: ${units(3)};
  text-decoration: none;
  transition: ${theme('animations.defaultTransition')};
  transition-property: color;

  &:focus,
  &:hover {
    color: ${color.transparentize('grey400', 0.8)};
  }
`;

/**
 * Export `PhoneLink` component.
 */

export const PhoneLink = ({ children }: { children?: string }) => {
  return (
    <ContactLink href={`tel:${children}`}>
      {children}

      <RawHtml>{' <sup>[1]</sup>'}</RawHtml>
    </ContactLink>
  );
};

/**
 * Export `Link` component.
 */

export const Link = ({ i18nkey }: { i18nkey?: string }): ReactElement => {
  return (
    <Trans
      components={[<PhoneLink key={'phone'} />]}
      i18nKey={i18nkey}
    />
  );
};
