
/**
 * Export `downloadPdfFile`.
 */

export function downloadPdfFile(data, filename: string) {
  if (!(data instanceof Blob)) {
    return;
  }

  const blob = new Blob([data], { type: 'application/pdf' });
  const link = document.createElement('a');

  link.href = window.URL.createObjectURL(blob);
  link.download = `${filename}-${+new Date()}.pdf`;
  link.click();
}
