
/**
 * Module dependencies.
 */

import {
  authorizationHeader,
  handleRequestError,
  request
} from 'src/core/utils/requests';

import { getApiEndpoint } from 'src/core/utils/api-url-resolver';

/**
 * `Query` type.
 */

type Query = {
  formData: {
    description?: string,
    image?: string,
    title: string
  },
  token: string
};

/**
 * Export `createProject`.
 */

export async function createProject({ formData, token }: Query) {
  const endpoint = getApiEndpoint('getUserProjects');

  try {
    const { data } = await request.post(endpoint, formData, {
      headers: authorizationHeader(token)
    });

    return data?.data;
  } catch (error) {
    throw handleRequestError(error);
  }
}
