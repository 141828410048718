
/**
 * Module dependencies.
 */

import { getName, registerLocale } from 'i18n-iso-countries';
import map from 'lodash/map';

/**
 * Locales.
 */

const locales: string[] = process.env.NEXT_PUBLIC_LOCALES.split(',');

/**
 * Regist i18n locales.
 */

locales.map(locale => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  registerLocale(require(`i18n-iso-countries/langs/${locale}.json`));
});

/**
 * Export `convertCountriesToLocale`.
 */

export function convertCountriesToLocale(alpha2Codes: string[], locale: string) {
  return map(alpha2Codes, alpha2 => ({
    alpha2,
    name: getName(alpha2, locale)
  }));
}
