
/**
 * Module dependencies.
 */

import { ComponentType, ReactNode } from 'react';
import { setStyledProps } from 'src/styles/utils/props';
import get from 'lodash/get';
import isString from 'lodash/isString';
import styled, { css } from 'styled-components';
import upperFirst from 'lodash/upperFirst';

/**
 * `TypeElement` type.
 */

type TypeElement = {
  element: ReactNode,
  name: string
};

/**
 * `Props` types.
 */

type Props = {
  children: ReactNode
};

/**
 * `Typography` type.
 */

type Typography = {
  H1: ComponentType<Props>,
  H2: ComponentType<Props>,
  H3: ComponentType<Props>,
  H4: ComponentType<Props>,
  H5: ComponentType<Props>,
  H6: ComponentType<Props>,
  Label: ComponentType<Props>,
  Paragraph: ComponentType<Props>,
  Small: ComponentType<Props>
};

/**
 * Text elements.
 */

const textElements = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'label', 'small', {
  element: 'p',
  name: 'Paragraph'
}];

/**
 * Export `fontSettings`.
 */

export const fontSettings = `
  margin: 0;

  -webkit-font-feature-settings: 'calt', 'clig', 'kern', 'liga', 'locl', 'rlig';
  font-feature-settings: 'calt', 'clig', 'kern', 'liga', 'locl', 'rlig';
`;

/**
 * Parse type element.
 */

function parseTypeElement(element) {
  if (!isString(element)) {
    return element;
  }

  return {
    element,
    name: upperFirst(element)
  };
}

/**
 * Set type style.
 */

const setTypeStyle = (element: string) => ({ theme }) => {
  const typeStyles = get(theme, ['typography', 'styles', element], '');

  return css`
    ${fontSettings}
    ${typeStyles}
  `;
};

/**
 * `Type` component.
 */

const Type: any = textElements
  .reduce((result: Partial<Typography>, item: string | TypeElement): Partial<Typography> => {
    const { element, name } = parseTypeElement(item);
    const StyledElement = styled(element)`
      ${setTypeStyle(element)}
      ${setStyledProps}
    `;

    return {
      ...result,
      [name]: StyledElement
    };
  }, {});

/**
 * Export `Type` component.
 */

export default Type;
