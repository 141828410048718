
/**
 * Module dependencies.
 */

import { resolve } from 'url';

/**
 * Export `absoluteUrlResolver`.
 */

export function absoluteUrlResolver(path: string): string {
  return resolve(process.env.NEXT_PUBLIC_BASE_URL as string, path);
}

/**
 * Export `assetUrlResolve` util.
 */

export function assetUrlResolve(path: string): string {
  const baseUrl = process.env.NEXT_PUBLIC_FILES_DOMAIN;
  const enable = process.env.NEXT_PUBLIC_ENABLE_CDN === 'true';

  return baseUrl && enable ? resolve(baseUrl, path) : path;
}
