
/**
 * Module dependencies.
 */

import { ComparatorProduct } from 'src/types/products';
import { color, media, units } from 'src/styles/utils';
import { theme } from 'styled-tools';
import { useAuth } from 'src/context/auth/context';
import { useLocalizedRoute } from 'src/core/utils/routes';
import { useTranslate } from 'src/core/utils/translator';
import FavoritesMiddleman, {
  FavoriteState,
  favoriteStates
} from 'src/components/my-revigres/favorites/favorites-middleman';

import IconButton from 'src/components/core/buttons/icon-button';
import Image from 'src/components/core/image';
import RawHtml from 'src/components/core/raw-html';
import React, { ReactElement, useCallback, useState } from 'react';
import RouterLink from 'src/components/core/links/router-link';
import Type from 'src/components/core/typography';
import closeIcon from 'src/assets/svg/close.svg';
import favoriteAddIcon from 'src/assets/svg/favorite-add.svg';
import favoriteIcon from 'src/assets/svg/favorite.svg';
import styled from 'styled-components';
import useBreakpoint from 'src/hooks/use-breakpoint';

/**
 * `Props` type.
 */

type Props = ComparatorProduct & {
  className?: string,
  onRemove?: () => void
};

/**
 * `Card` styled component.
 */

const Card = styled(RouterLink)`
  align-items: flex-start;
  background-color: ${color('white')};
  color: ${color('textColor')};
  display: grid;
  grid-template-areas:
    'image    . details details close'
    '.        . details details .'
    'favorite . .       .       .';
  grid-template-columns: ${units(3)} ${units(0.5)} 1fr 10px ${units(3)};
  grid-template-rows: max-content ${units(7)} ${units(3)};
  padding-right: 10px;
  transition: color ${theme('animations.defaultTransition')};

  &:hover,
  &:focus {
    color: ${color('brown400')};
  }

  ${media.min('sm')`
    grid-template-areas:
      'image    . details . close'
      'image    . details . .'
      'favorite . .       . .';
    grid-template-rows: max-content ${units(4)} ${units(3)};
  `}

  ${media.min('md')`
    grid-template-columns: ${units(7)} ${units(1)} 1fr ${units(2)} ${units(3)};
    padding-right: ${units(2)};
  `}
`;

/**
 * `ImageWrapper` styled component.
 */

const ImageWrapper = styled.div`
  grid-area: image;
  overflow: hidden;
  padding-bottom: 100%;
  position: relative;

  ${media.max('ms')`
    align-self: flex-start;
  `}
`;

/**
 * `DetailsWrapper` styled component.
 */

const DetailsWrapper = styled.div`
  grid-area: details;
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.Small)`
  line-height: 16px;
`;

/**
 * `SpecsWrapper` styled component.
 */

const SpecsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: details;
  padding: ${units(1)} 0 0;

  ${media.min('ms')`
    padding: 0;
  `}
`;

/**
 * `Format` styled component.
 */

const Format = styled(Title)`
  color: ${color('grey300')};
  font-size: 12px;
  margin: 0 !important;
  overflow-x: visible;
  white-space: nowrap;
`;

/**
 * `Finish` styled component.
 */

const Finish = styled(Format)`
  text-transform: uppercase;
`;

/**
 * `CloseIcon` styled component.
 */

const CloseIcon = styled(IconButton).attrs({
  icon: closeIcon,
  iconSize: units(3)
})`
  grid-area: close;
  transition: color ${theme('animations.defaultTransition')};

  &:hover,
  &:focus {
    color: ${color('brown400')};
  }
`;

/**
 * `FavoriteIcon` styled component.
 */

const FavoriteIcon = styled(IconButton).attrs({ iconSize: units(3) })`
  grid-area: favorite;
  justify-self: flex-start;
  padding-top: 6px;
`;

/**
 * `ProductCard` component.
 */

const ProductCard = (props: Props): ReactElement => {
  const { locale, translate } = useTranslate();
  const routeResolver = useLocalizedRoute();
  const { isAuthenticated } = useAuth();
  const isMobile = useBreakpoint('ms', 'max');
  const {
    className,
    finish,
    format,
    formatApproximatedValue,
    id,
    imageUrl,
    name,
    onRemove,
    slug
  } = props;

  const [isFavorite, setIsFavorite] = useState<FavoriteState | null>(null);
  const [isFavoriteTriggered, setIsFavoriteTriggered] = useState<boolean>();
  const handleRemove = useCallback((event: Event) => {
    event.stopPropagation();
    event.preventDefault();
    onRemove();
  }, [onRemove]);

  const handleTriggerFavorite = useCallback((event: Event) => {
    event.stopPropagation();
    event.preventDefault();
    setIsFavoriteTriggered(true);
  }, []);

  return (
    <>
      <Card
        className={className}
        href={routeResolver('productDetails', { slug })}
        locale={locale}
      >
        <ImageWrapper>
          <Image
            alt={name}
            layout={'fill'}
            objectFit={'cover'}
            src={imageUrl}
          />
        </ImageWrapper>

        <DetailsWrapper>
          <Title>
            {name}
          </Title>

          <SpecsWrapper>
            <RawHtml element={Format}>
              {`${format.name}${isMobile ? '<br>' : ' '}${formatApproximatedValue}` }
            </RawHtml>

            <Finish>
              {translate(`collections:finishes.${finish}`)}
            </Finish>
          </SpecsWrapper>
        </DetailsWrapper>

        <CloseIcon onClick={handleRemove} />

        {isAuthenticated && (
          <FavoriteIcon
            disabled={!isFavorite || isFavoriteTriggered}
            icon={isFavorite === favoriteStates.on ? favoriteAddIcon : favoriteIcon}
            onClick={handleTriggerFavorite}
          />
        )}
      </Card>

      <FavoritesMiddleman
        isFavorite={isFavorite}
        isFavoriteTriggered={isFavoriteTriggered}
        onChangeFavoriteState={setIsFavorite}
        onChangeFavoriteTriggered={setIsFavoriteTriggered}
        productId={id}
        productName={name}
      />
    </>
  );
};

/**
 * Export `ProductCard` component.
 */

export default ProductCard;
