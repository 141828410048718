
/**
 * Module dependencies.
 */

import {
  acceptLanguageHeader,
  handleRequestError,
  request
} from 'src/core/utils/requests';

import { getApiEndpoint } from 'src/core/utils/api-url-resolver';

/**
 * `Query` type.
 */

type Query = {
  locale: string,
  params: {
    email: string,
    products: string[]
  }
};

/**
 * Export `fetchComparatorEmail`.
 */

export async function fetchComparatorEmail({ locale, params }: Query) {
  const endpoint = getApiEndpoint('getComparatorEmail');

  try {
    const { data } = await request.get(endpoint, {
      headers: acceptLanguageHeader(locale),
      params
    });

    return data?.data;
  } catch (error) {
    throw handleRequestError(error);
  }
}
