
/**
 * Module dependencies.
 */

import { AppUser, AuthUser } from 'src/types/user';
import { AuthContext, Token } from './context';
import { destroyCookie, parseCookies, setCookie } from 'nookies';
import { isProduction } from 'src/core/utils/environment';
import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';

import useAuthUser from 'src/api/auth/use-user';
import useUser from 'src/api/app/user/use-user';

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode
};

/**
 * `AuthProvider` provider.
 */

const AuthProvider = ({ children }: Props): ReactElement => {
  const cookies = parseCookies();
  const cookieToken = cookies?.token;
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isSignUpCompleted, setSignUpCompleted] = useState(false);
  const [token, setToken] = useState<Token>(cookieToken);
  const [user, setUser] = useState(null);
  const [authUser, setAuthUser] = useState(null);
  const {
    refetch: updateAppUser,
    remove: removerAppUser
  } = useUser<AppUser>(token, {
    enabled: !!token,
    onSuccess: data => {
      setUser(data);
    },
    // @ts-ignore
    retry: (_, error: any) => {
      if (error?.status === 401) {
        return;
      }
    }
  });

  const {
    remove: removerAuthUser
  } = useAuthUser<AuthUser>(token, {
    enabled: !!token,
    onSuccess: data => {
      setAuthUser(data);
    },
    // @ts-ignore
    retry: (_, error: any) => {
      if (error?.status === 401) {
        return;
      }
    }
  });

  // Update token.
  const handleUpdateToken = useCallback((token: Token) => {
    setToken(token);
    setCookie(null, 'token', token, {
      maxAge: 3650 * 24 * 60 * 60,
      path: '/',
      secure: isProduction()
    });
  }, []);

  // Clear token.
  const handleClearToken = useCallback(() => {
    setToken(null);
    destroyCookie(null, 'token');
  }, []);

  const value = useMemo(() => ({
    isAuthenticated: isLoggedIn,
    isSignUpCompleted,
    onClearToken: handleClearToken,
    onUpdateToken: handleUpdateToken,
    token,
    updateUser: updateAppUser,
    user: {
      authUser,
      user
    }
  }), [
    authUser,
    handleClearToken,
    handleUpdateToken,
    isLoggedIn,
    isSignUpCompleted,
    token,
    updateAppUser,
    user
  ]);

  useEffect(() => {
    if (authUser && token && !isLoggedIn) {
      setLoggedIn(true);
    }

    if (isLoggedIn && user && !isSignUpCompleted) {
      setSignUpCompleted(true);
    }

    if (!token && user) {
      removerAppUser();
      setUser(null);
    }

    if (!token && authUser) {
      removerAuthUser();
      setAuthUser(null);
    }

    if (!token) {
      setLoggedIn(false);
      setSignUpCompleted(false);
    }
  }, [
    authUser,
    isLoggedIn,
    isSignUpCompleted,
    removerAppUser,
    removerAuthUser,
    token,
    user
  ]);

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

/**
 * Export `AuthProvider` provider.
 */

export default AuthProvider;
