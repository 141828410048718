
/**
 * `kioskApiPages`.
 */

const kioskApiPages = {
  kioskHome: 'kiosk-homepage'
};

/**
 * Export `de` api page routes.
 */

export const de = {
  ...kioskApiPages,
  aboutUs: 'uber-revigres',
  aboutUsSgiPolicy: 'politik-des-integrierten-managementsystems',
  careers: 'bewerbung',
  collection: null,
  collections: 'kollektionen',
  contacts: 'kontakt',
  cookiesPolicy: 'cookie-richtlinie',
  downloads: 'downloads',
  environment: 'umwelt',
  faq: null,
  faqs: 'haufig-gestellte-frage',
  fundedProjects: null,
  highlights: 'highlights',
  history: 'geschichte',
  home: 'homepage',
  innovation: 'innovation',
  inspiration: 'inspiration',
  inspirationDetails: 'inspiration-detail',
  media: 'medien',
  portfolio: 'portfolio',
  portfolioDetails: 'portfolio-detail',
  privacyPolicy: 'datenschutzpolitik',
  productContact: 'produkt-kontakt-uns',
  productDetails: null,
  recommend: 'empfehlungstool',
  recommendResults: null,
  reportingChannel: 'berichtskanal',
  socialResponsability: 'soziale-verantwortung',
  socialResponsabilityAspects: null,
  socialResponsabilityCollaboratingStandards: null,
  socialResponsabilitySuppliers: null,
  storytiling: 'storytiling',
  storytilingDetails: 'storytiling-detail',
  termsConditions: 'allgemeine-geschaftsbedingungen',
  toolsBimObjects: 'bim',
  toolsEnvironmentSimulator: 'umgebungssimulator',
  toolsProductComparator: 'komparator',
  toolsQuantitiesCalculator: 'rechner',
  toolsSystem3d: '3d-system',
  whereToBuy: 'verkaufsstellen',
  whereToBuyResults: 'verkaufsstellen-resultate'
};
