
/**
 * Module dependencies.
 */

import { color, units } from 'src/styles/utils';
import { ifProp, theme } from 'styled-tools';
import React, { ReactElement, SyntheticEvent, forwardRef } from 'react';
import ReactSelect from 'react-select';
import Svg from 'src/components/core/svg';
import caretIcon from 'src/assets/svg/caret.svg';
import styled, { css } from 'styled-components';

/**
 * Export `ItemOption` type.
 */

export type ItemOption = {
  label: string,
  value: any
};

/**
 * Export `SelectProps` type.
 */

export type SelectProps = {
  className?: string,
  defaultValue?: any | null | undefined,
  disabled?: boolean,
  hasError?: boolean,
  id?: string,
  isClearable?: boolean,
  isLoading?: boolean,
  isMulti?: boolean,
  isSearchable?: boolean,
  name: string,
  onChange?: (event: SyntheticEvent) => void,
  options: ItemOption[],
  placeholder?: string,
  value?: string | null | undefined
};

/**
 * `StyledReactSelect` styled component.
 */

const StyledReactSelect = styled(ReactSelect)<{ hasError?: boolean }>`
  .react-select {
    &__single-value {
      ${theme('typography.styles.p')}

      color: ${color('grey900')};
      margin: 0;
      transition: color ${theme('animations.defaultTransition')};
    }

    &__control {
      background: none;
      border: 0;
      border-bottom: 1px solid ${color('grey400')};
      border-radius: 0;
      box-shadow: none;
      height: ${units(7)};
      transition: ${theme('animations.defaultTransition')};
      transition-property: border-color, color;

      &:hover {
        border-bottom-color: ${color('grey900')};

        &,
        .react-select__single-value,
        .react-select__placeholder {
          color: ${color('grey900')};
        }
      }

      &--is-focused {
        border-bottom-color: ${color('brown400')} !important;

        /* stylelint-disable no-descending-specificity */
        &,
        .react-select__single-value,
        .react-select__placeholder {
          color: ${color('brown400')} !important;
        }
        /* stylelint-enable no-descending-specificity */
      }

      &--is-disabled {
        border-bottom-color: ${color('grey600')} !important;

        /* stylelint-disable no-descending-specificity */
        &,
        .react-select__single-value,
        .react-select__placeholder,
        .react-select__indicators span {
          color: ${color('grey600')} !important;
        }
        /* stylelint-enable no-descending-specificity */
      }
    }

    /* stylelint-disable no-descending-specificity */
    &__placeholder {
      color: ${color('grey400')};
      transition: color ${theme('animations.defaultTransition')};
    }
    /* stylelint-enable no-descending-specificity */

    &__value-container {
      padding: 0;
    }

    &__menu {
      border: 0;
      border-radius: 0;
      box-shadow: none;
      margin: 0;
      padding: 0;

      &-list {
        border-bottom: 1px solid ${color('green100')};
        border-left: 1px solid ${color('green100')};
        border-right: 1px solid ${color('green100')};
        padding: 0;
      }
    }

    &__option {
      background-color: ${color('beige100')};
      color: ${color('grey400')};
      cursor: pointer;
      min-height: ${units(7)};
      outline: none;
      padding: ${units(2.5)} ${units(1)} ${units(2.25)};
      transition: ${theme('animations.defaultTransition')};
      transition-property: background-color, color;

      &:not(:last-child) {
        border-bottom: 1px solid ${color('green100')};
      }

      &:disabled {
        color: ${color('green100')};
      }

      &:hover,
      &:focus {
        background-color: ${color('white')};
        color: ${color('brown400')};
      }
    }
  }

  ${ifProp('hasError', css`
    .react-select__control {
      border-bottom-color: ${color('formError')} !important;
    }

    .react-select__single-value,
    .react-select__placeholder,
    .react-select__indicators span {
      color: ${color('formError')} !important;
    }
  `)}
`;

/**
 * `CaretIcon` styled component.
 */

const CaretIcon = styled(Svg).attrs({
  icon: caretIcon,
  size: '32px'
})`
  color: black;
`;

/**
 * `DropdownIndicator` component.
 */

const DropdownIndicator = (props: any): ReactElement => {
  const { innerProps: { ref, ...restInnerProps } } = props;

  return (
    <CaretIcon
      {...restInnerProps}
      ref={ref}
    />
  );
};

/**
 * `Select` component.
 */

const Select = (props: SelectProps, ref: any): ReactElement => {
  const { disabled, hasError, id, name, ...rest } = props;

  return (
    <StyledReactSelect
      aria-labelledby={`${name}_label`}
      className={'react-select'}
      classNamePrefix={'react-select'}
      components={{
        DropdownIndicator,
        IndicatorSeparator: '',
        LoadingIndicator: ''
      }}
      hasError={hasError}
      inputId={id ?? name}
      isDisabled={disabled}
      name={name}
      ref={ref}
      {...rest}
    />
  );
};

/**
 * Export `Select` component.
 */

export default forwardRef<SelectProps, any>(Select);
