
/**
 * Module dependencies.
 */
import { color, media, units } from 'src/styles/utils';
import { downloadPdfFile } from 'src/core/utils/downloads';
import { fetchProductsComparatorPdf } from 'src/api/app/products/fetch-products-comparator-pdf';
import { ifNotProp, ifProp, theme } from 'styled-tools';
import { useComparator } from 'src/context/comparator/context';
import { useTranslate } from 'src/core/utils/translator';
import Button from 'src/components/core/buttons/button';
import Collapse from 'src/components/core/collapse';
import Container from 'src/components/core/layout/container';
import EmptyProductCard from './empty-product-card';
import Fill from 'src/components/core/layout/fill';
import IconButton from 'src/components/core/buttons/icon-button';
import ProductCard from './product-card';
import ProductSpecsCard from './product-specs-card';
import React, {
  Fragment,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';

import Type from 'src/components/core/typography';
import arrowDownIcon from 'src/assets/svg/arrow-down.svg';
import arrowUpIcon from 'src/assets/svg/arrow-up.svg';
import downloadIcon from 'src/assets/svg/download.svg';
import map from 'lodash/map';
import range from 'lodash/range';
import size from 'lodash/size';
import styled, { css } from 'styled-components';
import useBodyScroll from 'src/hooks/use-body-scroll';
import useBreakpoint from 'src/hooks/use-breakpoint';
import useGTMEvent from 'src/hooks/use-gtm-event';

/**
 * `Overlay` styled component.
 */

const Overlay = styled(Fill)<{ isOpen: boolean }>`
  position: fixed !important;
  transition: ${theme('animations.defaultTransition')};
  transition-property: background-color, visibility;
  z-index: ${theme('zIndex.comparatorOverlay')};

  ${ifNotProp('isOpen', css`
    background-color: transparent;
    pointer-events: none;
    visibility: hidden;
  `, css`
    background-color: ${color.transparentize('black', 0.3)};
    visibility: visible;
  `)}
`;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div<{ visible: boolean }>`
  background-color: ${color('white')};
  bottom: 0;
  box-shadow: ${units(1)} 0 ${units(2)} 0 ${color.transparentize('black', 0.16)};
  left: 0;
  position: fixed;
  right: 0;
  transition: visibility ${theme('animations.defaultTransition')};
  z-index: ${theme('zIndex.comparator')};

  ${ifProp('visible', css`
    animation: ${theme('keyframes.slideInDown')} ${theme('animations.defaultTransition')} both;
    visibility: visible;
  `, css`
    animation: ${theme('keyframes.slideInUp')} ${theme('animations.defaultTransition')} both;
    pointer-events: none;
    visibility: hidden;
  `)}
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  ${media.min('ms')`
    display: grid;
    grid-template-areas:
      '. header   .'
      '. collapse .';
    grid-template-columns: 1fr 10fr 1fr;
  `}
`;

/**
 * `HeaderWrapper` styled component.
 */

const HeaderWrapper = styled.div`
  align-items: center;
  display: flex;
  grid-area: header;
  justify-content: space-between;
  padding: ${units(2)} 0;
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.Paragraph).attrs({ as: 'h3' })`
  font-size: ${units(2.5)};
`;

/**
 * `MainListCollapse` styled component.
 */

const MainListCollapse = styled(Collapse)`
  grid-area: collapse;
`;

/**
 * `ListWrapper` styled component.
 */

const ListWrapper = styled.div`
  align-items: center;
  display: grid;
  grid-template-areas:
    'list'
    'specs'
    'button';
  grid-template-columns: 100%;
  grid-template-rows: repeat(3, max-content);

  ${media.min('lg')`
    grid-template-areas:
      'list  .     button'
      'specs specs specs';
    grid-template-columns: 8fr 1fr 3fr;
    grid-template-rows: repeat(2, max-content);
  `}
`;

/**
 * `List` styled component.
 */

const List = styled.div`
  display: grid;
  grid-area: list;
  grid-column-gap: 10px;
  grid-template-columns: repeat(2, minmax(${units(17)}, 1fr));
  padding-bottom: ${units(4)};

  ${media.min('sm')`
    grid-template-columns: repeat(2, minmax(${units(22.5)}, 1fr));
  `}

  ${media.min('ms')`
    grid-column-gap: ${units(2)};
    grid-template-columns: repeat(3, minmax(${units(25)}, 1fr));
  `}

  ${media.min('md')`
    grid-template-columns: repeat(3, minmax(${units(30)}, 1fr));
  `}

  ${media.min('lg')`
    padding-bottom: ${units(2)};
  `}
`;

/**
 * `ButtonWrapper` styled component.
 */

const ButtonWrapper = styled.div`
  grid-area: button;
  justify-self: center;
  margin-bottom: ${units(2)};
  padding-top: ${units(2)};

  ${media.min('lg')`
    align-self: flex-start;
    justify-self: flex-end;
    margin: 0;
    padding: 0;
  `}
`;

/**
 * `StyledButton` styled component.
 */

const StyledButton = styled(Button)`
  justify-self: center;
  width: max-content;

  ${media.min('lg')`
    justify-self: flex-end;
  `}
`;

/**
 * `StyledProductCard` styled component.
 */

const StyledProductCard = styled(ProductCard)`
  &:not(:last-child) {
    border-right: 1px solid ${color('grey100')};
  }

  &:last-child {
    padding-right: 0;
  }
`;

/**
 * `StyledEmptyProductCard` styled component.
 */

const StyledEmptyProductCard = styled(EmptyProductCard)`
  &:not(:last-child) {
    border-right: 1px solid ${color('grey100')};
  }
`;

/**
 * `SpecsCollapse` styled component.
 */

const SpecsCollapse = styled(Collapse)`
  grid-area: specs;

  > div {
    border-top: 1px solid ${color('grey100')};
    display: grid;
    grid-template-areas: 'specsList';
    grid-template-columns: 100%;
    max-height: 40vh;
    min-height: 40vh;
    overflow-x: hidden;
    overflow-y: auto;
    padding-top: ${units(2)};

    ${media.min('ms')`
      max-height: 53vh;
      min-height: 53vh;
    `}

    ${media.min('lg')`
      grid-template-areas: 'specsList . .';
      grid-template-columns: 8fr 1fr 3fr;
    `}
  }
`;

/**
 * `SpecsList` styled component.
 */

const SpecsList = styled(List)`
  grid-area: specsList;

  ${media.max('lg')`
    padding-bottom: 0;
  `}
`;

/**
 * `StyledProductSpecsCard` styled component.
 */

const StyledProductSpecsCard = styled(ProductSpecsCard)`
  border-right: 1px solid ${color('grey100')};
`;

/**
 * `Comparator` component.
 */

const Comparator = (): ReactElement => {
  const { locale, translate } = useTranslate();
  const [isExpanded, setIsExpanded] = useState<boolean>();
  const [isLoadingPdf, setLoadingPdf] = useState<boolean>();
  const { isOpen, onClose, onOpen, onRemove, products } = useComparator();
  const title = translate('common:comparator.compareProducts');
  const isMobile = useBreakpoint('ms', 'max');
  const maxCards = isMobile ? 2 : 3;
  const sendGTMEvent = useGTMEvent();
  const total = size(products);
  const totalEmptyCards = useMemo(() => {
    const emptyCards = maxCards - total;

    return emptyCards > 0 ? emptyCards : 0;
  }, [maxCards, total]);

  const handleCollapseComparator = useCallback(() => {
    if (isOpen) {
      setIsExpanded(false);
      onClose();

      return;
    }

    onOpen();
  }, [isOpen, onClose, onOpen]);

  const handleOpenSpecsArea = useCallback(() => {
    setIsExpanded(true);
  }, []);

  const handleGetPdf = useCallback(() => {
    setLoadingPdf(true);
    const productsIds = map(products, ({ id }) => id);

    fetchProductsComparatorPdf({
      locale,
      products: productsIds
    }).then(({ data }) => {
      setLoadingPdf(false);
      sendGTMEvent('downloadComparator')

      return downloadPdfFile(data, title);
    });
  }, [locale, products, title, sendGTMEvent]);

  useEffect(() => {
    if (total < 2) {
      setIsExpanded(false);
    }
  }, [total]);

  useBodyScroll({ off: isExpanded });

  return (
    <>
      <Overlay isOpen={isExpanded} />

      <Wrapper visible={total > 0}>
        <Container>
          <Grid>
            <HeaderWrapper>
              <Title>
                {title}
              </Title>

              <IconButton
                icon={isOpen ? arrowDownIcon : arrowUpIcon}
                iconSize={units(4)}
                onClick={handleCollapseComparator}
              />
            </HeaderWrapper>

            <MainListCollapse isOpen={isOpen}>
              <ListWrapper>
                <List>
                  {map(products, product => (
                    <StyledProductCard
                      key={product.id}
                      {...product}
                      onRemove={() => onRemove(product?.id)}
                    />
                  ))}

                  {map(range(0, totalEmptyCards), (index: number) => (
                    <StyledEmptyProductCard key={index} />
                  ))}
                </List>

                <ButtonWrapper>
                  {!isExpanded ? (
                    <StyledButton
                      disabled={total < 2}
                      onClick={handleOpenSpecsArea}
                    >
                      {translate('common:actions.compare')}
                    </StyledButton>
                  ) : (
                    <StyledButton
                      disabled={total < 2}
                      icon={downloadIcon}
                      isLoading={isLoadingPdf}
                      onClick={handleGetPdf}
                      variant={'outlined'}
                    >
                      {translate('common:comparator.download')}
                    </StyledButton>
                  )}
                </ButtonWrapper>

                <SpecsCollapse isOpen={isExpanded}>
                  <SpecsList>
                    {map(products, ({ slug }) => (
                      <StyledProductSpecsCard
                        isVisible={isExpanded}
                        key={slug}
                        slug={slug}
                      />
                    ))}

                    {map(range(0, totalEmptyCards), (index: number) => (
                      <Fragment key={index} />
                    ))}
                  </SpecsList>
                </SpecsCollapse>
              </ListWrapper>
            </MainListCollapse>
          </Grid>
        </Container>
      </Wrapper>
    </>
  );
};

/**
 * Export `Comparator` component.
 */

export default Comparator;
