
/**
 * Module dependencies.
 */

import { useCallback, useReducer } from 'react';

/**
 * `Steps` type.
 */

type Steps = Array<string>;

/**
 * `Step` type.
 */

type Step = string;

/**
 * Action types.
 */

const actionTypes = {
  goTo: 'goTo',
  next: 'next',
  previous: 'previous'
};

/**
 * Is last step.
 */

function isLastStep(steps: Steps, step: Step) {
  return steps.indexOf(step) === steps.length - 1;
}

/**
 * Get last action.
 */

function getLastAction(steps: Steps, currentStep: Step, nextStep: Step) {
  if (steps.indexOf(currentStep) > steps.indexOf(nextStep)) {
    return actionTypes.previous;
  }

  return actionTypes.next;
}

/**
 * `useStepper` hook.
 */

function useStepper(steps: Steps) {
  const [{ lastAction, step }, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case actionTypes.next:
        return {
          lastAction: action.type,
          step: steps[steps.indexOf(state.step) + 1]
        };

      case actionTypes.previous:
        return {
          lastAction: action.type,
          step: steps[steps.indexOf(state.step) - 1]
        };

      case actionTypes.goTo:
        return {
          lastAction: action.payload.lastAction,
          step: steps[steps.indexOf(action.payload.step)]
        };

      default:
        return state;
    }
  }, {
    lastAction: 'initial',
    step: steps[0]
  });

  const nextStep = useCallback(() => dispatch({
    payload: null,
    type: actionTypes.next
  }), []);

  const previousStep = useCallback(() => dispatch({
    payload: null,
    type: actionTypes.previous
  }), []);

  const goToStep = useCallback((nextStep: Step) => dispatch({
    payload: {
      lastAction: getLastAction(steps, step, nextStep),
      step: nextStep
    },
    type: actionTypes.goTo
  }), [step, steps]);

  return {
    goToStep,
    isLastStep: isLastStep(steps, step),
    lastAction,
    nextStep,
    previousStep,
    step,
    stepIndex: steps.indexOf(step)
  };
}

/**
 * Export `useStepper` hook.
 */

export default useStepper;
