
/**
 * Module dependencies.
 */

import styled from 'styled-components';

/**
 * `Fill` component.
 */

const Fill = styled.div`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

/**
 * Export `Fill` component.
 */

export default Fill;
