
/**
 * Module dependencies.
 */

import { Name as MenuName, Size as MenuSize, useMenu } from 'src/core/menu';
import { color, units } from 'src/styles/utils';
import { ifNotProp, ifProp, prop, switchProp, theme } from 'styled-tools';
import { setFontStyle } from 'src/styles/utils/typography';
import { typography } from 'src/styles/type';
import { useTranslate } from 'src/core/utils/translator';
import React, { ReactElement, useState } from 'react';
import RouterLink from 'src/components/core/links/router-link';
import isEmpty from 'lodash/isEmpty';
import styled, { css } from 'styled-components';

/**
 * Menu type sizes.
 */

const menuTypeSizes = {
  medium: {
    fontFamily: typography.fontFamily.serif,
    fontSize: 40,
    fontWeight: 300,
    letterSpacing: 0,
    lineHeight: 48
  },
  small: {
    fontFamily: typography.fontFamily.serif,
    fontSize: 24,
    fontWeight: 300,
    letterSpacing: 0,
    lineHeight: 32
  }
};

/**
 * `Props` type.
 */

type Props = {
  activeMenu: MenuName,
  className?: string,
  visible: boolean
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div<{ visible: boolean }>`
  display: grid;
  grid-template-columns: 100%;
  position: relative;
  transition: ${theme('animations.menuTransition')};

  ${ifProp('visible', css`
    opacity: 1;
    pointer-events: all;
    transform: translateX(-100%);
  `, css`
    opacity: 0;
    pointer-events: none;
    transform: translateX(0);
  `)}
`;

/**
 * `List` styled component.
 */

const List = styled.ul<{ visible: boolean }>`
  grid-column: 1;
  grid-row: 1;
  pointer-events: none;

  ${ifProp('visible', css`
    pointer-events: all;
  `)}
`;

/**
 * `ListItem` styled component.
 */

const ListItem = styled.li<{
  delay: number,
  visible: boolean
}>`
  opacity: ${ifProp('visible', 1, 0)};
  position: relative;
  transform: translateX(${ifProp('visible', 0, '50%')});
  transition: ${theme('animations.menuTransition')};

  &:not(:last-child) {
    margin-bottom: ${units(3)};
  }

  ${ifProp('visible', css`
    transition-delay: ${prop('delay', 0)}ms;
  `)}
`;

/**
 * `ListItemLink` styled component.
 */

const ListItemLink = styled(RouterLink)<{
  inactive?: boolean,
  size: MenuSize,
  visible: boolean
}>`
  color: ${color('white')};
  cursor: pointer;
  text-decoration: none;
  transition: color ${theme('animations.defaultTransition')};

  ${switchProp('size', {
    medium: css`
      ${setFontStyle(menuTypeSizes.medium)}
    `,
    small: css`
      ${setFontStyle(menuTypeSizes.small)}
    `
  })}

  ${ifProp('inactive', css`
    color: ${color.transparentize('white', 0.4)};
  `)}

  ${ifNotProp('visible', css`
    cursor: default;
    pointer-events: none;
  `)}
`;

/**
 * `MobileMenu` component.
 */

const MobileMenu = (props: Props): ReactElement => {
  const { activeMenu, className, visible } = props;
  const { locale, translate } = useTranslate();
  const [active, setActive] = useState<number | null>(null);
  const menu = useMenu();

  return (
    <Wrapper
      className={className}
      visible={visible}
    >
      {menu.map(({ name, size, submenu }) => !isEmpty(submenu) && (
        <List
          key={name}
          visible={activeMenu === name}
        >
          {submenu && submenu.map(({ href, title }, index: number) => (
            <>
              {href && (
                <ListItem
                  delay={(index + 1) * 150}
                  key={index}
                  onMouseEnter={() => setActive(index)}
                  onMouseLeave={() => setActive(null)}
                  visible={visible && activeMenu === name}
                >
                  <ListItemLink
                    href={href}
                    inactive={active !== index && active !== null}
                    locale={locale}
                    size={size ?? 'medium'}
                    visible={visible}
                  >
                    {translate(title)}
                  </ListItemLink>
                </ListItem>
              )}
            </>
          ))}
        </List>
      ))}
    </Wrapper>
  );
};

/**
 * Export `MobileMenu` component.
 */

export default MobileMenu;
