
/**
 * Module dependencies.
 */

import {
  acceptLanguageHeader,
  handleRequestError,
  request
} from 'src/core/utils/requests';

import { getApiEndpoint } from 'src/core/utils/api-url-resolver';

/**
 * `Query` type.
 */

type Query = {
  locale: string,
  products: string[]
}

/**
 * Export `fetchProductsComparatorPdf`.
 */

export async function fetchProductsComparatorPdf({ locale, products }: Query) {
  const endpoint = getApiEndpoint('getProductsComparatorPdf');

  try {
    return await request.get(endpoint, {
      headers: acceptLanguageHeader(locale),
      params: {
        products
      },
      responseType: 'blob'
    });
  } catch (error) {
    handleRequestError(error);
  }
}
