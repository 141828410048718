
/**
 * Module dependencies.
 */

import { useLocalizedRoute } from 'src/core/utils/routes';
import { useMemo } from 'react';
import useConfigurations from 'src/api/app/configurations/use-configurations';

/**
 * Export `Name` type.
 */

export type Name = 'products' | 'inspiration' | 'whereToBuy' | 'contacts' | 'tools' | 'revigres';

/**
 * Export `Size` type.
 */

export type Size = 'small' | 'medium';

/**
 * Export `SubMenu` type.
 */

export type SubMenu = {
  href: string | null,
  title: string
};

/**
 * Export `Menu` type.
 */

export type Menu = {
  href?: string | null,
  name: Name,
  size?: Size,
  submenu?: SubMenu[] | null,
  title: string
};

/**
 * Export `useFooterLinks`.
 */

export function useFooterLinks(): SubMenu[] {
  const { data } = useConfigurations();
  const routeResolver = useLocalizedRoute();
  const footerLinks = useMemo(() => {
    return [{
      href: routeResolver('privacyPolicy'),
      title: 'menu:privacyPolicy'
    }, {
      href: routeResolver('cookiesPolicy'),
      title: 'menu:cookiesPolicy'
    }, {
      href: routeResolver('termsConditions'),
      title: 'menu:termsConditions'
    }, {
      href: data?.complainBookUrl,
      title: 'menu:complainBook'
    }, {
      href: routeResolver('reportingChannel'),
      title: 'menu:reportingChannel'
    }, {
      href: data?.complimentBookUrl,
      title: 'menu:complimentBook'
    }];
  }, [data, routeResolver]);

  return footerLinks;
}

/**
 * Export `useMenu`.
 */

export function useMenu(): Menu[] {
  const routeResolver = useLocalizedRoute();
  const menu: Menu[] = useMemo(() => [{
    name: 'products',
    submenu: [{
      href: routeResolver('collections'),
      title: 'menu:productsMenu.collections'
    }, {
      href: routeResolver('recommend'),
      title: 'menu:productsMenu.recommender'
    }, {
      href: routeResolver('highlights'),
      title: 'menu:productsMenu.highlights'
    }],
    title: 'menu:products'
  }, {
    name: 'inspiration',
    submenu: [{
      href: routeResolver('inspiration'),
      title: 'menu:getInspiredMenu.ambients'
    }, {
      href: routeResolver('portfolio'),
      title: 'menu:getInspiredMenu.portfolio'
    }, {
      href: routeResolver('storytiling'),
      title: 'menu:getInspiredMenu.storytiling'
    }],
    title: 'menu:getInspired'
  }, {
    href: routeResolver('whereToBuy'),
    name: 'whereToBuy',
    title: 'menu:whereToBuy'
  }, {
    href: routeResolver('contacts'),
    name: 'contacts',
    title: 'menu:contacts'
  }, {
    name: 'tools',
    size: 'small',
    submenu: [{
      href: routeResolver('toolsEnvironmentSimulator'),
      title: 'menu:toolsMenu.environmentSimulator'
    }, {
      href: routeResolver('toolsSystem3d'),
      title: 'menu:toolsMenu.3dSystem'
    }, {
      href: 'https://pro.revigres.pt/pt/',
      title: 'menu:toolsMenu.b2b'
    }, {
      href: routeResolver('toolsBimObjects'),
      title: 'menu:toolsMenu.bim'
    }, {
      href: routeResolver('toolsProductComparator'),
      title: 'menu:toolsMenu.comparator'
    }, {
      href: routeResolver('downloads'),
      title: 'menu:toolsMenu.downloads'
    }, {
      href: routeResolver('faqs'),
      title: 'menu:toolsMenu.faqs'
    }],
    title: 'menu:tools'
  }, {
    name: 'revigres',
    size: 'small',
    submenu: [{
      href: routeResolver('aboutUs'),
      title: 'menu:revigresMenu.aboutUs'
    }, {
      href: routeResolver('history'),
      title: 'menu:revigresMenu.history'
    }, {
      href: routeResolver('innovation'),
      title: 'menu:revigresMenu.innovation'
    }, {
      href: routeResolver('environment'),
      title: 'menu:revigresMenu.environmentSustainability'
    }, {
      href: routeResolver('socialResponsability'),
      title: 'menu:revigresMenu.socialResponsability'
    }, {
      href: routeResolver('media'),
      title: 'menu:revigresMenu.media'
    }, {
      href: routeResolver('careers'),
      title: 'menu:revigresMenu.careers'
    }, {
      href: routeResolver('fundedProjects'),
      title: 'menu:revigresMenu.fundedProjects'
    }],
    title: 'menu:revigres'
  }], [routeResolver]);

  return menu;
}
