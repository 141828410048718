
/**
 * Module dependencies.
 */

import { Controller } from 'react-hook-form';
import {
  FormGroup,
  HelpText,
  Label
} from 'src/components/core/forms/fields-styled-components';

import BaseSelect, { SelectProps } from 'src/components/core/select';
import React, { ReactElement } from 'react';
import isEmpty from 'lodash/isEmpty';

/**
 * `Error` type.
 */

type Error = {
  message: string,
  type: string
};

/**
 * `Props` type.
 */

type Props = SelectProps & {
  control: any,
  error?: Error | null | undefined,
  helpText?: string,
  id?: string,
  label: string,
  rules?: {
    [key: string]: any
  }
};

/**
 * `Select` component.
 */

const Select = (props: Props): ReactElement => {
  const {
    className,
    control,
    disabled,
    error,
    helpText,
    id,
    label,
    name,
    rules,
    ...rest
  } = props;

  const hasError = !isEmpty(error);

  return (
    <FormGroup
      className={className}
      disabled={disabled}
    >
      <Label htmlFor={id ?? name}>
        {label}
      </Label>

      <Controller
        control={control}
        id={id ?? name}
        name={name}
        render={({ onBlur, onChange, value }) => (
          <BaseSelect
            hasError={hasError}
            id={id ?? name}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            {...rest}
          />
        )}
        rules={rules}
      />

      {hasError && (
        <HelpText hasError>
          {error?.message}
        </HelpText>
      )}

      {helpText && !hasError && (
        <HelpText>
          {helpText}
        </HelpText>
      )}
    </FormGroup>
  );
};

/**
 * Export `Select` component.
 */

export default Select;
