
/**
 * Export `zIndex`.
 */

export const zIndex = {
  comparator: 101,
  comparatorModal: 102,
  comparatorOverlay: 100,
  cookiesBar: 102,
  filters: 105,
  hamburgerMenu: 104,
  modal: 106,
  navbar: 102,
  pageTransition: 107,
  sidebar: 103,
  snackbar: 108
};
