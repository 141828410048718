/**
 * Export `getApplicationsSorted`.
 */

export const getApplicationsSorted = (items) => {
  return items
    .filter(item => item.slug)
    .map(item => ({
      name: item.name,
      slug: item.slug
    }))
    .sort((firstItem, secondItem) => firstItem.name.localeCompare(secondItem.name));
};
