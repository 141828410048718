
/**
 * Module dependencies.
 */

import {
  authorizationHeader,
  handleRequestError,
  request
} from 'src/core/utils/requests';

import { getApiEndpoint } from 'src/core/utils/api-url-resolver';

/**
 * `Query` type.
 */

type Query = {
  id: string,
  note: string,
  token: string
};

/**
 * Export `updateFavorite`.
 */

export async function updateFavorite({ id, note, token }: Query) {
  const endpoint = getApiEndpoint('getUserFavorite', { id });

  try {
    const { data } = await request.put(endpoint, { note }, {
      headers: authorizationHeader(token)
    });

    return data?.data;
  } catch (error) {
    throw handleRequestError(error);
  }
}
