
/**
 * Module dependencies.
 */

import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { SnackbarContext } from './context';
import { media, units } from 'src/styles/utils';
import { theme } from 'styled-tools';
import React, { ReactElement, ReactNode, useCallback, useReducer } from 'react';
import Snackbar, { Options } from 'src/components/snackbar';
import get from 'lodash/get';
import map from 'lodash/map';
import reject from 'lodash/reject';
import styled from 'styled-components';

/**
 * `State` type.
 */

type State = {
  messages: Array<{
    content: ReactNode,
    id: number,
    options?: Options
  }>,
  total: number
};

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode
};

/**
 * Action types.
 */

const actionTypes = {
  addMessage: 'ADD_MESSAGE',
  removeMessage: 'REMOVE_MESSAGE'
};

/**
 * Reducer.
 */

function reducer(state: State, { payload, type }) {
  switch (type) {
    case actionTypes.addMessage: {
      const total = state.total + 1;

      return {
        messages: [
          ...state.messages,
          {
            ...get(payload, 'message'),
            id: total
          }
        ],
        total
      };
    }

    case actionTypes.removeMessage:
      return {
        messages: reject(state.messages, payload),
        total: state.total - 1
      };

    default:
      return state;
  }
}

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  left: ${theme('grid.gutterMobile')}px;
  margin: 0 auto;
  max-width: ${theme('breakpoints.xxl')}px;
  position: fixed;
  right: ${theme('grid.gutterMobile')}px;
  top: ${theme('grid.gutterMobile')}px;
  z-index: ${theme('zIndex.snackbar')};

  ${media.min('ms')`
    left: ${theme('grid.gutter')}px;
    right: ${theme('grid.gutter')}px;
    top: ${theme('grid.gutter')}px;
  `}

  .snackbar-messages-item-enter {
    opacity: 0;
    transform: translateY(${units(1)});
  }

  .snackbar-messages-item-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition:
      opacity 255ms ${theme('animations.bezier.easeOutQuad')},
      transform 0.15s ${theme('animations.bezier.easeOutQuad')};
  }

  .snackbar-messages-item-exit {
    opacity: 1;
  }

  .snackbar-messages-item-exit-active {
    opacity: 0;
    transition: opacity 255ms ${theme('animations.bezier.easeOutQuad')};
  }
`;

/**
 * `Content` styled component.
 */

const Content = styled.div`
  margin: 0 auto;
  width: 100%;

  ${media.min('ms')`
    width: calc(100% / 12 * 10);
  `}
`;

/**
 * `SnackbarProvider` provider.
 */

const SnackbarProvider = ({ children }: Props): ReactElement => {
  const [{ messages }, dispatch] = useReducer(reducer, { messages: [], total: 0 });
  const showMessage = useCallback((content: ReactNode, options: Options) => {
    dispatch({
      payload: {
        message: { content, options }
      },
      type: actionTypes.addMessage
    });
  }, []);

  const removeMessage = useCallback((id: number) => {
    dispatch({
      payload: { id },
      type: actionTypes.removeMessage
    });
  }, []);

  return (
    <SnackbarContext.Provider
      value={{
        removeMessage,
        showMessage
      }}
    >
      {children}

      <Wrapper>
        <Content>
          <TransitionGroup>
            {map(messages, ({ content, id, options }) => (
              <CSSTransition
                classNames={'snackbar-messages-item'}
                key={id}
                timeout={255}
              >
                <Snackbar
                  id={id}
                  onDismiss={removeMessage}
                  options={options}
                >
                  {content}
                </Snackbar>
              </CSSTransition>
            ))}
          </TransitionGroup>
        </Content>
      </Wrapper>
    </SnackbarContext.Provider>
  );
};

/**
 * Export `SnackbarProvider` provider.
 */

export default SnackbarProvider;
