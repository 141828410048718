
/**
 * Module dependencies.
 */

import {
  authorizationHeader,
  handleRequestError,
  request
} from 'src/core/utils/requests';

import { getApiEndpoint } from 'src/core/utils/api-url-resolver';

/**
 * Endpoint.
 */

const endpoint = getApiEndpoint('getUser');

/**
 * `Query` type.
 */

type Query = {
  ssr?: boolean,
  token: string | null | undefined
};

/**
 * Export `fetchUser`.
 */

export async function fetchUser({ ssr, token }: Query) {
  if (!token) {
    return;
  }

  try {
    const { data } = await request.get(endpoint, {
      headers: authorizationHeader(token)
    });

    return data?.data;
  } catch (error) {
    if (!ssr) {
      throw handleRequestError(error);
    }
  }
}
