
/**
 * Export `hueVariation`.
 */

export const hueVariation = {
  v1: 'v1',
  v2: 'v2',
  v3: 'v3',
  v4: 'v4'
} as const;

/**
 * Export `hueVariationIcons`.
 */

export const hueVariationIcons = {
  [hueVariation.v1]: require('src/assets/svg/hue-variation/v1.svg'),
  [hueVariation.v2]: require('src/assets/svg/hue-variation/v2.svg'),
  [hueVariation.v3]: require('src/assets/svg/hue-variation/v3.svg'),
  [hueVariation.v4]: require('src/assets/svg/hue-variation/v4.svg')
};
