
/**
 * Module dependencies.
 */

import { connectInfiniteHits } from 'react-instantsearch-dom';
import { indexes } from 'src/core/search-config';
import { units } from 'src/styles/utils';
import { useTranslate } from 'src/core/utils/translator';
import Button from 'src/components/core/buttons/button';
import CollectionCard from 'src/components/search/search-results/cards/collection-card';
import MagazineCard from 'src/components/search/search-results/cards/magazine-card';
import ProductCard from 'src/components/search/search-results/cards/product-card';
import ProjectCard from 'src/components/search/search-results/cards/project-card';
import React, { ReactElement } from 'react';
import styled from 'styled-components';

/**
 * `ListItem` styled component.
 */

const ListItem = styled.li`
  &:not(:last-child) {
    margin-bottom: ${units(2)};
  }
`;

/**
 * `LoadMoreWrapper` styled component.
 */

const LoadMoreWrapper = styled.div`
  padding-top: ${units(5)};
`;

/**
 * `InfiniteHits` component.
 */

const InfiniteHits = ({ hasMore, hits, index, isKioskCard, refineNext }): ReactElement => {
  const { translate } = useTranslate();

  return (
    <>
      <ol>
        {hits.map(hit => (
          <ListItem key={hit.objectID}>
            {index === indexes.productsCollections && (
              <CollectionCard
                isKioskCard={isKioskCard}
                {...hit}
              />
            )}

            {index === indexes.products && (
              <ProductCard
                isKioskCard={isKioskCard}
                {...hit}
              />
            )}

            {index === indexes.magazines && (
              <MagazineCard {...hit} />
            )}

            {index === indexes.projects && (
              <ProjectCard {...hit} />
            )}
          </ListItem>
        ))}
      </ol>

      {hasMore && (
        <LoadMoreWrapper>
          <Button
            onClick={refineNext}
            size={'small'}
          >
            {translate('common:actions.loadMore')}
          </Button>
        </LoadMoreWrapper>
      )}
    </>
  );
};

/**
 * Export `InfiniteHits` component.
 */

export default connectInfiniteHits(InfiniteHits);
