
/**
 * Module dependencies.
 */

import { ProductProps } from 'src/types/products';
import {
  hueVariationIcons,
  hueVariation as hueVariationValues
} from 'src/core/collections-config/hue-variations';

import {
  slipResistanceIcons,
  slipResistanceKeys
} from 'src/core/collections-config/slip-resistance';

import { trafficLevelIcons } from 'src/core/collections-config/traffic-level';
import {
  typologiesIconsSmall,
  typologiesValues
} from 'src/core/collections-config/typologies';

import { useMemo } from 'react';
import { useTranslate } from 'src/core/utils/translator';
import camelCase from 'lodash/camelCase';
import findKey from 'lodash/findKey';
import isEmpty from 'lodash/isEmpty';

/**
 * `SpecProps` type.
 */

export type SpecProps = {
  active: boolean,
  icon: string,
  name?: string,
  uppercase?: boolean,
  value: string
};

/**
 * Get key from object.
 */

function getKeyFromObject(object, selectedValue): string {
  return findKey(object, value => value === selectedValue);
}

/**
 * `useProductSpecs` hook.
 */

function useProductSpecs(props: ProductProps) {
  const { translate } = useTranslate();
  const normalizedProductSpecs = useMemo<SpecProps[] | null>(() => {
    if (!props) {
      return null;
    }

    const {
      boxArea,
      boxWeight,
      boxes,
      boxesArea,
      boxesWeight,
      hueVariation,
      numberDesigns,
      pieces,
      stockUnit,
      surface,
      thickness,
      trafficLevel,
      typology
    } = props;

    const typologyKey = getKeyFromObject(typologiesValues, typology);
    const hueVariationKey = getKeyFromObject(hueVariationValues, hueVariation);
    const slipResistance: SpecProps[] = Object
      .keys(props)
      .filter(key => Object.keys(slipResistanceKeys).includes(camelCase(key)))
      .map(propKey => ({
        active: !isEmpty(props[propKey]),
        icon: slipResistanceIcons[camelCase(propKey)],
        name: translate(`collections:slipResistance.${camelCase(propKey)}`),
        uppercase: true,
        value: props[propKey]
      }));

    const specs: SpecProps[] = [{
      active: !isEmpty(typologyKey),
      icon: typologiesIconsSmall[typologyKey],
      name: translate('common:labels.typology'),
      value: translate(`collections:typologies.${typologyKey}`)
    }, {
      active: !isEmpty(thickness?.name),
      icon: require('src/assets/svg/thickness.svg'),
      name: translate('common:labels.thickness'),
      value: `${thickness?.name}mm`
    }, {
      active: !isEmpty(stockUnit),
      icon: require('src/assets/svg/stock-unit.svg'),
      name: translate('collections:labels.stockUnit'),
      value: stockUnit.replace('m2', 'm<sup>2</sup>')
    }, {
      active: !isEmpty(hueVariation),
      icon: hueVariationIcons[hueVariationKey],
      name: translate('collections:labels.hueVariation'),
      value: translate(`collections:hueVariation.${hueVariationKey}`)
    }, {
      active: !isEmpty(numberDesigns),
      icon: require('src/assets/svg/n-designs.svg'),
      name: translate('collections:labels.numberDesigns'),
      value: numberDesigns
    }, {
      active: !isEmpty(trafficLevel),
      // @ts-ignore
      icon: trafficLevelIcons[trafficLevel],
      name: translate('collections:labels.trafficLevel'),
      value: translate(`collections:trafficLevel.${trafficLevel}`)
    }, ...slipResistance, {
      active: !isEmpty(surface),
      icon: require('src/assets/svg/surface.svg'),
      name: translate('collections:labels.surface'),
      value: translate(`collections:surfaces.${surface}`)
    }, {
      active: !isEmpty(pieces),
      icon: require('src/assets/svg/piece.svg'),
      value: `${pieces} ${translate('collections:labels.piece', {
        count: Number(pieces)
      })}${boxArea && `<br>${boxArea}m2`}${boxWeight && `<br>${boxWeight} Kg`}`
    }, {
      active: !isEmpty(boxes),
      icon: require('src/assets/svg/box.svg'),
      value: `${boxes} ${translate('collections:labels.box', {
        count: Number(boxes)
      })}${boxesArea && `<br>${boxesArea}m2`}${boxesWeight && `<br>${boxesWeight} Kg`}`
    }];

    return specs;
  }, [props, translate]);

  return { normalizedProductSpecs };
}

/**
 * Export `useProductSpecs` hook.
 */

export default useProductSpecs;
