
/**
 * Module dependencies.
 */

import { useEffect, useState } from 'react';

/**
 * `useMediaQuery` hook.
 */

function useMediaQuery(query: string): boolean {
  const [matches, setMatches] = useState<boolean>();

  useEffect(() => {
    const media = window.matchMedia(query);

    setMatches(media.matches);
  }, [query]);

  useEffect(() => {
    const media = window.matchMedia(query);
    const listener = () => setMatches(media.matches);

    media.addListener(listener);

    return () => {
      media.removeListener(listener);
    };
  }, [query]);

  return matches;
}

/**
 * Export `useMediaQuery` hook.
 */

export default useMediaQuery;

