
/**
 * Module dependencies.
 */

import { createReactPortal } from 'src/core/utils/portals';
import { ifProp, theme } from 'styled-tools';
import React, { ReactElement, ReactNode, useCallback } from 'react';
import styled from 'styled-components';
import useBodyScroll from 'src/hooks/use-body-scroll';

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode,
  isOpen: boolean
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div<{ isVisible: boolean }>`
  transition: ${theme('animations.defaultTransition')};
  transition-property: visibility;
  visibility: ${ifProp('isVisible', 'visible', 'hidden')};
`;

/**
 * `ModalPortal` component.
 */

const ModalPortal = ({ children, isOpen }: Props): ReactElement => {
  const handleGhostClick = useCallback(event => {
    if (event) {
      event.stopPropagation();
    }
  }, []);

  useBodyScroll({ off: isOpen });

  return createReactPortal((
    <Wrapper
      isVisible={isOpen}
      onClick={handleGhostClick}
    >
      {isOpen ? children : null}
    </Wrapper>
  ), 'modal-root');
};

/**
 * Export `ModalPortal` component.
 */

export default ModalPortal;
