
/**
 * My revigres endpoints.
 */

const myRevigresEndpoints = {
  createUser: '/users',
  getUser: '/users/me',
  getUserClient: '/me/clients/:id',
  getUserClientPdf: '/me/clients/:id/pdf',
  getUserClients: '/me/clients',
  getUserFavorite: '/me/favorites/:id',
  getUserFavoritePdf: '/me/favorites/:id/pdf',
  getUserFavorites: '/me/favorites',
  getUserFavoritesPdf: '/me/favorites/pdf',
  getUserProject: '/me/projects/:id',
  getUserProjectClient: '/me/projects/:projectId/clients/:clientId',
  getUserProjectClients: '/me/projects/:id/clients',
  getUserProjectFavorite: '/me/projects/:projectId/favorites/:favoriteId',
  getUserProjectFavorites: '/me/projects/:id/favorites',
  getUserProjectPdf: '/me/projects/:id/pdf',
  getUserProjects: '/me/projects'
};

/**
 * API endpoints.
 */

export const apiEndpoints = {
  ...myRevigresEndpoints,
  contactUsForm: '/forms/contact-us',
  getAmbient: '/ambients/:id',
  getAmbients: '/ambients',
  getApplication: '/product-applications/:slug',
  getCollection: '/product-collections/:slug',
  getCollections: '/product-collections',
  getCollectionsRelated: '/product-collections/:slug/related',
  getCommercialContacts: '/commercial-contacts',
  getComparatorEmail: '/products/comparator/kiosk',
  getConfigurations: '/site-configs',
  getContacts: '/contacts',
  getCountries: '/countries',
  getDistricts: '/districts',
  getDownloadCategories: '/download-categories',
  getELearningPage: '/e-learning/:password',
  getFaq: '/faqs/:slug',
  getFaqs: '/faqs',
  getHistories: '/histories',
  getInstitutionalDownloads: '/institutional-downloads',
  getMagazine: '/magazines/:slug',
  getMagazinePdf: '/magazines/:slug/pdf',
  getMagazines: '/magazines',
  getPage: '/pages/:slug',
  getPopularCollections: '/product-collections/popular',
  getProduct: '/products/:slug',
  getProductApplications: '/product-applications',
  getProductBaseFormats: '/product-collections/base-formats',
  getProductPdf: '/products/:slug/pdf',
  getProductThicknesses: '/product-thicknesses',
  getProducts: '/products',
  getProductsComparatorPdf: '/products/comparator/pdf',
  getProject: '/projects/:slug',
  getProjects: '/projects',
  getRecommendFilters: '/recomendator',
  getResellers: '/resellers',
  getShowroomModule: '/showroom-module',
  getShowrooms: '/showrooms',
  getTopBarMenu: '/top-bar-menu',
  newsletter: '/forms/newsletter',
  recruitmentForm: '/forms/recruitment',
  talkToUsForm: '/forms/talk-to-us'
};
