
/**
 * Module dependencies.
 */

import { color } from 'src/styles/utils';
import { ifProp, switchProp, theme } from 'styled-tools';
import { isExternalRoute } from 'src/core/utils/routes';
import React, { ElementType, ReactElement, forwardRef } from 'react';
import RouterLink from 'src/components/core/links/router-link';
import Svg from 'src/components/core/svg';
import styled, { css } from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  as?: ElementType,
  className?: string,
  colorTheme?: 'black' | 'white',
  disabled?: boolean,
  href?: string,
  icon: string,
  iconSize: string,
  locale?: string,
  onClick?: () => void,
};

/**
 * `Button` styled component.
 */

const Button = styled.button.attrs<Props>(({ as, colorTheme, href, type }) => {
  const isExternal = isExternalRoute(href);
  const element = as || href && !isExternal && RouterLink || href && isExternal && 'a' || 'button';

  return {
    as: element,
    colorTheme: colorTheme || 'black',
    type: type || (element === 'button' ? 'button' : null)
  };
})`
  -webkit-tap-highlight-color: transparent;
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  grid-area: closeButton;
  outline: none;
  padding: 0;
  transition: color ${theme('animations.defaultTransition')};

  ${switchProp('colorTheme', {
    black: css`
      color: ${color('textColor')};

      &:focus,
      &:hover {
        color: ${color('grey300')};
      }
    `,
    white: css`
      color: ${color('white')};

      &:focus,
      &:hover {
        color: ${color('grey200')};
      }
    `
  })}

  ${ifProp('disabled', css`
    color: ${color('grey600')};
    cursor: default;
    pointer-events: none;
  `)}
`;

/**
 * `IconButton` component.
 */

const IconButton = (props: Props, ref: any): ReactElement => {
  const { icon, iconSize, ...rest } = props;

  return (
    <Button
      {...rest}
      ref={ref}
    >
      <Svg
        icon={icon}
        size={iconSize}
      />
    </Button>
  );
};

/**
 * Export `IconButton` component.
 */

export default forwardRef<Props, any>(IconButton);
