
/**
 * Palette.
 */

const palette = {
  beige50: '#f5f4f4',
  beige100: '#eeebe9',
  beige150: '#eae0d2',
  beige200: '#d7cfcb',
  beige500: '#cdbfb8',
  black: '#000000',
  brown200: '#91877e',
  brown300: '#93776d',
  brown400: '#8f6d4f',
  green100: '#c9c6c1',
  green300: '#868d84',
  green400: '#7b8179',
  grey100: '#f5f5f5',
  grey200: '#d8d8d8',
  grey300: '#777777',
  grey400: '#444444',
  grey500: '#cdcdcd',
  grey600: '#999999',
  grey900: '#111111',
  white: '#ffffff'
};

/**
 * Export `colors`.
 */

export const colors = {
  ...palette,
  error: '#dcc2b9',
  formError: '#dc3545',
  info: '#c4d0d2',
  success: '#cedad3',
  textColor: palette.grey900,
  warning: '#eae0d2'
};
