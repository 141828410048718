
/**
 * Module dependencies.
 */

import uniq from 'lodash/uniq';

/**
 * `Breakpoints` type.
 */

type Breakpoints = {
  [key: string]: number
};

/**
 * Export `baseBreakpoint`.
 */

export const baseBreakpoint = 'base';

/**
 * Sort by breakpoint.
 */

const sortByBreakpoint = (breakpoints: Breakpoints) => (first: string, second: string): number => {
  return breakpoints[first] - breakpoints[second];
};

/**
 * Export `orderedBreakpointsKeys`.
 */

export const orderedBreakpointsKeys = (breakpoints: Breakpoints): Array<string> => Object
  .keys(breakpoints)
  .sort(sortByBreakpoint(breakpoints));

/**
 * Export `orderByBreakpoints`.
 *
 * This method receives an Object and returns the
 * Object keys ordered by the breakpoints variable.
 */

export const orderByBreakpoints = (unorderedBreakpoints: Breakpoints, breakpoints: Breakpoints): string[] => {
  const keys = uniq(Object
    .keys(unorderedBreakpoints)
    .map(key => key.replace('Offset', '')));

  return keys.sort(sortByBreakpoint(breakpoints));
};
