
/**
 * Module dependencies.
 */

import { assetUrlResolve } from 'src/core/utils/url-resolver';
import { color, units } from 'src/styles/utils';
import { theme } from 'styled-tools';
import { useRouter } from 'next/router';
import Image from 'src/components/core/image';
import React, { ReactElement, ReactNode } from 'react';
import RouterLink from 'src/components/core/links/router-link';
import Type from 'src/components/core/typography/index';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  description: ReactNode | null,
  href: string,
  imageUrl: string | null | undefined,
  title: string
};

/**
 * `Title` styled component.
 */

const Title = styled(Type.Paragraph).attrs({ as: 'span' })`
  color: ${color('grey400')};
  transition: color ${theme('animations.defaultTransition')};
`;

/**
 * `Description` styled component.
 */

const Description = styled(Type.Small).attrs({ as: 'span', xSmall: true })`
  color: ${color('grey300')};
  transition: color ${theme('animations.defaultTransition')};
`;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled(RouterLink)`
  align-items: center;
  display: grid;
  grid-gap: ${units(2)};
  grid-template-areas: 'imageWrapper content';
  grid-template-columns: ${units(8)} 1fr;

  &:focus,
  &:hover {
    ${Title} {
      color: ${color('grey900')};
    }

    ${Description} {
      color: ${color('grey900')};
    }
  }
`;

/**
 * `ImageWrapper` styled component.
 */

const ImageWrapper = styled.div`
  border-radius: ${units(8)};
  grid-area: imageWrapper;
  height: ${units(8)};
  overflow: hidden;
  position: relative;
  width: ${units(8)};
`;

/**
 * `Content` styled component.
 */

const Content = styled.div`
  display: grid;
  grid-area: content;
  grid-template-columns: 100%;
  grid-template-rows: max-content;
`;

/**
 * `Card` component.
 */

const Card = (props: Props): ReactElement => {
  const { description, href, imageUrl, title } = props;
  const { locale } = useRouter();

  return (
    <Wrapper
      href={href}
      locale={locale}
    >
      <ImageWrapper>
        <Image
          alt={title}
          layout={'fill'}
          objectFit={'cover'}
          src={imageUrl ?? assetUrlResolve('/static/images/empty/list@2x.jpg')}
        />
      </ImageWrapper>

      <Content>
        <Title>
          {title}
        </Title>

        {description && (
          <Description>
            {description}
          </Description>
        )}
      </Content>
    </Wrapper>
  );
};

/**
 * Export `Card` component.
 */

export default Card;
