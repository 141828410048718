
/**
 * Module dependencies.
 */

import AnimateHeight from 'react-animate-height';
import React, { ReactElement, ReactNode } from 'react';

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode,
  className?: string,
  initialHeight: number,
  isOpen?: boolean
};

/**
 * `Collapse` component.
 */

const Collapse = (props: Props): ReactElement => {
  const { children, initialHeight, isOpen, ...rest } = props;

  return (
    <AnimateHeight
      duration={500}
      height={isOpen ? 'auto' : initialHeight}
      {...rest}
    >
      {children}
    </AnimateHeight>
  );
};

/**
 * Default props.
 */

Collapse.defaultProps = {
  initialHeight: 0
};

/**
 * Export `Collapse` component.
 */

export default Collapse;
