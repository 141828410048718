
/**
 * Module dependencies.
 */

import { SubMenu, useFooterLinks } from 'src/core/menu';
import { color, media, units } from 'src/styles/utils';
import { ifNotProp, ifProp, prop, theme } from 'styled-tools';
import { isExternalRoute } from 'src/core/utils/routes';
import { useTranslate } from 'src/core/utils/translator';
import React, { ReactElement, useMemo } from 'react';
import RouterLink from 'src/components/core/links/router-link';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import styled, { css } from 'styled-components';

/**
 * Current year.
 */

const currentYear = new Date().getFullYear();

/**
 * Delay.
 */

const delay = 100;

/**
 * `Props` type.
 */

type Props = {
  startDelay: number,
  visible: boolean
};

/**
 * Type style.
 */

const typeStyle = css`
  font-family: ${theme('typography.fontFamily.serif')};
  font-size: 12px;
  font-weight: 400;
  line-height: ${units(3)};
`;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  display: grid;
  grid-gap: ${units(0.5)};
  grid-template-areas:
    'list'
    'copyright';
  grid-template-columns: max-max-content;

  ${media.min('ms')`
    align-items: center;
    grid-gap: ${units(2)};
    grid-template-areas: 'copyright list';
    grid-template-columns: repeat(2, max-content);
  `}
`;

/**
 * `Copyright` styled component.
 */

const Copyright = styled.span<{
  delay: number,
  delayMobile: number,
  visible: boolean
}>`
  ${typeStyle}

  color: ${color('white')};
  grid-area: copyright;
  opacity: ${ifProp('visible', 1, 0)};
  transition: ${theme('animations.menuTransition')};

  ${media.min('ms')`
    transform: translateY(${ifProp('visible', 0, units(2))});

    ${ifProp('visible', css`
      transition-delay: ${prop('delay', 0)}ms;
    `)}
  `}

  ${media.max('ms')`
    transform: translateX(${ifProp('visible', 0, units(-2))});

    ${ifProp('visible', css`
      transition-delay: ${prop('delayMobile', 0)}ms;
    `)}
  `}
`;

/**
 * `List` styled component.
 */

const List = styled.ul`
  display: grid;
  grid-area: list;
  grid-auto-flow: row;
  grid-gap: ${units(0.5)};

  ${media.min('ms')`
    grid-auto-flow: column;
    grid-gap: ${units(2)};
  `}
`;

/**
 * `ListItem` styled component.
 */

const ListItem = styled.li<{
  delay: number,
  visible: boolean
}>`
  opacity: ${ifProp('visible', 1, 0)};
  transition: ${theme('animations.menuTransition')};

  ${ifProp('visible', css`
    transition-delay: ${prop('delay', 0)}ms;
  `)}

  ${media.min('ms')`
    transform: translateY(${ifProp('visible', 0, units(2))});
  `}

  ${media.max('ms')`
    transform: translateX(${ifProp('visible', 0, units(-2))});
  `}
`;

/**
 * `ListItemLink` styled component.
 */

const ListItemLink = styled.a.attrs(({ href }) => ({
  as: href && !isExternalRoute(href) && RouterLink || 'a'
}))<{ visible: boolean }>`
  ${typeStyle}

  color: ${color('white')};
  cursor: pointer;
  text-decoration: none;
  transition: color ${theme('animations.defaultTransition')};

  &:focus,
  &:hover {
    color: ${color.transparentize('white', 0.4)};
  }

  ${ifNotProp('visible', css`
    cursor: default;
    pointer-events: none;
  `)}
`;

/**
 * `FooterMenu` component.
 */

const FooterMenu = (props: Props): ReactElement => {
  const { startDelay, visible } = props;
  const { locale, translate } = useTranslate();
  const footerLinks = useFooterLinks();
  const activeFooterLinks = useMemo(() => {
    return filter(footerLinks, ({ href }) => !isEmpty(href));
  }, [footerLinks]);

  const totalLinks = activeFooterLinks.length;

  return (
    <Wrapper>
      <Copyright
        delay={startDelay}
        delayMobile={startDelay + delay + totalLinks * delay}
        visible={visible}
      >
        {`Revigrés © ${currentYear}`}
      </Copyright>

      <List>
        {activeFooterLinks.map(({ href, title }: SubMenu, index: number) => (
          <ListItem
            delay={(index + 1) * delay + startDelay}
            key={index}
            visible={visible}
          >
            <ListItemLink
              href={href}
              {...!isExternalRoute(href) ? { locale } : {
                rel: 'noopener',
                target: '_blank'
              }}
              visible={visible}
            >
              {translate(title)}
            </ListItemLink>
          </ListItem>
        ))}
      </List>
    </Wrapper>
  );
};

/**
 * Export `FooterMenu` component.
 */

export default FooterMenu;
