
/**
 * Module dependencies.
 */

import {
  authorizationHeader,
  handleRequestError,
  request
} from 'src/core/utils/requests';

import { getApiEndpoint } from 'src/core/utils/api-url-resolver';

/**
 * `Query` type.
 */

type Query = {
  formData: FormData,
  id: string,
  token: string
};

/**
 * Export `updateProject`.
 */

export async function updateProject(data: Query) {
  const { formData, id, token } = data;
  const endpoint = `${getApiEndpoint('getUserProject', { id })}?_method=PUT`;

  try {
    const { data } = await request.post(endpoint, formData, {
      headers: {
        ...authorizationHeader(token),
        'Content-Type': 'multipart/form-data'
      }
    });

    return data?.data;
  } catch (error) {
    throw handleRequestError(error);
  }
}
