
/**
 * Module dependencies.
 */

import { FavoritesContext } from './context';
import ProjectsSidebar from 'src/components/my-revigres/favorites/projects-sidebar';
import React, { ReactElement, ReactNode, useState } from 'react';

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode
};

/**
 * `FavoritesProvider` provider.
 */

const FavoritesProvider = ({ children }: Props): ReactElement => {
  const [isOpenProjectsSidebar, setIsOpenProjectsSidebar] = useState<boolean>();
  const [productName, setProductName] = useState<string | null>(null);
  const [favoriteId, setFavoriteId] = useState<string | null>(null);

  return (
    <FavoritesContext.Provider
      value={{
        favoriteId,
        isOpenProjectsSidebar,
        onCloseProjectsSidebar: () => setIsOpenProjectsSidebar(false),
        onOpenProjectsSidebar: () => setIsOpenProjectsSidebar(true),
        onUpdateFavoriteId: setFavoriteId,
        onUpdateProductName: setProductName,
        productName
      }}
    >
      {children}

      <ProjectsSidebar
        favoriteId={favoriteId}
        isOpenProjectsSidebar={isOpenProjectsSidebar}
        onCloseProjectsSidebar={() => setIsOpenProjectsSidebar(false)}
        productName={productName}
      />
    </FavoritesContext.Provider>
  );
};

/**
 * Export `FavoritesProvider` provider.
 */

export default FavoritesProvider;
