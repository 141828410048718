
/**
 * Module dependencies.
 */

import { ifProp, prop } from 'styled-tools';
import React, { ReactElement } from 'react';
import styled, { css } from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  color?: string,
  icon: string,
  size: string | unknown
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.span`
  display: inline-block;
  line-height: 0;
  max-width: ${prop('size')};
  position: relative;
  transform: translate3d(0, 0, 0);
  width: ${prop('size')};

  ${ifProp('color', css`
    color: ${prop('color')};`
  )}
`;

/**
 * `Svg` component.
 */

const Svg = ({ icon, ...rest }: Props): ReactElement => {
  const innerHtml = {
    __html: icon // eslint-disable-line id-match
  };

  return (
    <Wrapper
      {...rest}
      dangerouslySetInnerHTML={innerHtml}
    />
  );
};

/**
 * Export `Svg` component.
 */

export default Svg;
