/**
 * Module dependencies.
 */

import { useEffect } from 'react';

/**
 * `Props` type.
 */

type Props = {
  isActive: boolean,
  ref?: any,
  timeout?: number
}

/**
 * `useAutoFocus` hook.
 */

export function useAutoFocus({ isActive, ref, timeout }: Props) {
  useEffect(() => {
    if (isActive && ref.current) {
      const time = setTimeout(() => {
        ref.current.focus();
      }, timeout ?? 100);

      return () => clearTimeout(time);
    }
  }, [isActive, ref, timeout]);
}

/**
 * Export `useAutoFocus` hook.
 */

export default useAutoFocus;
