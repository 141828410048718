
/**
 * Module dependencies.
 */

import { color } from 'src/styles/utils';
import { kioskRoutes } from 'src/core/routes';
import { theme } from 'styled-tools';
import { useLocalizedRoute } from 'src/core/utils/routes';
import { useRouter } from 'next/router';
import React, { ReactElement } from 'react';
import RouterLink from 'src/components/core/links/router-link';
import Trans from 'next-translate/Trans';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  i18nKey: string,
  isKiosk?: boolean
};

/**
 * `PrivacyPolicyLinkProps` type.
 */

type PrivacyPolicyLinkProps = {
  children?: string,
  isKiosk?: boolean
};

/**
 * `Link` styled component.
 */

const Link = styled(RouterLink)`
  color: ${color('grey400')};
  cursor: pointer;
  display: inline-block;
  text-decoration: underline;
  transition: color, ${theme('animations.defaultTransition')};
  width: max-content;

  &:focus,
  &:hover {
    color: ${color.transparentize('grey400', 0.8)};
  }
`;

/**
 * `Paragraph` styled component.
 */

const Paragraph = styled.p`
  margin: 0;
`;

/**
 * `PrivacyPolicyLink` component.
 */

const PrivacyPolicyLink = ({ children, isKiosk }: PrivacyPolicyLinkProps) => {
  const { locale } = useRouter();
  const routeResolver = useLocalizedRoute();
  const route = routeResolver('privacyPolicy');
  const kioskRoute = kioskRoutes.privacyPolicy;

  return (
    <Link
      href={isKiosk ? kioskRoute : route}
      locale={locale}
    >
      {children}
    </Link>
  );
};

/**
 * `PrivacyPolicyCheckboxLabel` component.
 */

const PrivacyPolicyCheckboxLabel = ({ i18nKey, isKiosk }: Props): ReactElement => {
  return (
    <Trans
      components={[
        <PrivacyPolicyLink
          isKiosk={isKiosk}
          key={'privacy-policy'}
        />,
        <Paragraph key={'paragraph'} />
      ]}
      i18nKey={i18nKey}
    />
  );
};

/**
 * Export `PrivacyPolicyCheckboxLabel` component.
 */

export default PrivacyPolicyCheckboxLabel;
