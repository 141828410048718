
/**
 * Module dependencies.
 */

import { kioskRoutes } from './routes';

/**
 * Export `Size` type.
 */

export type Size = 'medium' | 'small';

/**
 * Export `Menu` type.
 */

export type Menu = {
  hasMargin?: boolean,
  href?: string | null,
  size?: Size,
  title: string
};

/**
 * Export `kioskMenu`.
 */

export const kioskMenu: Menu[] = [{
  href: kioskRoutes.collections,
  size: 'medium',
  title: 'menu:productsMenu.collections'
}, {
  href: kioskRoutes.highlights,
  size: 'medium',
  title: 'menu:productsMenu.highlights'
}, {
  hasMargin: true,
  href: kioskRoutes.inspiration,
  size: 'medium',
  title: 'menu:getInspiredMenu.ambients'
}, {
  href: kioskRoutes.toolsEnvironmentSimulator,
  title: 'menu:toolsMenu.environmentSimulatorKiosk'
}, {
  hasMargin: true,
  href: kioskRoutes.recommend,
  title: 'menu:productsMenu.recommender'
}, {
  href: kioskRoutes.aboutUs,
  title: 'menu:revigresMenu.aboutUs'
}, {
  href: kioskRoutes.history,
  title: 'menu:revigresMenu.history'
}, {
  href: kioskRoutes.innovation,
  title: 'menu:revigresMenu.innovation'
}, {
  href: kioskRoutes.environment,
  title: 'menu:revigresMenu.environmentSustainability'
}, {
  hasMargin: true,
  href: kioskRoutes.socialResponsability,
  title: 'menu:revigresMenu.socialResponsability'
}, {
  href: kioskRoutes.contacts,
  title: 'menu:contacts'
}];

/**
 * Export `kioskNavbarLinks`.
 */

export const kioskNavbarLinks = [{
  label: 'menu:productsMenu.collections',
  link: kioskRoutes.collections
}, {
  label: 'menu:simulator',
  link: kioskRoutes.toolsEnvironmentSimulator
}, {
  label: 'menu:ambients',
  link: kioskRoutes.inspiration
}];

