
/**
 * Module dependencies.
 */

import { assetUrlResolve } from 'src/core/utils/url-resolver';
import { isExternalRoute } from 'src/core/utils/routes';
import { units } from 'src/styles/utils';
import { useTranslate } from 'src/core/utils/translator';
import Image from 'src/components/core/image';
import React, { ReactElement } from 'react';
import RouterLink from 'src/components/core/links/router-link';
import styled from 'styled-components';

/**
 * `icons` constant.
 */

const icons = {
  archello: {
    height: 33,
    href: 'https://archello.com/pt',
    src: assetUrlResolve('/static/images/partners/archello.png'),
    width: 134
  },
  archiproducts: {
    height: 37,
    href: 'https://www.archiproducts.com/en/revigres',
    src: assetUrlResolve('/static/images/partners/archiproducts.png'),
    width: 160
  },
  bimobject: {
    height: 33,
    href: 'https://www.bimobject.com/pt',
    src: assetUrlResolve('/static/images/partners/bimobject.png'),
    width: 156
  },
  goodDesign: {
    height: 112,
    href: 'https://www.chi-athenaeum.org/building-products/materials-2015/2015/12/21/chromatic-collection-2014/',
    src: assetUrlResolve('/static/images/partners/good-design@2x.png'),
    width: 88
  },
  ifDesignAward: {
    height: 112,
    href: 'https://ifworlddesignguide.com/design-guide/profiles/companies?#/page/profile/6965-revigres',
    src: assetUrlResolve('/static/images/partners/if-design-award@2x.png'),
    width: 54
  },
  reddotDesignAward: {
    height: 112,
    href: 'https://www.red-dot.org',
    src: assetUrlResolve('/static/images/partners/reddot-design-award@2x.png'),
    width: 182
  }
}

/**
 * `LogoProps` type
 */

type LogoProps = {
  height: number;
  href: string;
  src: string;
  width: number;
};

/**
 * `LogosWrapper` styled component.
 */

const LogosWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4,auto);
  position: relative;
`;

/**
 * `VerticalLogos` styled component.
 */

const VerticalLogos = styled.div`
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  position: relative;
`;

/**
 * `Logo` styled component.
 */

const Logo = styled.a.attrs(({ href }) => ({
  as: href && !isExternalRoute(href) && RouterLink || 'a',
  ...!isExternalRoute(href) ? {} : {
    rel: 'noopener',
    target: '_blank'
  }
})) `
  color: inherit;
  outline: none;
  text-decoration: none;

  &:not(:last-child) {
    margin-right: ${units(1.5)};
  }
`;

/**
 * `PartnerLogo` component.
 */

const PartnerLogo = ({ height, href, src, width }: LogoProps) => {
  const { locale, translate } = useTranslate();

  return (
    <Logo
      href={href}
      {...!isExternalRoute(href) && { locale }}
    >
      <Image
        alt={translate(`footer:partners`)}
        height={height}
        layout={'intrinsic'}
        objectFit={'scale-down'}
        src={src}
        width={width}
      />
    </Logo>
  )
}

/**
 * `PartnersLogo` component.
 */

const PartnersLogo = (): ReactElement => {

  return (
    <LogosWrapper>
      <VerticalLogos>
        <PartnerLogo {...icons.archello} />

        <PartnerLogo {...icons.archiproducts} />

        <PartnerLogo {...icons.bimobject} />
      </VerticalLogos>

      <PartnerLogo {...icons.goodDesign} />

      <PartnerLogo {...icons.ifDesignAward} />

      <PartnerLogo {...icons.reddotDesignAward} />
    </LogosWrapper>
  );
};

/**
 * Export `PartnersLogo` component.
 */

export default PartnersLogo;
