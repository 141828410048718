
/**
 * Module dependencies.
 */

import NextLink, { LinkProps } from 'next/link';
import React, { ReactElement, ReactNode, forwardRef } from 'react';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = LinkProps & {
  'aria-label'?: string,
  children: ReactNode,
  className?: string
};

/**
 * `StyledLink` styled component.
 */

const StyledLink = styled.a`
  cursor: pointer;
  text-decoration: none;
`;

/**
 * `RouterLink` component.
 */

const RouterLink = (props: Props, ref: any): ReactElement => {
  const { children, className, ...rest } = props;

  return (
    <NextLink
      passHref
      {...rest}
    >
      <StyledLink
        aria-label={props['aria-label']}
        className={className}
        ref={ref}
      >
        {children}
      </StyledLink>
    </NextLink>
  );
};

/**
 * Export `RouterLink` component.
 */

export default forwardRef<Props, any>(RouterLink);
