
/**
 * Module dependencies.
 */

import { authRoutes } from 'src/core/routes';
import { useAuth } from 'src/context/auth/context';
import { useFavoriteActions } from 'src/api/app/my-revigres/favorites/use-favorite-actions';
import { useFavorites } from 'src/context/favorites/context';
import { useRouter } from 'next/router';
import { useSnackbar } from 'src/context/snackbar/context';
import { useTranslate } from 'src/core/utils/translator';
import AddFavoriteModal from 'src/components/my-revigres/core/add-favorite-modal';
import ConfirmationModal from 'src/components/my-revigres/core/confirmation-modal';
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import filter from 'lodash/filter';
import find from 'lodash/find';
import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import useUserFavorites from 'src/api/app/my-revigres/favorites/use-user-favorites';
import useUserProjects from 'src/api/app/my-revigres/projects/use-user-projects';

/**
 * Export `FavoriteState` type.
 */

type FavoriteStateName = typeof favoriteStates;
export type FavoriteState = FavoriteStateName[keyof FavoriteStateName];

/**
 * Export Favorite states.
 */

export const favoriteStates = {
  off: 'NOT_FAVORITE',
  on: 'IS_FAVORITE'
};

/**
 * `Props` type.
 */

type Props = {
  isFavorite: FavoriteState | null,
  isFavoriteTriggered: boolean,
  onChangeFavoriteState: (state: FavoriteState) => void,
  onChangeFavoriteTriggered: (state: boolean) => void,
  onUpdateList?: () => void,
  productId: string,
  productName: string
}

/**
 * `FavoritesMiddleman` component.
 */

const FavoritesMiddleman = (props: Props): ReactElement => {
  const {
    isFavorite,
    isFavoriteTriggered,
    onChangeFavoriteState,
    onChangeFavoriteTriggered,
    onUpdateList,
    productId,
    productName
  } = props;

  const { translate } = useTranslate();
  const { showMessage } = useSnackbar();
  const { isAuthenticated, token } = useAuth();
  const router = useRouter();
  const { onCreateFavorite, onRemoveFavorite } = useFavoriteActions();
  const {
    data: projects,
    isSuccess: isSuccessProjects,
    refetch: refetchProjects
  } = useUserProjects({ token });

  const {
    data: favorites,
    isSuccess: isSuccessFavorites,
    refetch: refetchFavorites
  } = useUserFavorites({ token });

  const [isOpenAddFavoriteModal, setIsOpenAddFavoriteModal] = useState<boolean>();
  const [isOpenRemoveFavoriteModal, setIsOpenRemoveFavoriteModal] = useState<boolean>();
  const [thisFavoriteId, setThisFavoriteId] = useState<string | null>();
  const {
    onOpenProjectsSidebar,
    onUpdateFavoriteId,
    onUpdateProductName
  } = useFavorites();

  const hasProjects = useMemo<boolean>(() => {
    const projectsWithFavorites = filter(projects, ({ favorites }) => !isEmpty(favorites));
    const filteredFavorites = flatten(map(projectsWithFavorites, ({ favorites }) => {
      return map(favorites, ({ id }) => id);
    }));

    return filteredFavorites.includes(thisFavoriteId);
  }, [projects, thisFavoriteId]);

  const handleCreateFavorite = useCallback(() => {
    onChangeFavoriteState(favoriteStates.on);
    onCreateFavorite({
      productId,
      productName
    })
      .then(({ id }) => {
        setThisFavoriteId(id);
        onUpdateFavoriteId(id);
        onUpdateProductName(productName);
        setIsOpenAddFavoriteModal(true);
        refetchFavorites();
        refetchProjects();
      });
  }, [
    onChangeFavoriteState,
    onCreateFavorite,
    onUpdateFavoriteId,
    onUpdateProductName,
    productId,
    productName,
    refetchFavorites,
    refetchProjects
  ]);

  const handleRemoveFavorite = useCallback(() => {
    onChangeFavoriteState(favoriteStates.off);
    onRemoveFavorite({
      id: thisFavoriteId,
      productName
    })
      .then(() => {
        if (onUpdateList) {
          onUpdateList();

          return;
        }

        refetchProjects();
        refetchFavorites();
        setIsOpenRemoveFavoriteModal(false);
      });
  }, [
    onChangeFavoriteState,
    onRemoveFavorite,
    onUpdateList,
    productName,
    refetchFavorites,
    refetchProjects,
    thisFavoriteId
  ]);

  const handleChangeIsFavorite = useCallback(() => {
    if (!isAuthenticated) {
      showMessage(
        translate('my-revigres:favorites.signInMessage'), {
          appearance: 'warning'
        });

      router.push(authRoutes.signIn);

      return;
    }

    if (isFavorite === 'NOT_FAVORITE') {
      handleCreateFavorite();

      return;
    }

    if (hasProjects) {
      setIsOpenRemoveFavoriteModal(true);

      return;
    }

    handleRemoveFavorite();
  }, [
    handleCreateFavorite,
    handleRemoveFavorite,
    hasProjects,
    isAuthenticated,
    isFavorite,
    router,
    showMessage,
    translate
  ]);

  useEffect(() => {
    if (!isFavorite && isSuccessFavorites && isSuccessProjects) {
      const favorite = find(favorites, ({ product }) => product.id === productId);

      if (favorite) {
        onChangeFavoriteState(favoriteStates.on);
        setThisFavoriteId(favorite?.id);

        return;
      }

      onChangeFavoriteState(favoriteStates.off);
    }
  }, [
    favorites,
    isFavorite,
    isSuccessFavorites,
    isSuccessProjects,
    onChangeFavoriteState,
    productId
  ]);

  useEffect(() => {
    if (isFavorite && isFavoriteTriggered) {
      handleChangeIsFavorite();
      onChangeFavoriteTriggered(false);
    }
  }, [
    handleChangeIsFavorite,
    isFavorite,
    isFavoriteTriggered,
    onChangeFavoriteTriggered
  ]);

  return (
    <>
      <AddFavoriteModal
        isOpen={isOpenAddFavoriteModal}
        onClose={() => setIsOpenAddFavoriteModal(false)}
        onConfirm={() => {
          setIsOpenAddFavoriteModal(false);
          onOpenProjectsSidebar();
        }}
        productName={productName}
      />

      <ConfirmationModal
        description={translate('my-revigres:favorites.removeFavorite.description')}
        isOpen={isOpenRemoveFavoriteModal}
        onClose={() => setIsOpenRemoveFavoriteModal(false)}
        onConfirm={handleRemoveFavorite}
        title={translate('my-revigres:favorites.removeFavorite.title')}
      />
    </>
  );
};

/**
 * Export `FavoritesMiddleman` component.
 */

export default FavoritesMiddleman;
