
/**
 * Module dependencies.
 */

import { animations } from 'src/styles/animations';
import { baseUnit } from 'src/styles/utils/units';
import { breakpoints } from 'src/styles/breakpoints';
import { colors } from 'src/styles/colors';
import { dimensions } from 'src/styles/dimensions';
import { typography } from 'src/styles/type';
import { zIndex } from 'src/styles/z-index';
import keyframes from 'src/styles/keyframes';

/**
 * Export `theme`.
 */

export const theme = {
  animations,
  baseUnit,
  breakpoints,
  colors,
  dimensions,
  grid: {
    gutter: baseUnit * 4,
    gutterMobile: baseUnit * 2.5
  },
  keyframes,
  typography,
  zIndex
};
