
/**
 * Module dependencies.
 */

import { ActiveResults } from 'src/types/search';
import { media, units } from 'src/styles/utils';
import PopularList from './popular-list';
import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
  useMemo
} from 'react';

import RecentSearchLink from './recent-search-link';
import Stepper from 'src/components/core/stepper';
import SwitchButton from './switch-button';
import isEmpty from 'lodash/isEmpty';
import split from 'lodash/split';
import styled from 'styled-components';
import useStepper from 'src/hooks/use-stepper';

/**
 * `Props` type.
 */

type Props = {
  activeResults: ActiveResults,
  recentSearches: string,
  setActiveResults: Dispatch<SetStateAction<ActiveResults>>,
  setSearchValue: Dispatch<SetStateAction<string>>
};

/**
 * `Wrapper` styled components.
 */

const Wrapper = styled.div`
  padding-bottom: ${units(3)};

  ${media.min('md')`
    display: grid;
    grid-template-areas:
      'head head'
      'body .';
    grid-template-columns: 75% 25%;
  `}
`;

/**
 * `Head` styled component.
 */

const Head = styled.div`
  ${media.min('md')`
    grid-area: head;
  `}
`;

/**
 * `Body` styled component.
 */

const Body = styled.div`
  overflow: hidden;
  position: relative;

  ${media.min('md')`
    grid-area: body;
  `}
`;

/**
 * `RecentAndPopular` component.
 */

const RecentAndPopular = (props: Props): ReactElement => {
  const {
    activeResults,
    recentSearches,
    setActiveResults,
    setSearchValue
  } = props;

  const { goToStep, lastAction, step } = useStepper(['recent', 'popular']);
  const handleToggleSwitch = useCallback((step: ActiveResults) => {
    goToStep(step);
    setActiveResults(step);
  }, [goToStep, setActiveResults]);

  const recentSearchesList = useMemo(() => {
    return split(recentSearches, ',') ?? [];
  }, [recentSearches]);

  return (
    <Wrapper>
      <Head>
        <SwitchButton
          active={activeResults}
          onToggle={handleToggleSwitch}
        />
      </Head>

      <Body>
        <Stepper
          animationDistance={'100%'}
          lastAction={lastAction}
          step={step}
        >
          {step === 'recent' && !isEmpty(recentSearches) && (
            <div style={{ paddingTop: `${units(6)}` }}>
              {recentSearchesList.map((recentSearch, index) => (
                <RecentSearchLink
                  key={index}
                  name={recentSearch}
                  onClick={() => setSearchValue(recentSearch)}
                />
              ))}
            </div>
          )}

          {step === 'popular' && <PopularList />}
        </Stepper>
      </Body>
    </Wrapper>
  );
};

/**
 * Export `RecentAndPopular` component.
 */

export default RecentAndPopular;
