
/**
 * Module dependencies.
 */

import {
  acceptLanguageHeader,
  authorizationHeader,
  handleRequestError,
  request
} from 'src/core/utils/requests';

import { getApiEndpoint } from 'src/core/utils/api-url-resolver';

/**
 * `Query` type.
 */

type Query = {
  locale: string,
  token: string
};

/**
 * Export `fetchUserFavorites`.
 */

export async function fetchUserFavorites({ locale, token }: Query) {
  const endpoint = getApiEndpoint('getUserFavorites');

  if (!token) {
    return;
  }

  try {
    const { data } = await request.get(endpoint, {
      headers: {
        ...acceptLanguageHeader(locale),
        ...authorizationHeader(token)
      }
    });

    return data?.data;
  } catch (error) {
    handleRequestError(error);
  }
}
