
/**
 * Module dependencies.
 */

import { Theme } from 'src/types/theme';
import { css } from 'styled-components';
import get from 'lodash/get';
import isNil from 'lodash/isNil';

/**
 * Create media queries based on breakpoint.
 */

const createMediaQueries = query => (breakpointKey: string) => (styles, ...interpolations) => {
  /* eslint-disable indent */
  return css`
    ${(props: Theme) => {
      const breakpointValue = get(props, ['theme', 'breakpoints', breakpointKey]);

      if (isNil(breakpointValue)) {
        throw new Error(`🚨 The '${breakpointKey}' key was not found on the theme configuration.`);
      }

      const renderStyles = css(styles, ...interpolations);

      if (breakpointValue === 0) {
        return renderStyles;
      }

      return css`${query(breakpointValue)} {${renderStyles}}`;
    }}
  `;
  /* eslint-enable indent */
};

/**
 * Export `createMediaQuery`.
 */

export function createMediaQuery(boundType: string, compensation = 0) {
  return (breakpoint: number): string => `@media (${boundType}: ${breakpoint + compensation}px)`;
}

/**
 * Export `createMediaQueryOrientation`.
 */

export function createMediaQueryOrientation(boundType: string) {
  return (breakpoint: number): string => `@media (max-width: ${breakpoint}px) and (orientation: ${boundType})`;
}

/**
 * Export `maxWidth`.
 */

export const maxWidth = createMediaQuery('max-width', -1);

/**
 * Export `minWidth`.
 */

export const minWidth = createMediaQuery('min-width');

/**
 * Export `landscape`.
 */

export const landscape = createMediaQueryOrientation('landscape');

/**
 * Export `portrait`.
 */

export const portrait = createMediaQueryOrientation('portrait');

/**
 * Export `media`.
 */

export const media = {
  landscape: createMediaQueries(landscape),
  max: createMediaQueries(maxWidth),
  min: createMediaQueries(minWidth),
  portrait: createMediaQueries(portrait)
};
