
/**
 * Module dependencies.
 */

import { ProductProps } from 'src/types/products';
import { useLocalizedRoute } from 'src/core/utils/routes';
import Card from './card';
import React, { ReactElement } from 'react';

/**
 * `Props` type.
 */

type Props = ProductProps & {
  image_url?: string | null
};

/**
 * `ProductCard` component.
 */

const ProductCard = (props: Props): ReactElement => {
  const {
    format,
    image_url, // eslint-disable-line id-match
    name,
    slug
  } = props;

  const routeResolver = useLocalizedRoute();

  return (
    <Card
      description={format as any}
      href={routeResolver('productDetails', { slug })}
      imageUrl={image_url}
      title={name}
    />
  );
};

/**
 * Export `ProductCard` component.
 */

export default ProductCard;
