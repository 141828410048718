
/**
 * Module dependencies.
 */

import {
  acceptLanguageHeader,
  handleRequestError,
  request
} from 'src/core/utils/requests';

import { getApiEndpoint } from 'src/core/utils/api-url-resolver';

/**
 * `Query` type.
 */

type Query = {
  hasPreview?: boolean | undefined,
  locale: string,
  slug: string
};

/**
 * Export `fetchProduct`.
 */

export async function fetchProduct({ hasPreview, locale, slug }: Query): Promise<any> {
  const endpoint = getApiEndpoint('getProduct', { slug });

  try {
    const { data } = await request.get(endpoint, {
      headers: acceptLanguageHeader(locale),
      ...hasPreview && { params: { preview: true } }
    });

    return data?.data;
  } catch (error) {
    handleRequestError(error);
  }
}
