
/**
 * Bezier.
 */

const bezier = {
  easeInOutSine: 'cubic-bezier(0.445, 0.05, 0.55, 0.95)',
  easeOutQuad: 'cubic-bezier(0.4, 0, 0.2, 1)'
};

/**
 * Default transition.
 */

const defaultTransition = '0.5s ease';

/**
 * Fast transition.
 */

const fastTransition = '0.2s ease';

/**
 * Ease out quad transition.
 */

const easeOutQuadTransition = `0.5s ${bezier.easeOutQuad}`;

/**
 * Menu transition.
 */

const menuTransition = 'opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1), transform 0.45s cubic-bezier(0.4, 0, 0.2, 1);';

/**
 * Export `animations`.
 */

export const animations = {
  bezier,
  defaultTransition,
  easeOutQuadTransition,
  fastTransition,
  menuTransition
};
