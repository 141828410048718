
/**
 * Module dependencies.
 */

import {
  Configure,
  Index,
  connectStateResults
} from 'react-instantsearch-dom';

import { Result } from 'src/types/search';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { color, units } from 'src/styles/utils';
import { hitsPerPage, indexes } from 'src/core/search-config';
import { theme } from 'styled-tools';
import { useTranslate } from 'src/core/utils/translator';
import Hits from 'src/components/search/search-results/hists/hits';
import InfiniteHits from 'src/components/search/search-results/hists/infinite-hits';
import PopularList from 'src/components/search/recent-and-popular/popular-list';
import React, { ReactElement, useMemo } from 'react';
import TabContent from 'src/components/search/search-results/tab-content';
import Type from 'src/components/core/typography';
import styled from 'styled-components';

/**
 * Items.
 */

const items = ['collections', 'products', 'others'];

/**
 * `AllResults` type.
 */

type AllResults = {
  [key: string]: Result
}

/**
 * `Props` type.
 */

type Props = {
  allSearchResults: AllResults,
  currentSearch: string
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: repeat(2, max-content) 1fr;
  height: 650px;
  max-height: 650px;
  overflow: hidden;
`;

/**
 * `StyledTabs` styled component.
 */

const StyledTabs = styled(Tabs)`
  overflow-y: auto;
`;

/**
 * `TabItem` styled components.
 */

const TabItem = styled.a`
  ${theme('typography.styles.p')}

  color: ${color('grey300')};
  cursor: pointer;
  padding: ${units(1)} 0;
  transition: ${theme('animations.defaultTransition')};

  &:focus,
  &:hover {
    color: ${color('grey900')};
  }
`;

/**
 * `StyledTabList` styled components.
 */

const StyledTabList = styled(TabList)`
  align-items: center;
  display: grid;
  grid-gap: ${units(2)};
  grid-template-columns: repeat(5, max-content);
  margin-bottom: ${units(3)};
  overflow-x: auto;
  overflow-y: hidden;
  padding: ${units(1.5)} 0;
  scroll-snap-type: x mandatory;

  .selected ${TabItem} {
    color: ${color('grey900')};
    text-decoration: underline;
  }
`;

/**
 * Get totals.
 */

function getTotals(results: AllResults) {
  const collections = results[indexes.productsCollections]?.nbHits ?? 0;
  const products = results[indexes.products]?.nbHits ?? 0;
  const others = results[indexes.contacts]?.nbHits ?? 0;

  return {
    collections,
    others,
    products,
    total: collections + others + products
  };
}

/**
 * `SearchResults` component.
 */

const SearchResults = (props: Props): ReactElement => {
  const { allSearchResults, currentSearch } = props;
  const { translate } = useTranslate();
  const totals = useMemo(() => getTotals(allSearchResults), [allSearchResults]);

  return (
    <Wrapper>
      {Object.values(indexes).map(index => (
        <Index
          indexName={index}
          key={index}
        />
      ))}

      {totals?.total === 0 ? (
        <>
          <Type.H6>
            {translate('common:search.results.noResults')}
          </Type.H6>

          <Type.H6
            fontWeight={400}
            marginBottom={units(5.5)}
            small
          >
            {`"${currentSearch}"`}
          </Type.H6>

          <Type.H6
            fontWeight={400}
            marginBottom={units(3.5)}
            small
          >
            {translate('common:search.labels.suggestions')}
          </Type.H6>

          <PopularList />
        </>
      ) : (
        <>
          <Type.H6>
            {translate('common:search.results.title')}
          </Type.H6>

          <Type.H6
            fontWeight={400}
            marginBottom={units(5.5)}
            small
          >
            {`"${currentSearch}"`}
          </Type.H6>

          <StyledTabs>
            <StyledTabList>
              {items.map((name, index: number) => totals[name] > 0 && (
                <Tab
                  key={index}
                  selectedClassName={'selected'}
                >
                  <TabItem>
                    {translate(`common:search.results.tabs.${name}`, {
                      total: totals[name]
                    })}
                  </TabItem>
                </Tab>
              ))}
            </StyledTabList>

            {totals.collections > 0 && (
              <TabPanel>
                <TabContent>
                  <Index indexName={indexes.productsCollections}>
                    <Configure hitsPerPage={hitsPerPage} />

                    <InfiniteHits
                      index={indexes.productsCollections}
                      isKioskCard
                    />
                  </Index>
                </TabContent>
              </TabPanel>
            )}

            {totals.products > 0 && (
              <TabPanel>
                <TabContent>
                  <Index indexName={indexes.products}>
                    <Configure hitsPerPage={hitsPerPage} />

                    <InfiniteHits
                      index={indexes.products}
                      isKioskCard
                    />
                  </Index>
                </TabContent>
              </TabPanel>
            )}

            {totals.others > 0 && (
              <TabPanel>
                <TabContent>
                  <Index indexName={indexes.contacts}>
                    <Configure hitsPerPage={hitsPerPage} />

                    <Hits
                      index={indexes.contacts}
                      isKiosk
                    />
                  </Index>
                </TabContent>
              </TabPanel>
            )}
          </StyledTabs>
        </>
      )}
    </Wrapper>
  );
};

/**
 * Export `SearchResults` component.
 */

export default connectStateResults(SearchResults);
