
/**
 * Module dependencies.
 */

import { createFavorite } from 'src/api/app/my-revigres/favorites/create-favorite';
import { removeFavorite } from 'src/api/app/my-revigres/favorites/remove-favorite';
import { updateFavorite } from 'src/api/app/my-revigres/favorites/update-favorite';
import { useAuth } from 'src/context/auth/context';
import { useCallback } from 'react';
import { useMutation } from 'react-query';
import { useSnackbar } from 'src/context/snackbar/context';
import { useTranslate } from 'src/core/utils/translator';

/**
 * Export `useFavoriteActions` hook.
 */

export function useFavoriteActions() {
  const { translate } = useTranslate();
  const { showMessage } = useSnackbar();
  const { token, updateUser } = useAuth();
  const { mutateAsync: createFavoriteMutation } = useMutation(createFavorite);
  const { mutateAsync: removeFavoriteMutation } = useMutation(removeFavorite);
  const {
    isLoading: isUpdatingFavorite,
    mutateAsync: updateFavoriteMutation
  } = useMutation(updateFavorite);

  const handleCreateFavorite = useCallback((formData: {
    productId: string,
    productName: string
  }) => {
    const { productId, productName } = formData;

    return createFavoriteMutation({ productId, token }, {
      onError: () => {
        showMessage(
          translate('my-revigres:favorites.addFavorite.errorMessage', { productName }), {
            appearance: 'error'
          });

        updateUser();
      },
      onSuccess: () => {
        updateUser();
      }
    });
  }, [createFavoriteMutation, showMessage, token, translate, updateUser]);

  const handleRemoveFavorite = useCallback((formData: {
    id: string,
    productName: string
  }) => {
    const { id, productName } = formData;

    return removeFavoriteMutation({ id, token }, {
      onError: () => {
        showMessage(
          translate('my-revigres:favorites.removeFavorite.errorMessage', { productName }), {
            appearance: 'error'
          });
      },
      onSuccess: () => {
        showMessage(
          translate('my-revigres:favorites.removeFavorite.successMessage', { productName }), {
            appearance: 'success'
          });

        updateUser();
      }
    });
  }, [removeFavoriteMutation, showMessage, token, translate, updateUser]);

  const handleUpdateFavorite = useCallback((formData: {
    id: string,
    note: string
  }) => {
    return updateFavoriteMutation({ ...formData, token }, {
      onError: () => {
        showMessage(
          translate('my-revigres:favorites.editFavorite.errorMessage'), {
            appearance: 'error'
          });
      },
      onSuccess: () => {
        showMessage(
          translate('my-revigres:favorites.editFavorite.successMessage'), {
            appearance: 'success'
          });
      }
    });
  }, [showMessage, token, translate, updateFavoriteMutation]);

  return {
    isUpdatingFavorite,
    onCreateFavorite: handleCreateFavorite,
    onRemoveFavorite: handleRemoveFavorite,
    onUpdateFavorite: handleUpdateFavorite
  };
}
