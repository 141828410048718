
/**
 * Module dependencies.
 */

import { QueryObserverBaseResult, useQuery } from 'react-query';
import { fecthShowroomModule } from './fetch-showroom-module';
import { useMemo } from 'react';
import { useRouter } from 'next/router';

/**
 * `Props` type.
 */

type Props<P> = QueryObserverBaseResult<P, unknown>;

/**
 * `Options` type.
 */

type Options<P> = {
  initialData?: P
};

/**
 * Action type.
 */

const actionType = ({ locale }): string => {
  return `${locale}-showroom-module`;
};

/**
 * `useShowroomModule` hook.
 */

function useShowroomModule<P>(options?: Options<P>): Props<P> {
  const { locale } = useRouter();
  const variables = useMemo(() => ({ locale }), [locale]);
  const result = useQuery([actionType(variables), variables], () => {
    return fecthShowroomModule(variables);
  }, {
    initialData: options?.initialData
  });

  return result;
}

/**
 * Export `useShowroomModule` hook.
 */

export default useShowroomModule;
