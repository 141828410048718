/**
 * Module dependencies.
 */

import { color, units } from 'src/styles/utils';
import { getApplicationsSorted } from 'src/core/utils/sort-applications';
import { resolvePageUrl } from 'src/core/utils/routes';
import { theme } from 'styled-tools';
import { useRouter } from "next/router";
import { useTranslate } from 'src/core/utils/translator';
import React, { ReactElement } from 'react';
import Svg from 'src/components/core/svg';
import Type from 'src/components/core/typography';
import arrowRightIcon from 'src/assets/svg/arrow-right.svg';
import styled from 'styled-components';
import useProductApplications from 'src/api/app/products/use-product-applications';

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  margin-top: ${units(4)};
  padding-bottom: ${units(8)};
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.H6).attrs({
  as: 'p',
  small: true
})`
  font-family: ${theme('typography.fontFamily.serif')};
  margin-bottom: ${units(3)};
`;

/**
 * `Icon` styled component.
 */

const Icon = styled(Svg).attrs({ icon: arrowRightIcon, size: units(2.5) })`
  color: ${color('beige200')};
  margin-right: 26px;
  transition: ${theme('animations.defaultTransition')};
  transition-property: color, transform;
`;

/**
 * `Name` styled component.
 */

const Name = styled.span`
  ${theme('typography.styles.p')}

  transition: transform ${theme('animations.defaultTransition')};
`;

/**
 * `Link` styled component.
 */

const Link = styled.a`
  align-items: center;
  color: ${color('grey400')};
  cursor: pointer;
  display: flex;
  padding: ${units(3.5)} 0;
  text-decoration: none;
  transition: color ${theme('animations.defaultTransition')};
  white-space: nowrap;

  &:focus,
  &:hover {
    color: ${color('black')};

    ${Icon} {
      color: ${color('black')};
      transform: translateX(${units(1.5)});
    }

    ${Name} {
      transform: translateX(${units(0.75)});
    }
  }
`;

/**
 * `ApplicationSection` component.
 */

const ApplicationSection = (): ReactElement | null => {
  const { locale, translate } = useTranslate();
  const router = useRouter();

  const {
    data,
    isSuccess
  } = useProductApplications();

  let sortedData = [];

  if (isSuccess) {
    sortedData = getApplicationsSorted(data);
  }

  if (!sortedData.length) {
    return null;
  }

  const handleOnClick = (url: string) => () => {
    router.push(url);
  };

  return (
    <Wrapper>
      <Title>{translate('footer:applications.title')}</Title>

      {sortedData.map((item) => (
        <Link key={item.slug} onClick={handleOnClick(`${resolvePageUrl('applications', locale)}/${item.slug}`)}>
          <Icon />

          <Name>
            {item.name}
          </Name>
        </Link>
      ))}
    </Wrapper>
  )
};

/**
 * Export `ApplicationSection` component.
 */

export default ApplicationSection;
