
/**
 * Module dependencies.
 */

import { ApiPageSlug, RouteName } from 'i18n-routes';
import { apiPageSlugs } from 'src/core/routes/api-page-slugs';
import { regexes } from 'src/core/utils/regexes';
import { resolve } from 'url';
import { routes } from 'src/core/routes';
import { useCallback } from 'react';
import { useRouter } from 'next/router';
import template from 'lodash/template';

/**
 * Constants.
 */

const baseUrl: string = process.env.NEXT_PUBLIC_BASE_URL;
const defaultLocale: string = process.env.NEXT_PUBLIC_DEFAULT_LOCALE;

/**
 * `RouteInterpolations` type.
 */

type RouteInterpolations = {
  [key: string]: string
};

/**
 * Template options.
 */

const templateOptions = {
  interpolate: /:([\s\S]+?)\b/g
};

/**
 * Export `routeTemplate`.
 */

export function routeTemplate(route: string, interpolations: RouteInterpolations): string {
  return template(route, templateOptions)(interpolations);
}

/**
 * `isExternalRoute` util.
 */

export function isExternalRoute(route: string): boolean {
  return regexes.url.test(route) || regexes.emailLink.test(route) || regexes.telLink.test(route);
}

/**
 * Export `getApiPageSlugByLocale`
 */

export function getApiPageSlugByLocale(pageSlug: ApiPageSlug, locale: string): string {
  return apiPageSlugs[locale][pageSlug];
}

/**
 * Export `useLocalizedRoute` hook.
 */

export function useLocalizedRoute() {
  const { locale } = useRouter();
  const handleRouteResolver = useCallback((routeName: RouteName, interpolations?: RouteInterpolations) => {
    const route = routes[locale][routeName];

    if (!interpolations) {
      return route;
    }

    return template(route, templateOptions)(interpolations);
  }, [locale]);

  return handleRouteResolver;
}

/**
 * Export `getRouteByLocale`.
 */

export function getRouteByLocale(routeName: RouteName, locale: string) {
  const route = routes[locale][routeName];

  return locale === defaultLocale ? route : `/${locale}${route}`;
}

/**
 * Export `resolvePageUrl`.
 */

export function resolvePageUrl(routeName: RouteName, locale: string) {
  const path = getRouteByLocale(routeName, locale);

  return resolve(baseUrl, path);
}
