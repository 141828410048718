
/**
 * Module dependencies.
 */

import { NavbarTheme } from 'src/types/navbar';
import { color, media, units } from 'src/styles/utils';
import { ifProp, switchProp, theme } from 'styled-tools';
import React, { ReactElement } from 'react';
import styled, { css } from 'styled-components';

/**
 * Constants.
 */

const duration = '0.3s';
const bezier = 'cubic-bezier(0.23, 1, 0.32, 1)';

/**
 * `Props` type.
 */

type Props = {
  className?: string,
  colorTheme: NavbarTheme,
  isOpen: boolean,
  isScrolled?: boolean,
  onClick: () => void
};

/**
 * `Button` styled component.
 */

const Button = styled.button<{
  active: boolean,
  colorTheme: NavbarTheme,
  isScrolled?: boolean
}>`
  -webkit-tap-highlight-color: transparent;
  align-items: center;
  appearance: none;
  background: none;
  border: 0;
  cursor: pointer;
  display: flex;
  height: ${units(4)};
  justify-content: center;
  outline: none;
  padding: 0;
  transition: color 0.3s ease, opacity 0.3s ease-out;
  width: ${units(4)};
  z-index: ${theme('zIndex.hamburgerMenu')};

  ${media.min('ms')`
    margin-right: ${units(0.5)};
  `}

  ${switchProp('colorTheme', {
    dark: css`
      color: ${color('black')};
    `,
    light: css`
      color: ${color('white')};
    `
  })}

  ${ifProp('isScrolled', css`
    color: ${color('black')};
  `)}

  ${ifProp('active', css`
    color: ${color('white')};
    transition-delay: 0.4s, 0s;
  `)}

  &:hover,
  &:focus {
    opacity: 0.6;
    transition-delay: 0.4s, 0s;
  }
`;

/**
 * `Lines` styled component.
 */

const Lines = styled.span<{ active: boolean }>`
  position: relative;
  transition: background-color 0.3s ease;

  &,
  &::before,
  &::after {
    background-color: currentcolor;
    height: 2px;
    width: 30px;
  }

  &::before,
  &::after {
    content: '';
    left: 0;
    position: absolute;
  }

  &::before {
    bottom: 12px;
    transition:
      bottom ${duration} ${duration} ${bezier},
      transform ${duration} ${bezier};
  }

  &::after {
    top: 12px;
    transition:
      top ${duration} ${duration} ${bezier},
      transform ${duration} ${bezier};
  }

  ${ifProp('active', css`
    background-color: transparent !important;

    &::before {
      bottom: 0;
      transform: rotate(-45deg);
      transition:
        bottom ${duration} ${bezier},
        transform ${duration} ${duration} ${bezier};
    }

    &::after {
      top: 0;
      transform: rotate(45deg);
      transition:
        top ${duration} ${bezier},
        transform ${duration} ${duration} ${bezier};
    }
  `)}
`;

/**
 * `HamburgerMenu` component.
 */

const HamburgerMenu = (props: Props): ReactElement => {
  const { className, colorTheme, isOpen, isScrolled, onClick } = props;

  return (
    <Button
      active={isOpen}
      aria-label={'Menu'}
      className={className}
      colorTheme={colorTheme}
      isScrolled={isScrolled}
      onClick={onClick}
    >
      <Lines active={isOpen} />
    </Button>
  );
};

/**
 * Default props.
 */

HamburgerMenu.defaultProps = {
  colorTheme: 'dark'
};

/**
 * Export `HamburgerMenu` component.
 */

export default HamburgerMenu;
