
/**
 * Module dependencies.
 */

import { ActiveResults } from 'src/types/search';
import { Index, InstantSearch } from 'react-instantsearch-dom';
import {
  client,
  indexes,
  recenteSearchesKey
} from 'src/core/search-config';

import { color, media, units } from 'src/styles/utils';
import { ifProp, theme } from 'styled-tools';
import { useLocalizedRoute } from 'src/core/utils/routes';
import { useRouter } from 'next/router';
import { useTranslate } from 'src/core/utils/translator';
import ApplicationSection from './applications';
import Fill from 'src/components/core/layout/fill';
import IconButton from 'src/components/core/buttons/icon-button';
import ModalPortal from 'src/components/core/modals/modal-portal';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import RecentAndPopular from 'src/components/search/recent-and-popular';
import RouterLink from 'src/components/core/links/router-link';
import SearchForm, { VirtualSearchBox } from 'src/components/search/search-form';
import SearchResults from 'src/components/search/search-results';
import Svg from 'src/components/core/svg';
import Type from 'src/components/core/typography/index';
import closeIcon from 'src/assets/svg/close.svg';
import concat from 'lodash/concat';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import logoIcon from 'src/assets/svg/logo.svg';
import styled, { css } from 'styled-components';
import uniqWith from 'lodash/uniqWith';
import useBreakpoint from 'src/hooks/use-breakpoint';

/**
 * `Props` type.
 */

type Props = {
  isOpen: boolean,
  onClose: () => void
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled(Fill) <{ visible: boolean }>`
  background-color: ${color('grey100')};
  display: grid;
  grid-template-areas:
    '. logo          title         closeButton   .'
    '. searchWrapper searchWrapper searchWrapper .';
  grid-template-columns: ${theme('grid.gutterMobile')}px ${units(5)} 1fr ${units(5)} ${theme('grid.gutterMobile')}px;
  grid-template-rows: ${theme('dimensions.navbarHeightMobile')}px 1fr;
  outline: none;
  overflow: auto;
  position: fixed !important;
  transition: visibility ${theme('animations.defaultTransition')};
  z-index: ${theme('zIndex.modal')};

  ${ifProp('visible', css`
    animation: ${theme('keyframes.fadeIn')} ${theme('animations.defaultTransition')} both;
    visibility: visible;
  `, css`
    animation: ${theme('keyframes.fadeOut')} ${theme('animations.defaultTransition')} both;
    pointer-events: none;
    visibility: hidden;
  `)}

  ${media.min('ms')`
    grid-template-areas:
      '. logo .             closeButton .'
      '. .    searchWrapper .           .';
    grid-template-columns: ${units(4)} 1fr 10fr 1fr ${units(4)};
    grid-template-rows: ${units(13)} 1fr;
  `}

  ${media.min('lg')`
    grid-template-columns: ${units(4)} 3fr 6fr 3fr ${units(4)};
  `}
`;

/**
 * `LogoLink` styled component.
 */

const LogoLink = styled(RouterLink)`
  align-self: center;
  color: ${color('black')};
  grid-area: logo;
  grid-row: 1;
  justify-self: start;
  transition: opacity ${theme('animations.defaultTransition')};

  &:focus,
  &:hover {
    opacity: 0.7;
  }
`;

/**
 * `CloseButton` styled component.
 */

const CloseButton = styled(IconButton).attrs({
  icon: closeIcon,
  iconSize: units(5)
})`
  grid-area: closeButton;
  grid-row: 1;
  justify-self: end;
  line-height: 0;

  ${media.max('ms')`
    align-self: center;
  `}
`;

/**
 * `SearchWrapper` styled component.
 */

const SearchWrapper = styled.div`
  grid-area: searchWrapper;
  grid-row: 2;

  ${media.max('ms')`
    padding-top: ${units(5)};
  `}
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.Paragraph).attrs({ as: 'h5' })`
  align-self: center;
  grid-area: title;
  justify-self: center;

  ${media.min('ms')`
    display: none;
  `}
`;

/**
 * Normalize recent searches.
 */

function normalizeRecentSearches(oldValues: string | null, newValue: string) {
  if (isEmpty(oldValues)) {
    return newValue;
  }

  const values = concat(newValue, oldValues.split(','));
  const removeDuplicates = uniqWith(values, isEqual);

  return removeDuplicates.slice(0, 5).join();
}

/**
 * `Search` component.
 */

const Search = (props: Props): ReactElement => {
  const { isOpen, onClose } = props;
  const { locale, translate } = useTranslate();
  const router = useRouter();
  const routeResolver = useLocalizedRoute();
  const isMobile = useBreakpoint('ms', 'max');
  const [recentSearches, setRecentSearches] = useState<string>();
  const [activeResults, setActiveResults] = useState<ActiveResults>('recent');
  const [searchValue, setSearchValue] = useState<string>('');
  const handleAddRecents = useCallback((searchQuery: string) => {
    const oldValues = window.localStorage.getItem(recenteSearchesKey);
    const newValue = normalizeRecentSearches(oldValues, searchQuery);

    window.localStorage.setItem(recenteSearchesKey, newValue);

    // Update the initital state of recent searches.
    setRecentSearches(newValue);
  }, []);

  const handleClose = useCallback(() => {
    onClose();
    setSearchValue('');
    setActiveResults('popular');
  }, [onClose]);

  useEffect(() => {
    if (!isEmpty(searchValue)) {
      setActiveResults('results');

      return;
    }

    setActiveResults('recent');
  }, [searchValue]);

  useEffect(() => {
    const oldValues = window.localStorage.getItem(recenteSearchesKey);

    if (!isEmpty(oldValues)) {
      // Set the initital state of recent searches.
      setRecentSearches(oldValues);
    }
  }, []);

  useEffect(() => {
    const handleRouteChange = () => {
      if (isOpen) {
        handleClose();
      }
    };

    router.events.on('beforeHistoryChange', handleRouteChange);

    return () => {
      router.events.off('beforeHistoryChange', handleRouteChange);
    };
  }, [handleClose, isOpen, router.events]);

  return (
    <ModalPortal isOpen={isOpen}>
      <Wrapper
        aria-hidden={!isOpen}
        aria-modal
        role={'dialog'}
        tabIndex={isOpen ? -1 : undefined}
        visible={isOpen}
      >
        <LogoLink
          href={routeResolver('home')}
          locale={locale}
        >
          <Svg
            icon={logoIcon}
            size={isMobile ? units(10) : units(13)}
          />
        </LogoLink>

        <CloseButton onClick={handleClose} />

        {isMobile && (
          <Title>
            {translate('common:search.title')}
          </Title>
        )}

        <SearchWrapper>
          <InstantSearch
            indexName={indexes.productsCollections}
            searchClient={client}
          >
            <SearchForm
              initialValue={searchValue}
              onAddRecents={handleAddRecents}
              setSearchValue={setSearchValue}
            />

            {Object.values(indexes).map((indexName, index: number) => (
              <Index
                indexName={indexName}
                key={index}
              />
            ))}
          </InstantSearch>

          <InstantSearch
            indexName={indexes.productsCollections}
            searchClient={client}
          >
            <VirtualSearchBox defaultRefinement={searchValue} />

            {activeResults === 'results' && (
              <SearchResults currentSearch={searchValue} />
            )}
          </InstantSearch>

          {activeResults !== 'results' && (
            <RecentAndPopular
              activeResults={activeResults}
              recentSearches={recentSearches}
              setActiveResults={setActiveResults}
              setSearchValue={setSearchValue}
            />
          )}

          <ApplicationSection />
        </SearchWrapper>
      </Wrapper>
    </ModalPortal>
  );
};

/**
 * Export `Search` component.
 */

export default Search;
