
/**
 * Export `trafficLevel`.
 */

export const trafficLevel = {
  level1: '1',
  level2: '2',
  level3: '3',
  level4: '4',
  level5: '5'
} as const;

/**
 * Export `trafficLevelIcons`.
 */

export const trafficLevelIcons = {
  [trafficLevel.level1]: require('src/assets/svg/traffic-level/1.svg'),
  [trafficLevel.level2]: require('src/assets/svg/traffic-level/2.svg'),
  [trafficLevel.level3]: require('src/assets/svg/traffic-level/3.svg'),
  [trafficLevel.level4]: require('src/assets/svg/traffic-level/4.svg'),
  [trafficLevel.level5]: require('src/assets/svg/traffic-level/5.svg')
};
