
/**
 * Module dependencies.
 */

import { color, media, units } from 'src/styles/utils';
import { ifProp, theme } from 'styled-tools';
import { myRevigresRoutes } from 'src/core/routes';
import { useTranslate } from 'src/core/utils/translator';
import Button from 'src/components/core/buttons/button';
import Fill from 'src/components/core/layout/fill';
import IconButton from 'src/components/core/buttons/icon-button';
import ModalPortal from 'src/components/core/modals/modal-portal';
import RawHtml from 'src/components/core/raw-html';
import React, { ReactElement } from 'react';
import Type from 'src/components/core/typography';
import closeIcon from 'src/assets/svg/close.svg';
import styled, { css } from 'styled-components';
import useBodyScroll from 'src/hooks/use-body-scroll';

/**
 * `Props` type.
 */

type Props = {
  isOpen: boolean,
  onClose: () => void,
  onConfirm: () => void,
  productName: string
}

/**
 * `Overlay` styled component.
 */

const Overlay = styled(Fill)<{ visible: boolean }>`
  align-items: center;
  background-color: ${color.transparentize('black', 0.3)};
  display: flex;
  justify-content: center;
  position: fixed !important;
  z-index: ${theme('zIndex.modal')};

  ${ifProp('visible', css`
    animation: ${theme('keyframes.fadeIn')} ${theme('animations.defaultTransition')} both;
    visibility: visible;
  `, css`
    animation: ${theme('keyframes.fadeOut')} ${theme('animations.defaultTransition')} both;
    pointer-events: none;
    visibility: hidden;
  `)}
`;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div<{ visible: boolean }>`
  background-color: ${color('beige150')};
  color: ${color('textColor')};
  max-width: ${units(75)};
  padding: ${units(4)};
  transition: visibility ${theme('animations.defaultTransition')};
  width: 90%;

  ${media.min('ms')`
    width: max-content;
  `}

  ${ifProp('visible', css`
    animation: ${theme('keyframes.fadeIn')} ${theme('animations.defaultTransition')} both;
    visibility: visible;
  `, css`
    animation: ${theme('keyframes.fadeOut')} ${theme('animations.defaultTransition')} both;
    pointer-events: none;
    visibility: hidden;
  `)}
`;

/**
 * `Header` styled component.
 */

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${units(2)};
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.H6)`
  font-size: ${units(3)} !important;
  line-height: 1.2em !important;
`;

/**
 * `Description` styled component.
 */

const Description = styled(Type.Paragraph)`
  color: ${color('grey400')};
`;

/**
 * `ButtonsWrapper` styled component.
 */

const ButtonsWrapper = styled.div`
  display: grid;
  grid-gap: ${units(2)};
  grid-template-columns: 100%;
  padding-top: ${units(4)};

  ${media.min('sm')`
    grid-template-columns: max-content;
  `}

  ${media.min('ms')`
    grid-template-columns: repeat(3, max-content);
  `}
`;

/**
 * `AddFavoriteModal` component.
 */

const AddFavoriteModal = (props: Props): ReactElement => {
  const { isOpen, onClose, onConfirm, productName } = props;
  const { locale, translate } = useTranslate();

  useBodyScroll({ off: isOpen });

  return (
    <ModalPortal isOpen={isOpen}>
      <Overlay visible={isOpen}>
        <Wrapper visible={isOpen}>
          <Header>
            <Title>
              {translate('my-revigres:favorites.addFavorite.title')}
            </Title>

            <IconButton
              icon={closeIcon}
              iconSize={units(3)}
              onClick={onClose}
            />
          </Header>

          <RawHtml element={Description}>
            {translate('my-revigres:favorites.addFavorite.description', {
              productName
            })}
          </RawHtml>

          <ButtonsWrapper>
            <Button onClick={onClose}>
              {translate('common:actions.close')}
            </Button>

            <Button
              onClick={onConfirm}
              variant={'outlined'}
            >
              {translate('common:actions.yes')}
            </Button>

            <Button
              href={myRevigresRoutes.favorites}
              locale={locale}
              onClick={onConfirm}
              variant={'outlined'}
            >
              {translate('my-revigres:favorites.goToFavorites')}
            </Button>
          </ButtonsWrapper>
        </Wrapper>
      </Overlay>
    </ModalPortal>
  );
};

/**
 * Export `AddFavoriteModal` component.
 */

export default AddFavoriteModal;
