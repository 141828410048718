
/**
 * Module dependencies.
 */

import applyCaseMiddleware from 'axios-case-converter';
import axios from 'axios';
import qs from 'qs';

/**
 * Locales.
 */

const locales = process.env.NEXT_PUBLIC_LOCALE_ISO_CODES
  .split(',')
  .reduce((acc, curr) => {
    const key = curr.split('_')[0];
    acc[key] = curr;

    return acc;
  }, {});

/**
 * Export `acceptLanguageHeader`.
 */

export function acceptLanguageHeader(locale: string) {
  return {
    'Accept-Language': locales[locale]
  };
}

/**
 * Export `authorizationHeader`.
 */

export function authorizationHeader(token: string | null | undefined) {
  return {
    Authorization: `Bearer ${token}`
  };
}

/**
 * Export `request.
 */

export const request = applyCaseMiddleware(axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_BASE_URL,
  paramsSerializer: params => qs.stringify(params)
}), { ignoreHeaders: true });

/**
 * Export `authRequest.
 */

export const authRequest = axios.create({
  baseURL: process.env.NEXT_PUBLIC_AUTH_API_BASE_URL,
  paramsSerializer: params => qs.stringify(params)
});

/**
 * Export `handleRequestError`.
 */

export function handleRequestError(error: any) {
  return error?.response ?? error;
}
