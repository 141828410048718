
/**
 * Export `colors`.
 */

import { theme } from 'styled-tools';
import colorUtil from 'color';

/**
 * Export `transparentize`.
 */

export function transparentize(value: string, transparency: number): string {
  return colorUtil(value).alpha(transparency).rgb().string();
}

/**
 * Export `color`.
 */

export const color = (colorName: string) => props => {
  if (colorName.startsWith('#')) {
    return colorName;
  }

  return theme(`colors.${colorName}`)(props);
};

/**
 * Transparentize theme color.
 */

color.transparentize = (colorName: string, transparency: number) => props => {
  const colorValue = color(colorName)(props);

  return transparentize(colorValue, transparency);
};
