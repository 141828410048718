
/**
 * Export `mediasCollectionName`.
 */

export const mediasCollectionName = {
  bimArchive: 'bim_archive',
  colorImage: 'color_image',
  featuredImages: 'featured_images',
  functionImage: 'function_image',
  gallery: 'gallery',
  imageCloseup: 'image_closeup',
  imagesArchive: 'images_archive',
  secondaryImage: 'secondary_image',
  videoImage: 'video_image'
} as const;

/**
 * Export `mediasProductName`.
 */

export const mediasProductName = {
  featuredImages: 'featured_images',
  gallery: 'gallery',
  imagesArchive: 'images_archive',
  videoImage: 'video_image'
} as const;
