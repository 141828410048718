
/**
 * Module dependencies.
 */
import { color, transparentize, units } from 'src/styles/utils';
import { colors } from 'src/styles/colors';
import { useTranslate } from 'src/core/utils/translator';
import React, { ReactElement } from 'react';
import Svg from 'src/components/core/svg';
import Type from 'src/components/core/typography';
import imageIcon from 'src/assets/svg/image.svg';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  className?: string
};

/**
 * `Card` styled component.
 */

const Card = styled.div`
  background-color: ${color('white')};
  display: grid;
  grid-template-areas: 'image details';
  grid-template-columns: 42px 1fr;
  grid-template-rows: max-content;
`;

/**
 * `ImageWrapper` styled component.
 */

const ImageWrapper = styled.div`
  align-items: center;
  align-self: start;
  background-color: ${color('grey100')};
  display: flex;
  grid-area: image;
  height: 42px;
  justify-content: center;
  position: relative;
`;

/**
 * `TextWrapper` styled component.
 */

const TextWrapper = styled.div`
  align-items: center;
  display: flex;
  grid-area: details;
  padding: 6px 9px;
`;

/**
 * `Text` styled component.
 */

const Text = styled(Type.Small).attrs({ xSmall: true })`
  color: ${color('grey300')};
  font-size: 9px;
  line-height: 12px;
`;

/**
 * `EmptyProductCard` component.
 */

const EmptyProductCard = (props: Props): ReactElement => {
  const { translate } = useTranslate();

  return (
    <Card className={props?.className}>
      <ImageWrapper>
        <Svg
          color={transparentize(colors.black, 0.16)}
          icon={imageIcon}
          size={units(2.5)}
        />
      </ImageWrapper>

      <TextWrapper>
        <Text>
          {translate('common:comparator.selectOtherProduct')}
        </Text>
      </TextWrapper>
    </Card>
  );
};

/**
 * Export `EmptyProductCard` component.
 */

export default EmptyProductCard;
