
/**
 * Export `typologies`.
 */

export const typologies = {
  fullBodyPorcelainTiles: 'fullBodyPorcelainTiles',
  glazedPorcelainTiles: 'glazedPorcelainTiles',
  glazedWallTiles: 'glazedWallTiles'
};

/**
 * Export `typologiesValues`.
 */

export const typologiesValues = {
  [typologies.fullBodyPorcelainTiles]: 'full body porcelain tiles',
  [typologies.glazedPorcelainTiles]: 'glazed porcelain tiles',
  [typologies.glazedWallTiles]: 'glazed wall tiles'
} as const;

/**
 * Export `typologiesIconsLarge`.
 */

export const typologiesIconsLarge = {
  [typologies.fullBodyPorcelainTiles]: require('src/assets/svg/typologies/full-body-porcelain-tiles.svg'),
  [typologies.glazedPorcelainTiles]: require('src/assets/svg/typologies/glazed-porcelain-tiles.svg'),
  [typologies.glazedWallTiles]: require('src/assets/svg/typologies/glazed-wall-tiles.svg')
};

/**
 * Export `typologiesIconsSmall`.
 */

export const typologiesIconsSmall = {
  [typologies.fullBodyPorcelainTiles]: require('src/assets/svg/typologies/full-body-porcelain-tiles-small.svg'),
  [typologies.glazedPorcelainTiles]: require('src/assets/svg/typologies/glazed-porcelain-tiles-small.svg'),
  [typologies.glazedWallTiles]: require('src/assets/svg/typologies/glazed-wall-tiles-small.svg')
};
