
/**
 * Module dependencies.
 */

import {
  Menu as MenuProps,
  Size as MenuSize,
  kioskMenu
} from 'src/core/kiosk-menu';

import { color, units } from 'src/styles/utils';
import { ifNotProp, ifProp, prop, switchProp, theme } from 'styled-tools';
import { setFontStyle } from 'src/styles/utils/typography';
import { typography } from 'src/styles/type';
import { useTranslate } from 'src/core/utils/translator';
import React, { ReactElement } from 'react';
import RouterLink from 'src/components/core/links/router-link';
import styled, { css } from 'styled-components';

/**
 * Menu type sizes.
 */

const menuTypeSizes = {
  medium: {
    fontFamily: typography.fontFamily.serif,
    fontSize: 56,
    fontWeight: 300,
    letterSpacing: 0,
    lineHeight: 64
  },
  small: {
    fontFamily: typography.fontFamily.serif,
    fontSize: 32,
    fontWeight: 300,
    letterSpacing: 0,
    lineHeight: 40
  }
};

/**
 * Delay.
 */

const delay = 50;

/**
 * `Props` type.
 */

type Props = {
  startDelay: number,
  visible: boolean
};

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  display: grid;
  grid-gap: ${units(4)};
  grid-template-areas: 'list .';
  grid-template-columns: 60% 40%;
  max-width: ${theme('breakpoints.lg')}px;
  transition: ${theme('animations.defaultTransition')};
`;

/**
 * `List` styled components.
 */

const List = styled.ul`
  grid-area: list;
`;

/**
 * `ListItem` styled component.
 */

const ListItem = styled.li<{
  delay: number,
  hasMargin?: boolean,
  visible: boolean
}>`
  opacity: ${ifProp('visible', 1, 0)};
  position: relative;
  transform: translateX(${ifProp('visible', 0, '-50%')});
  transition: ${theme('animations.menuTransition')};

  ${ifProp('hasMargin', css`
    margin-bottom: ${units(14)};
  `)}

  ${ifProp('visible', css`
    transition-delay: ${prop('delay', 0)}ms;
  `)}
`;

/**
 * `ListItemLink` styled component.
 */

const ListItemLink = styled(RouterLink)<{ visible: boolean}>`
  align-items: center;
  color: ${color('white')};
  cursor: pointer;
  display: grid;
  grid-template-areas: 'title . line .';
  grid-template-columns: max-content ${units(4)} 1fr ${units(4)};
  padding: ${units(1.5)} 0;
  text-decoration: none;
  transition: color ${theme('animations.defaultTransition')};
  white-space: nowrap;

  ${ifNotProp('visible', css`
    cursor: default;
    pointer-events: none;
  `)}
`;

/**
 * `ListItemLinkTitle` styled component.
 */

const ListItemLinkTitle = styled.span<{ size: MenuSize }>`
  grid-area: title;

  ${switchProp('size', {
    medium: css`
      ${setFontStyle(menuTypeSizes.medium)}
    `,
    small: css`
      ${setFontStyle(menuTypeSizes.small)}
    `
  })}
`;

/**
 * `KioskMenu` component.
 */

const KioskMenu = (props: Props): ReactElement => {
  const { startDelay, visible } = props;
  const { locale, translate } = useTranslate();

  return (
    <Grid>
      <List>
        {kioskMenu.map(({ hasMargin, href, size, title }: MenuProps, index: number) => (
          <ListItem
            delay={(index + 1) * delay + startDelay}
            hasMargin={hasMargin}
            key={index}
            visible={visible}
          >
            <ListItemLink
              href={href}
              locale={locale}
              visible={visible}
            >
              <ListItemLinkTitle size={size ?? 'small'}>
                {translate(title)}
              </ListItemLinkTitle>
            </ListItemLink>
          </ListItem>
        ))}
      </List>
    </Grid>
  );
};

/**
 * Export `KioskMenu` component.
 */

export default KioskMenu;
