
/**
 * Module dependencies.
 */

import {
  acceptLanguageHeader,
  handleRequestError,
  request
} from 'src/core/utils/requests';
import { getApiEndpoint } from 'src/core/utils/api-url-resolver';

/**
 * `Query` type.
 */

type Query = {
  locale: string
};
/**
 * Export `fecthShowrooms`.
 */

export async function fecthShowrooms({ locale }: Query) {
  const endpoint = getApiEndpoint('getShowrooms');

  try {
    const { data } = await request.get(endpoint, {
      headers: acceptLanguageHeader(locale)
    });

    return data?.data;
  } catch (error) {
    handleRequestError(error);
  }
}
