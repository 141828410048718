
/**
 * Module dependencies.
 */

import { color, units } from 'src/styles/utils';
import { theme } from 'styled-tools';
import React, { ReactElement, useMemo } from 'react';
import Svg from 'src/components/core/svg';
import Type from 'src/components/core/typography/index';
import facebookIcon from 'src/assets/svg/facebook.svg';
import instagramIcon from 'src/assets/svg/instagram.svg';
import issuuIcon from 'src/assets/svg/issuu.svg';
import linkedinIcon from 'src/assets/svg/linkedin.svg';
import styled from 'styled-components';
import useConfigurations from 'src/api/app/configurations/use-configurations';
import youtubeIcon from 'src/assets/svg/youtube.svg';

/**
 * `SocialLink` type.
 */

type SocialLink = {
  href: string | null,
  icon: string,
  name: string
};

/**
 * `Props`types.
 */

type Props = {
  className?: string
}

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  display: grid;
`;

/**
 * `SocialLink` styled component.
 */

const SocialLink = styled.a`
  align-items: center;
  color: ${color('grey300')};
  display: flex;
  text-decoration: none;
  transition: color ${theme('animations.defaultTransition')};

  &:focus,
  &:hover {
    color: ${color('textColor')};
  }
`;

/**
 * `SocialName` styled component.
 */

const SocialName = styled(Type.Small).attrs({ as: 'span' })`
  grid-area: socialName;
  margin-left: ${units(2)};
`;

/**
 * `SocialNetworks` component.
 */

const SocialNetworks = ({ className }: Props): ReactElement => {
  const { data, isSuccess } = useConfigurations();
  const socialNetworks: SocialLink[] = useMemo(() => {
    return [{
      href: data?.facebook,
      icon: facebookIcon,
      name: 'Facebook'
    }, {
      href: data?.youtube,
      icon: youtubeIcon,
      name: 'Youtube'
    }, {
      href: data?.instagram,
      icon: instagramIcon,
      name: 'Instagram'
    }, {
      href: data?.linkedin,
      icon: linkedinIcon,
      name: 'Linkedin'
    }, {
      href: data?.issuu,
      icon: issuuIcon,
      name: 'Issuu'
    }];
  }, [data]);

  return (
    <Wrapper className={className}>
      {isSuccess && socialNetworks.map(({ href, icon, name }: SocialLink) => href && (
        <SocialLink
          href={href}
          key={name}
          rel={'noopener'}
          target={'_blank'}
        >
          <Svg
            icon={icon}
            size={units(4)}
          />

          <SocialName>
            {name}
          </SocialName>
        </SocialLink>
      ))}
    </Wrapper>
  );
};

/**
 * Export `SocialNetworks` component.
 */

export default SocialNetworks;
