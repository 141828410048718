
/**
 * Module dependencies.
 */

import { Context, ReactNode, createContext, useContext } from 'react';
import { Options } from 'src/components/snackbar';
import noop from 'lodash/noop';

/**
 * `Props` type.
 */

type Props = {
  removeMessage: (id: number) => void;
  showMessage: (content: ReactNode, options?: Options) => void;
}

/**
 * Export `SnackbarContext` context.
 */

export const SnackbarContext: Context<Props> = createContext({
  removeMessage: noop,
  showMessage: noop
});

/**
 * Export `useSnackbar` hook.
 */

export const useSnackbar = () => useContext(SnackbarContext);
