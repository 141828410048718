
/**
 * Module dependencies.
 */

import { Link } from 'src/components/core/contacts/links';
import { ShowroomModuleProps, ShowroomProps } from 'src/types/showrooms';
import { SubMenu, useFooterLinks } from 'src/core/menu';
import { assetUrlResolve } from 'src/core/utils/url-resolver';
import { color, media, units } from 'src/styles/utils';
import { isExternalRoute, useLocalizedRoute } from 'src/core/utils/routes';
import { normalizeNewLine } from 'src/core/utils/strings';
import { prop, switchProp, theme } from 'styled-tools';
import { useTranslate } from 'src/core/utils/translator';
import Applications from './applications';
import BlockHeader from './block-header';
import Button from 'src/components/core/buttons/button';
import Container from 'src/components/core/layout/container';
import Image from 'src/components/core/image';
import Newsletter from './newsletter';
import PartnersLogo from './logos/partners';
import RawHtml from 'src/components/core/raw-html';
import React, { Fragment, ReactElement, useMemo } from 'react';
import RouterLink from 'src/components/core/links/router-link';
import SocialNetworks from 'src/components/footer/social-networks';
import Type from 'src/components/core/typography/index';
import map from 'lodash/map';
import styled, { css } from 'styled-components';
import useShowroomModule from 'src/api/app/showrooms/use-showroom-module';
import useShowrooms from 'src/api/app/showrooms/use-showrooms';

/**
 * Current year.
 */

const currentYear = new Date().getFullYear();

/**
 * `Section` styled component.
 */

const Section = styled.footer`
  background-color: ${color('beige100')};
  padding-top: ${units(8)};

  ${media.min('lg')`
    padding-top: ${units(11)};
  `}
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  ${media.min('ms')`
    display: grid;
    grid-column-gap: ${units(2)};
    grid-template-areas:
      '. newsletter applications showrooms  contacts       .'
      '. partners   partners    supports   certifications .'
      '. footerFoot footerFoot   footerFoot footerFoot  .';
    grid-template-columns: 0.2fr repeat(4, 1fr) 0.2fr;
  `}
`;

/**
 * `ContactsWrapper` styled component.
 */

const ContactsWrapper = styled.div`
  grid-area: contacts;
`;

/**
 * `ContactUsWrapper` styled component.
 */

const ContactUsWrapper = styled.div`
  margin-bottom: ${units(8)};

  ${media.min('lg')`
    margin-bottom: ${units(6)};
  `}
`;

/**
 * `NewsletterWrapper` styled component.
 */

const NewsletterWrapper = styled.div`
  grid-area: newsletter;
`;

/**
 * `ApplicationsWrapper` styled component.
 */

const ApplicationsWrapper = styled.div`
  grid-area: applications;
  margin-bottom: ${units(8)};

  ${media.min('ms')`
    margin-bottom: ${units(2)};
  `}
`;

/**
 * `ShowroomsWrapper` styled component.
 */

const ShowroomsWrapper = styled.div`
  grid-area: showrooms;
  margin-bottom: ${units(8)};

  ${media.min('ms')`
    margin-bottom: ${units(2)};
  `}
`;

/**
 * `Showroom` styled component.
 */

const Showroom = styled.div`
  margin-bottom: ${units(2)};
`;

/**
 * `ShowroomInfo` styled component.
 */

const ShowroomInfo = styled(Type.Small).attrs({ as: 'p' })`
  color: ${prop('color', color('grey300'))};
  line-height: ${units(3)};

  a {
    color: ${prop('color', color('grey300'))};
  }
`;

/**
 * `ShowroomLinkWrapper` styled component.
 */

const ShowroomLinkWrapper = styled.div`
  margin-bottom: ${units(2)};
`;

/**
 * `ShowroomMapLink` styled component.
 */

const ShowroomMapLink = styled(Type.Small).attrs({ as: 'a', xSmall: true })`
  color: ${color('brown400')};
  text-transform: uppercase;
`;

/**
 * `FooterLogoWrapper` styled component.
 */

const FooterLogoWrapper = styled.div<{ gridArea: string }>`
  display: flex;
  flex-direction: column;
  grid-area: ${prop('gridArea')};

  ${switchProp('gridArea', {
  certifications: css`
      margin: 0 0 ${units(4)} 0;

      ${media.min('ms')`
        margin: auto 0 ${units(4)} auto;
      `};
    `,
  partners: css`
      margin-bottom: ${units(3)};
      margin-top: ${units(8)};

      ${media.min('ms')`
        margin-bottom: ${units(4)};
        margin-top: ${units(6)};
      `};
    `,
  supports: css`
      margin: ${units(3)} 0;

      ${media.min('ms')`
        margin: auto ${units(1)} ${units(4)} auto;
      `};

      ${media.min('lg')`
        margin: auto 0 ${units(4)} 0;
      `};
    `
})}
`;

/**
 * `Label` styled component.
 */

const Label = styled(Type.Small).attrs({ as: 'p' })`
  color: ${prop('color', color('grey300'))};
  line-height: ${units(3)};
  margin-bottom: ${units(1)};
`;

/**
 * `LogosWrapper` styled component.
 */

const LogosWrapper = styled.div`
  display: flex;
  position: relative;
`;

/**
 * `Logo` styled component.
 */

const Logo = styled.a.attrs(({ href }) => ({
  as: href && !isExternalRoute(href) && RouterLink || 'a',
  ...!isExternalRoute(href) ? {} : {
    rel: 'noopener',
    target: '_blank'
  }
}))`
  color: inherit;
  outline: none;
  text-decoration: none;

  &:not(:last-child) {
    margin-right: ${units(1.5)};
  }
`;

/**
 * `FooterFoot` styled component.
 */

const FooterFoot = styled.div`
  border-top: 1px solid ${color('grey200')};
  grid-area: footerFoot;
  padding: ${units(3)} 0 ${units(5)};

  ${media.min('ms')`
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: ${units(4)};
    padding: ${units(4.5)} 0;
  `};
`;

/**
 * `FooterFootLeft` styled component.
 */

const FooterFootLeft = styled.div`
  ${media.max('ms')`
    margin-bottom: ${units(3)};
  `}
`;

/**
 * `FooterFootRight` styled component.
 */

const FooterFootRight = styled.div`
  ${media.min('ms')`
    text-align: right;
  `}
`;

/**
 * `AuthorLink` styled component.
 */

const AuthorLink = styled.a`
  color: inherit;
  outline: none;
  text-decoration: none;
  transition: color ${theme('animations.defaultTransition')};

  &:focus,
  &:hover {
    color: ${color('textColor')};
  }
`;

/**
 * `FooterFootInnerWrapper` styled component.
 */

const FooterFootInnerWrapper = styled(Type.Small).attrs({ xSmall: true })`
  color: ${color('grey300')};
  line-height: ${units(4)};
`;

/**
 * `FooterFootLink` styled component.
 */

const FooterFootLink = styled.a.attrs(({ href }) => ({
  as: href && !isExternalRoute(href) && RouterLink || 'a',
  ...!isExternalRoute(href) ? {} : {
    rel: 'noopener',
    target: '_blank'
  }
}))`
  color: inherit;
  display: inline-block;
  outline: none;
  position: relative;
  text-decoration: none;
  transition: color ${theme('animations.defaultTransition')};

  ${media.max('md')`
    display: table;
  `};

  ${media.min('md')`
    padding-right: ${units(2)};

    &::after {
      background-color: ${color('grey300')};
      content: '';
      height: ${units(1.5)};
      position: absolute;
      right: ${units(1)};
      top: 50%;
      transform: translateY(-50%);
      width: ${units(1 / 8)};
    }

    &:first-of-type {
      padding-left: ${units(2)};

      &::before {
        background-color: ${color('grey300')};
        content: '';
        height: ${units(1.5)};
        left: ${units(1)};
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: ${units(1 / 8)};
      }
    }
  `};

  &:focus,
  &:hover {
    color: ${color('textColor')};
  }
`;

/**
 * `CallCostsText` styled component.
 */

const CallCostsText = styled.div`
  display: table;

  ${media.max('md')`
    margin-top: ${units(2)};
  `};

  ${media.min('md')`
    display: inline-block;
    position: relative;
  `};
`;

/**
 * `Footer` component.
 */

const Footer = (): ReactElement => {
  const { locale, translate } = useTranslate();
  const routeResolver = useLocalizedRoute();
  const footerLinks = useFooterLinks();
  const {
    data: showroomModuleData,
    isSuccess: showroomModuleIsSuccess
  } = useShowroomModule<ShowroomModuleProps>();

  const {
    data: showroomsData,
    isSuccess: showroomsIsSuccess
  } = useShowrooms<ShowroomProps[]>();

  const isSuccess = showroomModuleIsSuccess && showroomsIsSuccess;
  const items = useMemo(() => [{
    gridArea: 'partners'
  }, {
    gridArea: 'supports',
    height: 80,
    icons: [{
      href: routeResolver('fundedProjects'),
      src: assetUrlResolve('/static/images/supports2023.png'),
      width: 237
    }]
  }, {
    gridArea: 'certifications',
    height: 32,
    icons: [{
      src: assetUrlResolve('/static/images/certifications2023.png'),
      width: 166
    }]
  }], [routeResolver]);

  return (
    <Section>
      <Container>
        <Grid>
          <NewsletterWrapper>
            <BlockHeader
              lead={translate('footer:newsletter.lead')}
              title={translate('footer:newsletter.title')}
            />

            <Newsletter />
          </NewsletterWrapper>

          <ApplicationsWrapper>
            <BlockHeader
              title={translate('footer:applications.title')}
            />

            <Applications />
          </ApplicationsWrapper>

          {isSuccess && showroomsData && (
            <ShowroomsWrapper>
              <BlockHeader
                lead={showroomModuleData?.description}
                title={showroomModuleData?.title}
              />

              {showroomsData.map(({
                mapUrl,
                mobile,
                name,
                phone,
                text,
                virtualTourUrl
              }, index: number) => (
                <Showroom key={index}>
                  <Type.Small>
                    {name}
                  </Type.Small>

                  <RawHtml element={ShowroomInfo}>
                    {normalizeNewLine(text)}
                  </RawHtml>

                  {phone && (
                    <ShowroomInfo>
                      <Link i18nkey={translate('common:sections.showrooms.phone', { phone })} />
                    </ShowroomInfo>
                  )}

                  {mobile && (
                    <ShowroomInfo>
                      <Link i18nkey={translate('common:sections.showrooms.mobile', { mobile })} />
                    </ShowroomInfo>
                  )}

                  {mapUrl && (
                    <ShowroomLinkWrapper>
                      <ShowroomMapLink
                        href={mapUrl}
                        rel={'noopener'}
                        target={'_blank'}
                      >
                        {translate('footer:viewMap')}
                      </ShowroomMapLink>
                    </ShowroomLinkWrapper>
                  )}

                  {virtualTourUrl && (
                    <Button
                      {...!isExternalRoute(virtualTourUrl) ? { locale } : {
                        rel: 'noopener',
                        target: '_blank'
                      }}
                      href={virtualTourUrl}
                      size={'small'}
                      variant={'outlined'}
                    >
                      {translate('footer:virtualTour')}
                    </Button>
                  )}
                </Showroom>
              ))}
            </ShowroomsWrapper>
          )}

          <ContactsWrapper>
            <ContactUsWrapper>
              <BlockHeader
                lead={translate('footer:contacts.lead')}
                title={translate('footer:contacts.title')}
              />

              <Button
                href={routeResolver('contacts')}
                locale={locale}
                size={'small'}
                variant={'outlined'}
              >
                {translate('footer:contacts.button')}
              </Button>
            </ContactUsWrapper>

            <BlockHeader
              title={translate('footer:socialMedia.title')}
            />

            <SocialNetworks className={'social-networks'} />
          </ContactsWrapper>

          {items.map(({ gridArea, height, icons }) => (
            <FooterLogoWrapper
              gridArea={gridArea}
              key={gridArea}
            >
              <Label>
                {translate(`footer:${gridArea}`)}
              </Label>

              {gridArea === 'partners' ? (
                <PartnersLogo />
              ) : (
                <LogosWrapper>
                  {map(icons, ({ href, src, width }, index: number) => (
                    <Fragment key={index}>
                      {href ? (
                        <Logo
                          href={href}
                          {...!isExternalRoute(href) && { locale }}
                        >
                          <Image
                            alt={translate(`footer:${gridArea}`)}
                            height={height}
                            layout={'intrinsic'}
                            objectFit={'scale-down'}
                            src={src}
                            width={width}
                          />
                        </Logo>
                      ) : (
                        <Image
                          alt={translate(`footer:${gridArea}`)}
                          height={height}
                          layout={'intrinsic'}
                          objectFit={'scale-down'}
                          src={src}
                          width={width}
                        />
                      )}
                    </Fragment>
                  ))}
                </LogosWrapper>
              )}
            </FooterLogoWrapper>
          ))}

          <FooterFoot>
            <FooterFootLeft>
              <FooterFootInnerWrapper>
                {`Revigrés © ${currentYear}`}

                {footerLinks.map(({ href, title }: SubMenu, index: number) => href && (
                  <FooterFootLink
                    href={href}
                    key={index}
                  >
                    {translate(title)}
                  </FooterFootLink>
                ))}

                <RawHtml element={CallCostsText}>
                  {translate('footer:callCosts')}
                </RawHtml>
              </FooterFootInnerWrapper>
            </FooterFootLeft>

            <FooterFootRight>
              <FooterFootInnerWrapper>
                {'By '}

                <AuthorLink
                  href={'https://web.untile.pt/'}
                  rel={'noopener'}
                  target={'_blank'}
                >
                  {'Web.Untile'}
                </AuthorLink>
              </FooterFootInnerWrapper>
            </FooterFootRight>
          </FooterFoot>
        </Grid>
      </Container>
    </Section>
  );
};

/**
 * Export `Footer` component.
 */

export default Footer;
