
/**
 * Module dependencies.
 */

import { media, units } from 'src/styles/utils';
import React, { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode
};

/**
 * `Wrapper` styled components.
 */

const Wrapper = styled.div`
  padding-bottom: ${units(2)};

  ${media.min('md')`
    display: grid;
    grid-template-areas: 'body .';
    grid-template-columns: 75% 25%;
  `}
`;

/**
 * `Body` styled component.
 */

const Body = styled.div`
  overflow: hidden;
  position: relative;

  ${media.min('md')`
    grid-area: body;
  `}
`;

/**
 * `TabContent` component.
 */

const TabContent = ({ children }: Props): ReactElement => (
  <Wrapper>
    <Body>
      {children}
    </Body>
  </Wrapper>
);

/**
 * Export `TabContent` component.
 */

export default TabContent;
