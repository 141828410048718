/**
 * Module dependencies.
 */

import Script from 'next/script';

/**
 * `Props` type.
 */

type Props = {
  googleTagManagerId: string
};

/**
 * Export `GoogleTagManagerSetUp` component.
 */

export default function GoogleTagManagerSetup({ googleTagManagerId }: Props) {
  return (
    <Script
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        // eslint-disable-next-line id-match
        __html: `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer', '${googleTagManagerId}');
        `
      }}
      id={'google-tag-manager'}
      strategy={'afterInteractive'}
    />
  );
}
