
/**
 * Module dependencies.
 */

import { Name as MenuName } from 'src/core/menu';
import { color, media, units } from 'src/styles/utils';
import { ifProp, theme } from 'styled-tools';
import Container from 'src/components/core/layout/container';
import Fill from 'src/components/core/layout/fill';
import FooterMenu from 'src/components/navbar/menu/footer-menu';
import IconButton from 'src/components/core/buttons/icon-button';
import LanguageSwitcher from './language-switcher';
import MainMenu from 'src/components/navbar/menu/main-menu';
import MobileMenu from 'src/components/navbar/menu/mobile-menu';
import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
  useRef
} from 'react';

import arrowLeftIcon from 'src/assets/svg/arrow-left.svg';
import isEmpty from 'lodash/isEmpty';
import styled, { css } from 'styled-components';
import useBreakpoint from 'src/hooks/use-breakpoint';

/**
 * `Props` type.
 */

type Props = {
  isOpen: boolean,
  menuMobileActive: MenuName | null,
  setMenuMobileActive: Dispatch<SetStateAction<MenuName | null>>
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled(Fill)<{ visible: boolean }>`
  overflow: hidden;
  position: fixed;
  transition: visibility 0s 0.85s;
  z-index: ${theme('zIndex.sidebar')};

  ${ifProp('visible', css`
    transition-delay: 0s;
    visibility: visible;
  `, css`
    pointer-events: none;
    visibility: hidden;
  `)}
`;

/**
 * `Background` styled component.
 */

const Background = styled(Fill)<{ visible: boolean }>`
  &::before,
  &::after {
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: scaleX(0);
    transform-origin: center left;
    transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  }

  &::before {
    background-color: ${color('green300')};
    transition-delay: 0.25s;
    z-index: 1;
  }

  &::after {
    background-color: ${color('green400')};
    bottom: ${units(12)};
    transition-delay: 0s;
    z-index: 3;
  }

  ${ifProp('visible', css`
    &::before,
    &::after {
      transform: scaleX(1);
    }

    &::before {
      transition-delay: 0s;
    }

    &::after {
      transition-delay: 0.15s;
    }
  `)}

  ${media.min('md')`
    &::after {
      bottom: 0;
      right: 104px;
    }
  `}
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  display: grid;
  grid-template-areas:
    'content'
    'languages';
  grid-template-columns: 1fr;
  grid-template-rows: 1fr ${units(12)};
  height: 100%;
  position: relative;
  z-index: ${theme('zIndex.sidebar')};

  ${media.min('md')`
    grid-template-areas: 'content languages';
    grid-template-columns: 1fr ${units(13)};
    grid-template-rows: 1fr;
    overflow-x: hidden;
    overflow-y: auto;
    scroll-snap-points-y: repeat(${units(10)});
    scroll-snap-type: y mandatory;
  `}
`;

/**
 * `Languages` styled component.
 */

const Languages = styled.div`
  grid-area: languages;
  padding: 0 ${theme('grid.gutterMobile')}px;

  ${media.min('md')`
    bottom: ${units(7)};
    padding: 0 34px;
    position: fixed;
    right: 0;
  `}
`;

/**
 * `Content` styled component.
 */

const Content = styled.div`
  grid-area: content;
  height: 100%;
  padding-top: ${units(13)};

  ${media.max('md')`
    overflow-x: hidden;
    overflow-y: auto;
    scroll-snap-points-y: repeat(${units(10)});
    scroll-snap-type: y mandatory;
  `}
`;

/**
 * `StyledContainer` styled component.
 */

const StyledContainer = styled(Container).attrs({ fluid: true })`
  height: 100%;

  ${media.max('md')`
    padding: 0;
  `}
`;

/**
 * `ContentGrid` styled component.
 */

const ContentGrid = styled.div`
  display: grid;
  grid-template-areas:
    'mainMenuWrapper   mobileMenu'
    'footerMenuWrapper mobileMenu';
  grid-template-columns: repeat(2, 100%);
  grid-template-rows: 1fr max-content;
  height: 100%;

  ${media.max('md')`
    padding: 0 ${theme('grid.gutterMobile')}px;
    overflow-x: hidden;
  `}

  ${media.min('md')`
    grid-template-areas:
      '. mainMenuWrapper   .'
      '. footerMenuWrapper .';
    grid-template-columns: 1fr 10fr 1fr;
  `}
`;

/**
 * `MainMenuWrapper` styled component.
 */

const MainMenuWrapper = styled.div`
  grid-area: mainMenuWrapper;
`;

/**
 * `FooterMenuWrapper` styled component.
 */

const FooterMenuWrapper = styled.div`
  grid-area: footerMenuWrapper;
  padding: ${units(4)} 0;

  ${media.min('md')`
    padding: ${units(6)} 0;
  `}
`;

/**
 * `StyledMobileMenu` styled component.
 */

const StyledMobileMenu = styled(MobileMenu)`
  grid-area: mobileMenu;
`;

/**
 * `MobileBackButton` styed component.
 */

const MobileBackButton = styled(IconButton)<{ visible: boolean }>`
  color: ${color('white')};
  left: ${units(2.25)};
  position: absolute;
  top: 18px;
  transition: ${theme('animations.defaultTransition')};
  transition-property: color, opacity;
  z-index: ${theme('zIndex.hamburgerMenu')};

  &:focus,
  &:hover {
    color: ${color.transparentize('white', 0.7)};
    transition-delay: 0;
  }

  ${ifProp('visible', css`
    opacity: 1;
    pointer-events: all;
    transition-delay: 400ms;
  `, css`
    opacity: 0;
    pointer-events: none;
    transition-delay: 0;
  `)}

  ${media.min('ms')`
    left: ${units(3.75)};
    top: ${units(4.75)};
  `}
`;

/**
 * `Sidebar` component.
 */

const Sidebar = (props: Props): ReactElement => {
  const { isOpen, menuMobileActive, setMenuMobileActive } = props;
  const contentRef = useRef<HTMLDivElement>();
  const isMobile = useBreakpoint('md', 'max');
  const handleClickBackButton = useCallback(() => {
    setMenuMobileActive(null);

    if (contentRef.current && contentRef.current.scrollIntoView) {
      setTimeout(() => {
        contentRef.current.scrollIntoView({ behavior: 'smooth' });
      }, 300);
    }
  }, [setMenuMobileActive]);

  const handleClick = useCallback((name: MenuName) => {
    setMenuMobileActive(name);

    if (contentRef.current && contentRef.current.scrollIntoView) {
      setTimeout(() => {
        contentRef.current.scrollIntoView({ behavior: 'smooth' });
      }, 150);
    }
  }, [setMenuMobileActive]);

  const isMenuMobileActive = !isEmpty(menuMobileActive);
  const isVisible = isMenuMobileActive ? false : isOpen;

  return (
    <Wrapper visible={isOpen}>
      <Background visible={isOpen} />

      {isMobile && (
        <MobileBackButton
          icon={arrowLeftIcon}
          iconSize={units(4)}
          onClick={handleClickBackButton}
          visible={isMenuMobileActive}
        />
      )}

      <Grid>
        <Languages>
          <LanguageSwitcher
            startDelay={1000}
            visible={isOpen}
          />
        </Languages>

        <Content>
          <StyledContainer>
            <ContentGrid>
              <MainMenuWrapper ref={contentRef}>
                <MainMenu
                  isMobile={isMobile}
                  onClickMobile={handleClick}
                  startDelay={350}
                  visible={isVisible}
                />
              </MainMenuWrapper>

              <FooterMenuWrapper>
                <FooterMenu
                  startDelay={1200}
                  visible={isVisible}
                />
              </FooterMenuWrapper>

              {isMobile && (
                <StyledMobileMenu
                  activeMenu={menuMobileActive}
                  visible={isMenuMobileActive}
                />
              )}
            </ContentGrid>
          </StyledContainer>
        </Content>
      </Grid>
    </Wrapper>
  );
};

/**
 * Export `Sidebar` component.
 */

export default Sidebar;
