
/**
 * Module dependencies.
 */

import { color, media, units } from 'src/styles/utils';
import { ifNotProp, ifProp, prop, theme } from 'styled-tools';
import { kioskRoutes } from 'src/core/routes';
import { useRouter } from 'next/router';
import React, { ReactElement, useEffect, useState } from 'react';
import RouterLink from 'src/components/core/links/router-link';
import forEach from 'lodash/forEach';
import styled, { css } from 'styled-components';

/**
 * Delay.
 */

const delay = 100;

/**
 * `Props` type.
 */

type Props = {
  className?: string,
  isKiosk?: boolean,
  startDelay: number,
  visible: boolean
}

/**
 * `List` styled component.
 */

const List = styled.ul`
  align-items: center;
  display: flex;
  height: 100%;

  ${media.min('md')`
    flex-direction: column;
    height: auto;
    justify-content: center;
  `}
`;

/**
 * `ListItem` styled component.
 */

const ListItem = styled.li<{
  delay: number,
  visible: boolean
}>`
  display: inline-block;
  opacity: 0;
  transition: opacity ${theme('animations.defaultTransition')};

  ${ifProp('visible', css`
    opacity: 1;
    transition-delay: ${prop('delay', 0)}ms;
  `)}

  ${media.max('md')`
    &:not(:last-child) {
      margin-right: ${units(1)};
    }
  `}
`;

/**
 * `ListItemLink` styled component.
 */

const ListItemLink = styled(RouterLink)<{
  active: boolean,
  visible: boolean
}>`
  color: ${color.transparentize('white', 0.4)};
  cursor: pointer;
  font-family: ${theme('typography.fontFamily.serif')};
  font-size: 14px;
  font-weight: 400;
  line-height: ${units(5)};
  padding: 0 ${units(1)};
  text-transform: uppercase;
  transition: color ${theme('animations.defaultTransition')};

  &:focus,
  &:hover {
    color: ${color('white')};
  }

  ${ifNotProp('visible', css`
    cursor: default;
    pointer-events: none;
  `)}

  ${ifProp('active', css`
    color: ${color('white')};
    cursor: default;
    pointer-events: none;
  `)}
`;

/**
 * Get hreflang values.
 */

const getHrefValues = () => {
  const linkTags = document.getElementsByTagName('link');
  const map = new Map();

  forEach(linkTags, (link: any) => {
    const hreflang = link.getAttribute('hreflang');
    const href = link.getAttribute('href');

    map.set(hreflang, href);
  });

  return map;
};

/**
 * `LanguageSwitcher` component.
 */

const LanguageSwitcher = (props: Props): ReactElement => {
  const { locale, locales } = useRouter();
  const router = useRouter();
  const { className, isKiosk, startDelay, visible } = props;
  const [hrefValuesMap, setHrefValuesMap] = useState(new Map());

  const updateHrefValuesMap = () => {
    setHrefValuesMap(getHrefValues());
  };

  useEffect(() => {
    updateHrefValuesMap();
  }, []);

  useEffect(() => {
    const handleRouteChange = () => {
      updateHrefValuesMap();
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router]);

  return (
    <List className={className}>
      {locales.map((language, index: number) => {
        const href = isKiosk ? kioskRoutes.home : hrefValuesMap.get(language) || '/';

        return (
          <ListItem
            delay={(index + 1) * delay + startDelay}
            key={language}
            visible={visible}
          >
            <ListItemLink
              active={language === locale}
              href={href}
              locale={language}
              visible={visible}
            >
              {language}
            </ListItemLink>
          </ListItem>
        );
      })}
    </List>
  );
};

/**
 * Export `LanguageSwitcher` component.
 */

export default LanguageSwitcher;
