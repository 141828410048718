
/**
 * Module dependencies.
 */

import {
  Configure,
  Index,
  connectStateResults
} from 'react-instantsearch-dom';

import { Result } from 'src/types/search';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { color, units } from 'src/styles/utils';
import { hitsPerPage, indexes } from 'src/core/search-config';
import { theme } from 'styled-tools';
import { useTranslate } from 'src/core/utils/translator';
import Hits from 'src/components/search/search-results/hists/hits';
import InfiniteHits from 'src/components/search/search-results/hists/infinite-hits';
import PopularList from 'src/components/search/recent-and-popular/popular-list';
import React, { ReactElement, useMemo } from 'react';
import TabContent from './tab-content';
import Type from 'src/components/core/typography';
import styled from 'styled-components';

/**
 * Items.
 */

const items = ['collections', 'products', 'magazines', 'projects', 'others'];

/**
 * `AllResults` type.
 */

type AllResults = {
  [key: string]: Result
}

/**
 * `Props` type.
 */

type Props = {
  allSearchResults: AllResults,
  currentSearch: string
};

/**
 * `StyledTabs` styled component.
 */

const StyledTabs = styled(Tabs)`
  overflow: hidden;
`;

/**
 * `TabItem` styled components.
 */

const TabItem = styled.a`
  ${theme('typography.styles.p')}

  color: ${color('grey300')};
  cursor: pointer;
  padding: ${units(1)} 0;
  transition: ${theme('animations.defaultTransition')};

  &:focus,
  &:hover {
    color: ${color('grey900')};
  }
`;

/**
 * `StyledTabList` styled components.
 */

const StyledTabList = styled(TabList)`
  align-items: center;
  display: grid;
  grid-gap: ${units(2)};
  grid-template-columns: repeat(5, max-content);
  margin-bottom: ${units(3)};
  overflow-x: auto;
  overflow-y: hidden;
  padding: ${units(1.5)} 0;
  scroll-snap-type: x mandatory;

  .selected ${TabItem} {
    color: ${color('grey900')};
    text-decoration: underline;
  }
`;

/**
 * Get totals.
 */

function getTotals(results: AllResults) {
  const collections = results[indexes.productsCollections]?.nbHits ?? 0;
  const commercialContacts = results[indexes.commercialContacts]?.nbHits ?? 0;
  const contacts = results[indexes.contacts]?.nbHits ?? 0;
  const downloads = results[indexes.downloads]?.nbHits ?? 0;
  const faqs = results[indexes.faqs]?.nbHits ?? 0;
  const magazines = results[indexes.magazines]?.nbHits ?? 0;
  const products = results[indexes.products]?.nbHits ?? 0;
  const projects = results[indexes.projects]?.nbHits ?? 0;
  const others = commercialContacts + contacts + downloads + faqs;

  return {
    collections,
    magazines,
    others,
    products,
    projects,
    total: collections + magazines + others + products + projects
  };
}

/**
 * `SearchResults` component.
 */

const SearchResults = (props: Props): ReactElement => {
  const { allSearchResults, currentSearch } = props;
  const { translate } = useTranslate();
  const totals = useMemo(() => getTotals(allSearchResults), [allSearchResults]);

  return (
    <>
      {Object.values(indexes).map(index => (
        <Index
          indexName={index}
          key={index}
        />
      ))}

      {totals?.total === 0 ? (
        <>
          <Type.H6>
            {translate('common:search.results.noResults')}
          </Type.H6>

          <Type.H6
            fontWeight={400}
            marginBottom={units(5.5)}
            small
          >
            {`"${currentSearch}"`}
          </Type.H6>

          <Type.H6
            fontWeight={400}
            marginBottom={units(3.5)}
            small
          >
            {translate('common:search.labels.suggestions')}
          </Type.H6>

          <PopularList />
        </>
      ) : (
        <>
          <Type.H6>
            {translate('common:search.results.title')}
          </Type.H6>

          <Type.H6
            fontWeight={400}
            marginBottom={units(5.5)}
            small
          >
            {`"${currentSearch}"`}
          </Type.H6>

          <StyledTabs>
            <StyledTabList>
              {items.map((name, index: number) => totals[name] > 0 && (
                <Tab
                  key={index}
                  selectedClassName={'selected'}
                >
                  <TabItem>
                    {translate(`common:search.results.tabs.${name}`, {
                      total: totals[name]
                    })}
                  </TabItem>
                </Tab>
              ))}
            </StyledTabList>

            {totals.collections > 0 && (
              <TabPanel>
                <TabContent>
                  <Index indexName={indexes.productsCollections}>
                    <Configure hitsPerPage={hitsPerPage} />

                    <InfiniteHits index={indexes.productsCollections} />
                  </Index>
                </TabContent>
              </TabPanel>
            )}

            {totals.products > 0 && (
              <TabPanel>
                <TabContent>
                  <Index indexName={indexes.products}>
                    <Configure hitsPerPage={hitsPerPage} />

                    <InfiniteHits index={indexes.products} />
                  </Index>
                </TabContent>
              </TabPanel>
            )}

            {totals.magazines > 0 && (
              <TabPanel>
                <TabContent>
                  <Index indexName={indexes.magazines}>
                    <Configure hitsPerPage={hitsPerPage} />

                    <InfiniteHits index={indexes.magazines} />
                  </Index>
                </TabContent>
              </TabPanel>
            )}

            {totals.projects > 0 && (
              <TabPanel>
                <TabContent>
                  <Index indexName={indexes.projects}>
                    <Configure hitsPerPage={hitsPerPage} />

                    <InfiniteHits index={indexes.projects} />
                  </Index>
                </TabContent>
              </TabPanel>
            )}

            {totals.others > 0 && (
              <TabPanel>
                <TabContent>
                  <Index indexName={indexes.downloads}>
                    <Configure hitsPerPage={hitsPerPage} />

                    <Hits index={indexes.downloads} />
                  </Index>

                  <Index indexName={indexes.faqs}>
                    <Configure hitsPerPage={hitsPerPage} />

                    <Hits index={indexes.faqs} />
                  </Index>

                  <Index indexName={indexes.commercialContacts}>
                    <Configure hitsPerPage={hitsPerPage} />

                    <Hits index={indexes.commercialContacts} />
                  </Index>

                  <Index indexName={indexes.contacts}>
                    <Configure hitsPerPage={hitsPerPage} />

                    <Hits index={indexes.contacts} />
                  </Index>
                </TabContent>
              </TabPanel>
            )}
          </StyledTabs>
        </>
      )}
    </>
  );
};

/**
 * Export `SearchResults` component.
 */

export default connectStateResults(SearchResults);
