
/**
 * Module dependencies.
 */

import { colors } from 'src/styles/colors';
import { ifProp, prop, theme } from 'styled-tools';
import Svg from 'src/components/core/svg';
import loadingIcon from 'src/assets/svg/loading.svg';
import styled, { css, keyframes } from 'styled-components';

/**
 * Dashoffset.
 */

const dashoffset = 604;

/**
 * `Props` type.
 */

type Props = {
  active: boolean,
  color?: string,
  duration?: number,
  size: string | unknown,
  stroke?: number
};

/**
 * `animation` keyframes.
 */

const animation = keyframes`
  0% {
    stroke-dashoffset: ${dashoffset};
  }

  50% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: -${dashoffset};
  }
`;
/**
 * `LoadingLogo` styled component.
 */

const LoadingLogo = styled(Svg).attrs({ icon: loadingIcon })<Props>`
  color: ${({ color }) => color ?? colors.white};

  path {
    animation-delay: 0s;
    animation-duration: ${prop('duration', 3000)}ms;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    animation-timing-function: ${theme('animations.bezier.easeOutQuad')};
    opacity: 0;
    pointer-events: none;
    stroke-dasharray: ${dashoffset};
    stroke-dashoffset: ${dashoffset};
    stroke-width: ${prop('stroke', 6)}px;
    transition: opacity ${theme('animations.fastTransition')};
  }

  ${ifProp('active', css`
    path {
      animation-delay: 0.2s;
      animation-name: ${animation};
      opacity: 1;
    }
  `)}
`;

/**
 * Export `LoadingLogo` component.
 */

export default LoadingLogo;
