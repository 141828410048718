
/**
 * Module dependencies.
 */

import { Name as MenuName } from 'src/core/menu';
import { NavbarTheme } from 'src/types/navbar';
import { authRoutes, myRevigresRoutes } from 'src/core/routes';
import { color, media, states, units } from 'src/styles/utils';
import { ifProp, switchProp, theme } from 'styled-tools';
import { isExternalRoute, useLocalizedRoute } from 'src/core/utils/routes';
import { useAuth } from 'src/context/auth/context';
import { useNavbarThemeContext } from 'src/context/navbar-theme/context';
import { useRouter } from 'next/router';
import { useTranslate } from 'src/core/utils/translator';
import Container from 'src/components/core/layout/container';
import Fill from 'src/components/core/layout/fill';
import HamburgerMenu from './hamburger-menu';
import IconButton from 'src/components/core/buttons/icon-button';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';

import RouterLink from 'src/components/core/links/router-link';
import Search from 'src/components/search';
import Sidebar from './sidebar';
import Svg from 'src/components/core/svg';
import isEmpty from 'lodash/isEmpty';
import logoIcon from 'src/assets/svg/logo.svg';
import map from 'lodash/map';
import searchIcon from 'src/assets/svg/search.svg';
import styled, { css } from 'styled-components';
import useBodyScroll from 'src/hooks/use-body-scroll';
import useBreakpoint from 'src/hooks/use-breakpoint';
import useScroll from 'src/hooks/use-scroll';
import useTopBarMenu from 'src/api/app/top-bar-menu/use-top-bar-menu';
import userAuthIcon from 'src/assets/svg/user-auth.svg';
import userIcon from 'src/assets/svg/user.svg';

/**
 * Set color theme.
 */

const setColorTheme = css`
  ${switchProp('colorTheme', {
    dark: css`
      color: ${color('black')};
    `,
    light: css`
      color: ${color('white')};
    `
  })}

  ${ifProp('isScrolled', css`
    color: ${color('black')};
  `)}
`;

/**
 * `Nav` styled component.
 */

const Nav = styled.nav<{
  colorTheme?: NavbarTheme,
  hasShadow: boolean,
  isScrolled: boolean
}>`
  height: ${theme('dimensions.navbarHeightMobile')}px;
  left: 0;
  padding: ${units(2.5)} 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${theme('zIndex.navbar')};

  ${media.min('ms')`
    height: ${theme('dimensions.navbarHeight')}px;
    padding: ${units(4.5)} 0;
  `}

  ${setColorTheme}

  ${ifProp('hasShadow', css`
    background: linear-gradient(to bottom, ${color('black')}, ${color.transparentize('black', 0)});
  `)}
`;

/**
 * `Underlay` styled component.
 */

const Underlay = styled(Fill)<{ isVisible: boolean }>`
  background-color: ${color('white')};
  transform: translateY(-100%);
  transition: transform ${theme('animations.fastTransition')};
  z-index: -1;

  ${ifProp('isVisible', css`
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
    transform: translateY(0);
  `)}
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  align-items: center;
  display: grid;
  grid-column-gap: ${units(2)};
  grid-template-areas: 'logo . actions';
  grid-template-columns: auto 1fr auto;
`;

/**
 * `LogoLink` styled component.
 */

const LogoLink = styled(RouterLink)<{
  colorTheme?: NavbarTheme,
  isScrolled: boolean
}>`
  grid-area: logo;
  transition: opacity ${theme('animations.defaultTransition')};

  ${setColorTheme}

  ${media.max('sm')`
    position: relative;
    top: ${units(0.5)};
  `}

  &:focus,
  &:hover {
    opacity: 0.7;
    transition-delay: 0s;
  }
`;

/**
 * `Actions` styled component.
 */

const Actions = styled.div`
  align-items: center;
  display: flex;
  grid-area: actions;

  > * {
    &:not(:last-child) {
      margin-right: ${units(4.5)};
    }

    ${media.min('lg')`
      &:not(:last-child) {
        margin-right: ${units(6)};
      }
    `}
  }
`;

/**
 * `NavbarLink` styled component.
 */

const NavbarLink = styled.a.attrs(({ href }) => ({
  as: href && !isExternalRoute(href) && RouterLink || 'a'
}))<{
  colorTheme?: NavbarTheme,
  isScrolled: boolean
}>`
  line-height: ${units(4)};
  text-decoration: none;
  text-transform: uppercase;
  transition: opacity 0.3s ease-out;

  ${states.action`
    outline: none;
  `}

  ${setColorTheme}

  &:focus,
  &:hover {
    opacity: 0.6;
  }
`;

/**
 * `StyledIconButton` styled component.
 */

const StyledIconButton = styled(IconButton)<{
  colorTheme?: NavbarTheme,
  isScrolled: boolean
}>`
  line-height: 0;
  transition: opacity 0.3s ease-out;

  ${setColorTheme}

  &:focus,
  &:hover {
    opacity: 0.6;
  }
`;

/**
 * `Navbar` component.
 */

const Navbar = (): ReactElement => {
  const { locale } = useTranslate();
  const { theme: navbarTheme } = useNavbarThemeContext();
  const { colorTheme, hasShadow } = navbarTheme;
  const routeResolver = useLocalizedRoute();
  const { isAuthenticated } = useAuth();
  const {
    data: navbarLinks,
    isSuccess
  } = useTopBarMenu();

  const { yPos } = useScroll();
  const isMobile = useBreakpoint('sm', 'max');
  const isTablet = useBreakpoint('md', 'max');
  const isScrolled = useMemo(() => {
    if (isMobile) {
      return yPos > 15;
    }

    return yPos > 80;
  }, [isMobile, yPos]);

  const router = useRouter();
  const themeProps = { colorTheme, isScrolled };
  const [isOpen, setOpen] = useState<boolean>();
  const [menuMobileActive, setMenuMobileActive] = useState<MenuName | null>();
  const [isOpenSearch, setOpenSearch] = useState<boolean>();
  const toggle = useCallback(() => {
    setOpen(isOpen => !isOpen);

    if (isOpen && !isEmpty(menuMobileActive)) {
      setMenuMobileActive(null);
    }
  }, [isOpen, menuMobileActive]);

  useBodyScroll({ off: isOpen });

  useEffect(() => {
    const handleRouteChange = () => {
      if (isOpen) {
        setOpen(false);
        setMenuMobileActive(null);
      }
    };

    router.events.on('beforeHistoryChange', handleRouteChange);

    return () => {
      router.events.off('beforeHistoryChange', handleRouteChange);
    };
  }, [isOpen, router.events]);

  return (
    <Nav
      {...themeProps}
      hasShadow={hasShadow && !isScrolled}
    >
      <Underlay isVisible={isScrolled} />

      <Container fluid>
        <Grid>
          <LogoLink
            {...themeProps}
            aria-label={'Revigrés'}
            href={routeResolver('home')}
            locale={locale}
          >
            <Svg
              icon={logoIcon}
              size={isMobile ? units(10) : units(13)}
            />
          </LogoLink>

          <Actions>
            {!isTablet ? isSuccess && map(navbarLinks, ({ label, link }) => link && (
              <NavbarLink
                {...themeProps}
                href={link}
                key={label}
                {...link && !isExternalRoute(link) ? { locale } : {
                  rel: 'noopener',
                  target: '_blank'
                }}
              >
                {label}
              </NavbarLink>
            )) : null}

            <StyledIconButton
              {...themeProps}
              aria-label={'Search'}
              icon={searchIcon}
              iconSize={units(4)}
              onClick={() => setOpenSearch(true)}
            />

            <StyledIconButton
              {...themeProps}
              aria-label={isAuthenticated ? 'My Revigrés' : 'Sign In'}
              href={isAuthenticated ? myRevigresRoutes.home : authRoutes.signIn}
              icon={isAuthenticated ? userAuthIcon : userIcon}
              iconSize={units(4)}
              locale={locale}
            />

            <HamburgerMenu
              {...themeProps}
              isOpen={isOpen}
              onClick={toggle}
            />
          </Actions>
        </Grid>
      </Container>

      <Sidebar
        isOpen={isOpen}
        menuMobileActive={menuMobileActive}
        setMenuMobileActive={setMenuMobileActive}
      />

      <Search
        isOpen={isOpenSearch}
        onClose={() => setOpenSearch(false)}
      />
    </Nav>
  );
};

/**
 * Export `Navbar` component.
 */

export default Navbar;
