
/**
 * Module dependencies.
 */

import { units } from 'src/styles/utils';
import { useTranslate } from 'src/core/utils/translator';
import Button from 'src/components/core/buttons/button';
import React, { ReactElement, useCallback, useState } from 'react';
import SubscribeNewsletterSidebar from './subscribe-newsletter-sidebar';
import styled from 'styled-components';

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  margin-bottom: ${units(8)};
  position: relative;
`;

/**
 * `Newsletter` component.
 */

const Newsletter = (): ReactElement => {
  const { translate } = useTranslate();
  const [isOpenSidebar, setIsOpenSidebar] = useState<boolean>();

  const handleClose = useCallback(() => {
    setIsOpenSidebar(false);
  }, []);

  return (
    <>
      <Wrapper>
        <Button
          onClick={() => setIsOpenSidebar(true)}
          size={'small'}
          variant={'outlined'}
        >
          {translate('common:actions.subscribe')}
        </Button>
      </Wrapper>

      <SubscribeNewsletterSidebar
        isOpen={isOpenSidebar}
        onClose={handleClose}
      />
    </>
  );
};

/**
 * Export `Newsletter` component.
 */

export default Newsletter;
