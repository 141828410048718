
/**
 * Module dependencies.
 */

import { de } from './de';
import { en } from './en';
import { es } from './es';
import { fr } from './fr';
import { pt } from './pt';

/**
 * Export `apiPageSlugs`.
 */

export const apiPageSlugs = { de, en, es, fr, pt };
