
/**
 * Export `dimensions`.
 */

export const dimensions = {
  kioskNavbarHeight: 104,
  navbarHeight: 104,
  navbarHeightMobile: 76
};
