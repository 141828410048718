
/**
 * `kioskApiPages`.
 */

const kioskApiPages = {
  kioskHome: 'kiosk-homepage'
};

/**
 * Export `pt` api page routes.
 */

export const pt = {
  ...kioskApiPages,
  aboutUs: 'sobre-nos',
  aboutUsSgiPolicy: 'politica-do-sistema-de-gestao-integrado',
  careers: 'recrutamento',
  collection: null,
  collections: 'colecoes',
  contacts: 'contactos',
  cookiesPolicy: 'politica-de-cookies',
  downloads: 'downloads',
  environment: 'ambiente',
  faq: null,
  faqs: 'perguntas-frequentes',
  fundedProjects: 'projetos-financiados',
  highlights: 'destaques',
  history: 'historia',
  home: 'homepage',
  innovation: 'inovacao',
  inspiration: 'inspiracao',
  inspirationDetails: 'inspiracao-detalhe',
  media: 'media',
  portfolio: 'portfolio',
  portfolioDetails: 'portfolio-detalhe',
  privacyPolicy: 'politica-de-privacidade',
  productContact: 'produto-contacte-nos',
  productDetails: null,
  recommend: 'recomendador',
  recommendResults: null,
  reportingChannel: 'canal-de-denuncias',
  socialResponsability: 'responsabilidade-social',
  socialResponsabilityAspects: 'responsabilidade-social-aspetos',
  socialResponsabilityCollaboratingStandards: 'responsabilidade-social-normas-colaboradores',
  socialResponsabilitySuppliers: 'responsabilidade-social-fornecedores',
  storytiling: 'storytiling',
  storytilingDetails: 'storytiling-detalhe',
  termsConditions: 'termos-e-condicoes',
  toolsBimObjects: 'objetos-bim',
  toolsEnvironmentSimulator: 'simulador-de-ambientes',
  toolsProductComparator: 'comparador-de-produtos',
  toolsQuantitiesCalculator: 'calculadora-de-quantidades',
  toolsSystem3d: 'sistema-3d',
  whereToBuy: 'onde-comprar',
  whereToBuyResults: 'onde-comprar-resultados'
};
