
/**
 * Module dependencies.
 */

import { CollectionProps, MediaProps } from 'src/types/collection';
import { assetUrlResolve } from 'src/core/utils/url-resolver';
import { kioskRoutes } from 'src/core/routes';
import { mediasCollectionName } from 'src/core/collections-config/media';
import { routeTemplate, useLocalizedRoute } from 'src/core/utils/routes';
import { useTranslate } from 'src/core/utils/translator';
import Card from './card';
import React, { ReactElement, useMemo } from 'react';
import filter from 'lodash/filter';
import head from 'lodash/head';
import size from 'lodash/size';
import styled from 'styled-components';
import uniqBy from 'lodash/uniqBy';

/**
 * `Props` type.
 */

type Props = CollectionProps & {
  colors: string[],
  image_url?: string | null,
  isKioskCard?: boolean
};

/**
 * `Description` styled component.
 */

const Description = styled.span`
  text-transform: uppercase;
`;

/**
 * Get image.
 */

function getImage(media: MediaProps[]): string {
  const image = head(filter(media, {
    collectionName: mediasCollectionName.featuredImages
  }));

  return image?.fileUrl ?? assetUrlResolve('/static/images/empty/list@2x.jpg');
}

/**
 * `CollectionCard` component.
 */

const CollectionCard = (props: Props): ReactElement => {
  const {
    colors, // eslint-disable-line id-match
    finishes,
    formats,
    image_url, // eslint-disable-line id-match
    isKioskCard,
    media,
    name,
    slug
  } = props;

  const { translate } = useTranslate();
  const routeResolver = useLocalizedRoute();
  const finishesTotal = size(finishes);
  const route = routeResolver('collection', { slug });
  const kioskRoute = routeTemplate(kioskRoutes.collection, { slug });
  const formatsTotal = useMemo(() => {
    const list = uniqBy(formats, 'name');

    return size(list);
  }, [formats]);

  return (
    <Card
      description={(
        <Description>
          {`${size(colors)} ${translate('common:labels.color', { count: size(colors) })} . `}
          
          {`${formatsTotal} ${translate('common:labels.format', { count: formatsTotal })} . `}
          
          {`${finishesTotal} ${translate('common:labels.finish', { count: finishesTotal })}`}
        </Description>
      )}
      href={isKioskCard ? kioskRoute : route}
      imageUrl={image_url ?? getImage(media)}
      title={name}
    />
  );
};

/**
 * Export `CollectionCard` component.
 */

export default CollectionCard;
