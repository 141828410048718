
/**
 * Module dependencies.
 */

import { connectInfiniteHits } from 'react-instantsearch-dom';
import { indexes } from 'src/core/search-config';
import { kioskRoutes } from 'src/core/routes';
import { useLocalizedRoute } from 'src/core/utils/routes';
import { useTranslate } from 'src/core/utils/translator';
import OtherCard from 'src/components/search/search-results/cards/other-card';
import React, { ReactElement, useCallback } from 'react';
import head from 'lodash/head';
import useSearchTranslations from 'src/hooks/use-search-translations';

/**
 * `Hits` component.
 */

const Hits = ({ hits, index, isKiosk }): ReactElement => {
  const { translate } = useTranslate();
  const routeResolver = useLocalizedRoute();
  const { getTranslation, getTranslations } = useSearchTranslations();
  const getFaqDetails = useCallback(faq => {
    const { questions, slug } = getTranslations(faq);

    return {
      description: head(questions),
      href: routeResolver('faq', { slug })
    };
  }, [getTranslations, routeResolver]);

  const getDownloadDetails = useCallback(download => {
    const data = getTranslations(download);

    return {
      description: data?.name,
      href: data?.file_url
    };
  }, [getTranslations]);

  return (
    <ol>
      {hits.map(hit => (
        <li key={hit.objectID}>
          {index === indexes.downloads && (
            <OtherCard
              {...getDownloadDetails(hit)}
              title={translate('common:search.labels.downloads')}
            />
          )}

          {index === indexes.faqs && (
            <OtherCard
              {...getFaqDetails(hit)}
              title={translate('common:search.labels.faqs')}
            />
          )}

          {index === indexes.commercialContacts && (
            <OtherCard
              description={hit?.name}
              href={routeResolver('contacts')}
              title={translate('common:search.labels.commercialContacts')}
            />
          )}

          {index === indexes.contacts && (
            <OtherCard
              description={`${getTranslation(hit, 'name')} . ${hit?.description}`}
              href={isKiosk ? kioskRoutes.contacts : routeResolver('contacts')}
              title={translate('common:search.labels.contacts')}
            />
          )}
        </li>
      ))}
    </ol>
  );
};

/**
 * Export `Hits` component.
 */

export default connectInfiniteHits(Hits);
