
/**
 * Module dependencies.
 */

import { color, units } from 'src/styles/utils';
import { ifProp, prop } from 'styled-tools';
import Image from 'src/components/core/image';
import React, { ReactElement } from 'react';
import Svg from 'src/components/core/svg';
import styled, { css } from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  className?: string,
  title: string,
  type?: 'color' | 'icon' | 'image',
  value?: string | null
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.span<{ fullWidth: boolean }>`
  align-items: center;
  display: grid;

  ${ifProp('fullWidth', css`
    grid-template-columns: 100%;
  `, css`
    grid-gap: ${units(2)};
    grid-template-columns: ${units(8)} calc(100% - ${units(10)});
  `)}
`;

/**
 * `Shape` styled component.
 */

const Shape = styled.span`
  border: 1px solid ${color('grey600')};
  border-radius: ${units(4)};
  height: ${units(8)};
  overflow: hidden;
  position: relative;
  width: ${units(8)};
`;

/**
 * `Color` styled component.
 */

const Color = styled(Shape)<{ colorValue: string }>`
  background-color: ${prop('colorValue')};
`;

/**
 * `FilterItemLabel` component.
 */

const FilterItemLabel = (props: Props): ReactElement => {
  const { className, title, type, value } = props;

  return (
    <Wrapper
      className={className}
      fullWidth={!type}
    >
      {type === 'color' && <Color colorValue={value} />}

      {type === 'icon' && (
        <Svg
          icon={value}
          size={units(8)}
        />
      )}

      {type === 'image' && (
        <Shape>
          {value && (
            <Image
              alt={title}
              layout={'fill'}
              objectFit={'cover'}
              src={value}
            />
          )}
        </Shape>
      )}

      <span>
        {title}
      </span>
    </Wrapper>
  );
};

/**
 * Export `FilterItemLabel` component.
 */

export default FilterItemLabel;
