
/**
 * Module dependencies.
 */

import { color, media, units } from 'src/styles/utils';
import { ifProp, theme } from 'styled-tools';
import Fill from 'src/components/core/layout/fill';
import KioskFooter from 'src/components/kiosk/navbar/menu/kiosk-footer';
import KioskMenu from 'src/components/kiosk/navbar/menu/kiosk-menu';
import LanguageSwitcher from 'src/components/navbar/language-switcher';
import React, { ReactElement } from 'react';
import styled, { css } from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  isOpen: boolean,
  onClose: () => void
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled(Fill)<{ visible: boolean }>`
  overflow: hidden;
  position: fixed;
  transition: visibility 0s 0.85s;
  z-index: ${theme('zIndex.sidebar')};

  ${ifProp('visible', css`
    transition-delay: 0s;
    visibility: visible;
  `, css`
    pointer-events: none;
    visibility: hidden;
  `)}
`;

/**
 * `Background` styled component.
 */

const Background = styled(Fill)<{ visible: boolean }>`
  &::before,
  &::after {
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: scaleX(0);
    transform-origin: center left;
    transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  }

  &::before {
    background-color: ${color('green300')};
    transition-delay: 0.25s;
    z-index: 1;
  }

  &::after {
    background-color: ${color('green400')};
    bottom: ${units(12)};
    transition-delay: 0s;
    z-index: 3;
  }

  ${ifProp('visible', css`
    &::before,
    &::after {
      transform: scaleX(1);
    }

    &::before {
      transition-delay: 0s;
    }

    &::after {
      transition-delay: 0.15s;
    }
  `)}

  ${media.min('md')`
    &::after {
      bottom: 0;
      right: 104px;
    }
  `}
`;

/**
 * `Content` styled component.
 */

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: ${units(53)} ${units(13)} ${units(3)} ${units(10)};
  position: relative;
  scroll-snap-points-y: repeat(${units(10)});
  scroll-snap-type: y mandatory;
  z-index: ${theme('zIndex.sidebar')};
`;

/**
 * `StyledKioskMenu` styled component.
 */

const StyledKioskMenu = styled(KioskMenu)`
  flex: 1;
`;

/**
 * `StyledFooter` styled component.
 */

const StyledFooter = styled(KioskFooter)`
  bottom: ${units(4)};
  left: ${units(11)};
  position: fixed;
`;

/**
 * `StyledLanguageSwitcher` styled component.
 */

const StyledLanguageSwitcher = styled(LanguageSwitcher)`
  bottom: 158px;
  position: fixed;
  right: 0;
  width: ${units(13)};
`;

/**
 * `KioskSidebar` component.
 */

const KioskSidebar = (props: Props): ReactElement => {
  const { isOpen } = props;

  return (
    <Wrapper visible={isOpen}>
      <Background visible={isOpen} />

      <Content>
        <StyledKioskMenu
          startDelay={150}
          visible={isOpen}
        />

        <StyledFooter
          startDelay={1200}
          visible={isOpen}
        />

        <StyledLanguageSwitcher
          isKiosk
          startDelay={1000}
          visible={isOpen}
        />
      </Content>
    </Wrapper>
  );
};

/**
 * Export `KioskSidebar` component.
 */

export default KioskSidebar;
