
/**
 * Module dependencies.
 */

import { FormGroup, HelpText } from 'src/components/core/forms/fields-styled-components';
import { regexes } from 'src/core/utils/regexes';
import { units } from 'src/styles/utils';
import { useForm } from 'react-hook-form';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useNewsletter } from 'src/api/app/forms/newsletter/use-newsletter';
import { useTranslate } from 'src/core/utils/translator';
import Checkbox from 'src/components/core/checkbox';
import Input from 'src/components/core/forms/fields/input';
import PrivacyPolicyCheckboxLabel from 'src/components/core/forms/privacy-policy-checkbox-label';
import RawHtml from 'src/components/core/raw-html';
import React, { ReactElement, useCallback, useRef } from 'react';
import Select from 'src/components/core/forms/fields/select';
import Sidebar from 'src/components/my-revigres/core/sidebar';
import map from 'lodash/map';
import pick from 'lodash/pick';
import styled from 'styled-components';
import useAutoFocus from 'src/hooks/use-auto-focus';
import useCountries from 'src/api/app/countries/use-countries';
import useGTMEvent from 'src/hooks/use-gtm-event';

/**
 * `Props` type.
 */

type Props = {
  className?: string,
  isOpen: boolean,
  onClose: () => void
};

/**
 * `StyledCheckbox` styled component.
 */

const StyledCheckbox = styled(Checkbox)`
  margin-bottom: ${units(3)};
`;

/**
 * `SubscribeNewsletterSidebar` component.
 */

const SubscribeNewsletterSidebar = (props: Props): ReactElement => {
  const { isOpen, onClose } = props;
  const { translate } = useTranslate();
  const {
    control,
    errors,
    handleSubmit,
    register,
    reset
  } = useForm({ mode: 'onBlur' });

  const {
    data: countriesData,
    isLoading: isLoadingCountries
  } = useCountries();

  const inputRef = useRef();
  const sendGTMEvent = useGTMEvent();
  const { isSubmitting, onSubscribeNewsletter } = useNewsletter();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const onSubmit = useCallback(async data => {
    const recaptchaToken = await executeRecaptcha('newsletter').then(token => token);
    const formData = {
      ...pick(data, ['email', 'name', 'professional']),
      countryAlpha2: data?.countryAlpha2?.value,
      recaptchaToken
    };

    onSubscribeNewsletter(formData).then(() => {
      sendGTMEvent('newsletter');
      reset();
      onClose();
    });
  }, [executeRecaptcha, onClose, onSubscribeNewsletter, reset, sendGTMEvent]);

  const handleClose = useCallback(() => {
    onClose();
    reset();
  }, [onClose, reset]);

  useAutoFocus({
    isActive: isOpen,
    ref: inputRef
  });

  return (
    <Sidebar
      isOpen={isOpen}
      isSubmitting={isSubmitting}
      onClose={handleClose}
      onSubmit={handleSubmit(onSubmit)}
      submitButtonLabel={translate('common:actions.subscribe')}
      title={translate('footer:newsletter.subscribe.title')}
    >
      <Input
        disabled={isSubmitting}
        error={errors?.name}
        id={'newsletter-sidebar-name'}
        label={translate('forms:nameLabel')}
        name={'name'}
        placeholder={translate('forms:namePlaceholder')}
        ref={register<any>({
          required: {
            message: translate('forms:requiredField'),
            value: true
          }
        })}
      />

      <Input
        disabled={isSubmitting}
        error={errors?.email}
        id={'newsletter-sidebar-email'}
        label={translate('forms:emailLabel')}
        name={'email'}
        placeholder={translate('forms:emailPlaceholder')}
        ref={register<any>({
          pattern: {
            message: translate('forms:emailPatternValidation'),
            value: regexes.email
          },
          required: {
            message: translate('forms:requiredField'),
            value: true
          }
        })}
        type={'email'}
      />

      <Select
        control={control}
        disabled={isSubmitting}
        error={errors?.countryAlpha2}
        id={'newsletter-sidebar-country'}
        isLoading={isLoadingCountries}
        label={translate('forms:countryLabel')}
        name={'countryAlpha2'}
        options={map(countriesData, ({ alpha2, name }) => ({
          label: name,
          value: alpha2
        }))}
        placeholder={translate('forms:selectPlaceholder')}
        rules={{
          required: {
            message: translate('forms:requiredField'),
            value: true
          }
        }}
      />

      <StyledCheckbox
        alignment={'flex-start'}
        disabled={isSubmitting}
        id={'newsletter-sidebar-construction-sector'}
        label={
          <RawHtml>
            {translate('forms:constructionSectorCheckbox')}
          </RawHtml>
        }
        name={'professional'}
        ref={register}
      />

      <FormGroup>
        <Checkbox
          alignment={'flex-start'}
          disabled={isSubmitting}
          id={'newsletter-sidebar-privavy-policy'}
          label={
            <PrivacyPolicyCheckboxLabel
              i18nKey={'forms:privacyPolicyNewsletterCheckbox'}
            />
          }
          name={'acceptPrivacyPolicy'}
          ref={register<any>({
            required: {
              message: translate('forms:privacyPolicyError'),
              value: true
            }
          })}
        />

        {errors?.acceptPrivacyPolicy && (
          <HelpText hasError>
            {errors?.acceptPrivacyPolicy?.message}
          </HelpText>
        )}
      </FormGroup>
    </Sidebar>
  );
};

/**
 * Export `SubscribeNewsletterSidebar` component.
 */

export default SubscribeNewsletterSidebar;
