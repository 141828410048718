
/**
 * Module dependencies.
 */

import {
  QueryObserverBaseResult,
  QueryObserverOptions,
  useQuery
} from 'react-query';

import { fetchUser } from './fetch-user';

/**
 * `Props` type.
 */

type Props<P> = QueryObserverBaseResult<P, unknown>;

/**
 * `Options` type.
 */

type Options<P> = QueryObserverOptions<P, unknown, P, unknown>;

/**
 * `useUser` hook.
 */

function useUser<P>(token: string, options?: Options<P>): Props<P> {
  const result = useQuery(['me', { token }], () => {
    return fetchUser({ token });
  }, options);

  return result;
}

/**
 * Export `useUser` hook.
 */

export default useUser;
