
/**
 * Module dependencies.
 */

import {
  FormControl,
  FormGroup,
  HelpText,
  Label
} from 'src/components/core/forms/fields-styled-components';

import React, { ReactElement, forwardRef } from 'react';
import isEmpty from 'lodash/isEmpty';

/**
 * `Error` type.
 */

type Error = {
  message: string,
  type: string
};

/**
 * `Props` type.
 */

type Props = {
  as?: any,
  className?: string,
  disabled?: boolean,
  error?: Error | null | undefined,
  helpText?: string,
  id?: string,
  label: string,
  name: string,
  placeholder: string,
  type?: string
};

/**
 * `Input` component.
 */

const Input = (props: Props, ref: any): ReactElement => {
  const {
    className,
    disabled,
    error,
    helpText,
    id,
    label,
    name,
    type,
    ...rest
  } = props;

  const hasError = !isEmpty(error);

  return (
    <FormGroup
      className={className}
      disabled={disabled}
    >
      <Label htmlFor={id ?? name}>
        {label}
      </Label>

      <FormControl
        disabled={disabled}
        hasError={hasError}
        id={id ?? name}
        name={name}
        ref={ref}
        type={type ?? 'text'}
        {...rest}
      />

      {hasError && (
        <HelpText hasError>
          {error?.message}
        </HelpText>
      )}

      {helpText && !hasError && (
        <HelpText>
          {helpText}
        </HelpText>
      )}
    </FormGroup>
  );
};

/**
 * Export `Input` component.
 */

export default forwardRef<Props, any>(Input);
