
/**
 * Module dependencies.
 */

import { color, states, units } from 'src/styles/utils';
import { ifProp, switchProp, theme } from 'styled-tools';
import { isExternalRoute } from 'src/core/utils/routes';
import Loading from 'src/components/core/loading';
import React, { ElementType, ReactElement, ReactNode, forwardRef } from 'react';
import RouterLink from 'src/components/core/links/router-link';
import Svg from 'src/components/core/svg';
import get from 'lodash/get';
import styled, { css } from 'styled-components';

/**
 * Export `ColorTheme` type.
 */

export type ColorTheme = 'primary' | 'secondary' | 'white';

/**
 * Export `Size` type.
 */

export type Size = 'medium' | 'small';

/**
 * Export `Variant` type.
 */

export type Variant = 'fill' | 'outlined';

/**
 * `Props` type.
 */

type Props = {
  as?: ElementType,
  children: ReactNode,
  className?: string,
  colorTheme?: ColorTheme,
  disabled?: boolean,
  href?: string,
  icon?: string | undefined | null,
  isLoading?: boolean,
  locale?: string,
  onClick?: () => void,
  size?: Size,
  type?: string,
  variant?: Variant
};

/**
 * Color themes.
 */

const colorThemes = {
  primary: {
    fill: {
      bgColor: color('grey400'),
      borderColor: color('grey400'),
      color: color('white'),
      hoverBgColor: color('grey900'),
      hoverBorderColor: color('grey900'),
      hoverColor: color('white')
    },
    outline: {
      borderColor: color('grey400'),
      color: color('grey400'),
      hoverBgColor: color('grey900'),
      hoverBorderColor: color('grey900'),
      hoverColor: color('white')
    }
  },
  secondary: {
    fill: {
      bgColor: color('beige200'),
      borderColor: color('beige200'),
      color: color('grey400'),
      hoverBgColor: color('beige500'),
      hoverBorderColor: color('beige500'),
      hoverColor: color('grey400')
    },
    outline: {
      borderColor: color('brown400'),
      color: color('brown400'),
      hoverBgColor: color('brown400'),
      hoverBorderColor: color('brown400'),
      hoverColor: color('white')
    }
  },
  white: {
    fill: {
      bgColor: color('white'),
      borderColor: color('white'),
      color: color('grey400'),
      hoverBgColor: color('beige100'),
      hoverBorderColor: color('beige100'),
      hoverColor: color('grey400')
    },
    outline: {
      borderColor: color('white'),
      color: color('white'),
      hoverBgColor: color('grey900'),
      hoverBorderColor: color('grey900'),
      hoverColor: color('white')
    }
  }
};

/**
 * Button themes.
 */

const buttonThemes = Object
  .entries(colorThemes)
  .reduce((result, [themeName, themeSchema]) => ({
    ...result,
    [themeName]: switchProp('variant', {
      fill: css`
        background-color: ${get(themeSchema, 'fill.bgColor')};
        border-color: ${get(themeSchema, 'fill.borderColor')};
        color: ${get(themeSchema, 'fill.color')};

        &:focus,
        &:hover {
          background-color: ${get(themeSchema, 'fill.hoverBgColor')};
          border-color: ${get(themeSchema, 'fill.hoverBorderColor')};
          color: ${get(themeSchema, 'fill.hoverColor')};
        }
      `,
      outlined: css`
        background-color: transparent;
        border-color: ${get(themeSchema, 'outline.borderColor')};
        color: ${get(themeSchema, 'outline.color')};

        &:focus,
        &:hover {
          background-color: ${get(themeSchema, 'outline.hoverBgColor')};
          border-color: ${get(themeSchema, 'outline.hoverBorderColor')};
          color: ${get(themeSchema, 'outline.hoverColor')};
        }
      `
    })
  }), {});

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.button.attrs<Props & { hasIcon: boolean }>(({ as, href, type }) => {
  const isExternal = isExternalRoute(href);
  const element = as || href && !isExternal && RouterLink || href && isExternal && 'a' || 'button';

  return {
    as: element,
    type: type || (element === 'button' ? 'button' : null)
  };
})`
  -webkit-tap-highlight-color: transparent;
  appearance: none;
  border: 1px solid;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 1em;
  outline: none;
  position: relative;
  text-transform: uppercase;
  transition: ${theme('animations.defaultTransition')};
  transition-property: background-color, border-color, color;
  white-space: nowrap;

  ${states.action`
    text-decoration: none;
  `}

  ${switchProp('colorTheme', buttonThemes)}

  ${switchProp('size', {
    medium: css`
      border-radius: ${units(3.5)};
      height: ${units(7)};
      min-width: ${units(17)};

      ${ifProp('hasIcon', css`
        padding: ${units(2)} 30px;
      `, css`
        padding: ${units(2.5)} 45px;
      `)}
    `,
    small: css`
      border-radius: ${units(2.5)};
      height: ${units(5)};
      min-width: ${units(12)};

      ${ifProp('hasIcon', css`
        padding: ${units(1)} 30px;
      `, css`
        padding: ${units(1.5)} ${units(3)};
      `)}
    `
  })}

  ${ifProp('disabled', css`
    background-color: ${color('grey200')};
    border-color: ${color('grey200')};
    color: ${color('grey600')};
    cursor: default;
    pointer-events: none;
  `)}
`;

/**
 * `InnerWrapper` styled component.
 */

const InnerWrapper = styled.span<{ isLoading: boolean }>`
  align-items: center;
  display: flex;
  justify-content: center;
  opacity: ${ifProp('isLoading', 0, 1)};
  transition: opacity ${theme('animations.defaultTransition')};
`;

/**
 * `Icon` styled component.
 */

const Icon = styled(Svg)`
  margin-right: ${units(2)};
`;

/**
 * `Button` component.
 */

const Button = (props: Props, ref: any): ReactElement => {
  const {
    children,
    colorTheme = 'primary',
    icon,
    isLoading,
    size = 'medium',
    variant = 'fill',
    ...rest
  } = props;

  return (
    <Wrapper
      {...rest}
      colorTheme={colorTheme}
      hasIcon={icon}
      ref={ref}
      size={size}
      variant={variant}
    >
      <InnerWrapper isLoading={isLoading}>
        {icon && (
          <Icon
            icon={icon}
            size={units(2.75)}
          />
        )}

        {children}
      </InnerWrapper>

      <Loading active={isLoading} />
    </Wrapper>
  );
};

/**
 * Export `Button` component.
 */

export default forwardRef<Props, any>(Button);
