
/**
 * Module dependencies.
 */

import { useCallback } from 'react';
import { useRouter } from 'next/router';
import get from 'lodash/get';

/**
 * Locales.
 */

const locales = process.env.NEXT_PUBLIC_LOCALE_ISO_CODES
  .split(',')
  .reduce((acc, curr) => {
    const key = curr.split('_')[0];
    acc[key] = curr;

    return acc;
  }, {});

/**
 * `Data` type.
 */

type Data = {
  [key: string]: any
};

/**
 * `useSearchTranslations` hook.
 */

function useSearchTranslations() {
  const { locale } = useRouter();
  const getTranslations = useCallback((data: Data) => {
    return get(data, locales[locale]);
  }, [locale]);

  const getTranslation = useCallback((data: Data, name: string) => {
    const key = `${name}_${locales[locale]}`;

    return get(data, key);
  }, [locale]);

  return {
    getTranslation,
    getTranslations
  };
}

/**
 * Export `useSearchTranslations` hook.
 */

export default useSearchTranslations;
