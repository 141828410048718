
/**
 * Module dependencies.
 */

import { Controller, useForm } from 'react-hook-form';
import { Index } from 'src/types/search';
import { color, media, units } from 'src/styles/utils';
import {
  connectAutoComplete,
  connectHighlight,
  connectSearchBox
} from 'react-instantsearch-dom';

import { theme } from 'styled-tools';
import { useTranslate } from 'src/core/utils/translator';
import Autosuggest from 'react-autosuggest';
import IconButton from 'src/components/core/buttons/icon-button';
import React, {
  Dispatch,
  Fragment,
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useState
} from 'react';

import Type from 'src/components/core/typography';
import arrowRightIcon from 'src/assets/svg/arrow-right.svg';
import isEmpty from 'lodash/isEmpty';
import searchIcon from 'src/assets/svg/search.svg';
import styled from 'styled-components';

/**
 * `Hits` type.
 */

type Hits = {
  hits: Array<{
    [key: string]: any
  }>,
  index: Index
};

/**
 * `Props` type.
 */

type Props = {
  hits: Hits[],
  initialValue?: string,
  onAddRecents: (searchQuery: string) => void,
  refine: (value?: string) => void,
  setSearchValue: Dispatch<SetStateAction<string>>
};

/**
 * `Form` styled component.
 */

const Form = styled.form`
  margin-bottom: ${units(6)};
  position: relative;

  ${media.min('ms')`
    margin-bottom: ${units(8)};
  `}
`;

/**
 * `Input` styled component.
 */

const Input = styled.input`
  appearance: none;
  background: none;
  border: 0;
  border-bottom: 1px solid ${color('grey400')};
  border-radius: 0;
  box-shadow: none;
  color: ${color('grey900')};
  display: block;
  height: ${units(7)};
  outline: none;
  padding: 13px ${units(6)} 13px 0;
  position: relative;
  transition: ${theme('animations.defaultTransition')};
  transition-property: border-color, color;
  width: 100%;

  &::placeholder {
    color: ${color('grey400')};
    transition: color ${theme('animations.defaultTransition')};
  }
`;

/**
 * `HelpText` styled component.
 */

const HelpText = styled(Type.Small).attrs({ as: 'div' })`
  color: ${color('grey300')};
  padding-top: ${units(1.5)};
`;

/**
 * `SubmitButton` styled component.
 */

const SubmitButton = styled(IconButton).attrs({ iconSize: units(4) })`
  position: absolute;
  right: ${units(1)};
  top: 10px;
`;

/**
 * `Dropdown` styled component.
 */

const Dropdown = styled.div`
  background-color: ${color('grey100')};
  left: 0;
  max-height: 315px;
  overflow: auto;
  position: absolute;
  right: 0;
  top: ${units(7)};
  z-index: 10;
`;

/**
 * `DropdownItem` styled component.
 */

const DropdownItem = styled.div`
  align-items: center;
  background-color: ${color('white')};
  color: ${color('grey900')};
  cursor: pointer;
  display: flex;
  height: ${units(13)};
  margin-bottom: 1px;
  padding: ${units(2)};

  span {
    color: ${color('grey300')};
  }
`;

/**
 * `SuggestionsContainer` component.
 */

const SuggestionsContainer = ({ children, containerProps }): ReactElement => (
  <Dropdown {...containerProps}>
    {children}
  </Dropdown>
);

/**
 * `Highlight` component.
 */

const Highlight = connectHighlight(({ attribute, highlight, hit }): ReactElement | null => {
  const parsedHit = highlight({
    attribute,
    highlightProperty: '_highlightResult',
    hit
  });

  if (isEmpty(parsedHit)) {
    return null;
  }

  return (
    <DropdownItem>
      {parsedHit.map((part, index: number) => {
        return (
          <Fragment key={index}>
            {part.isHighlighted ? <span>{part.value}</span> : part.value}
          </Fragment>
        );
      })}
    </DropdownItem>
  );
});

/**
 * `Suggestions` component.
 */

const Suggestions = (suggestion): ReactElement => {
  return (
    <Highlight
      attribute={'name'}
      hit={suggestion}
    />
  );
};

/**
 * Export `VirtualSearchBox` component.
 */

export const VirtualSearchBox = connectSearchBox(() => null);

/**
 * `SearchForm` component.
 */

const SearchForm = (props: Props): ReactElement => {
  const { hits, initialValue, onAddRecents, refine, setSearchValue } = props;
  const { translate } = useTranslate();
  const [value, setValue] = useState<string>('');
  const handleFetchRequested = useCallback(({ value }) => {
    refine(value);
  }, [refine]);

  const handleClearRequested = useCallback(() => {
    refine();
  }, [refine]);

  const { control, handleSubmit } = useForm();
  const onSubmit = useCallback(({ autocomplete }) => {
    setSearchValue(autocomplete);

    if (!isEmpty(autocomplete) && autocomplete.length > 3) {
      onAddRecents(autocomplete);
    }
  }, [onAddRecents, setSearchValue]);

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue);
    }
  }, [initialValue]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name={'autocomplete'}
        render={({ onChange }) => (
          <Autosuggest
            getSectionSuggestions={section => section.hits}
            getSuggestionValue={hit => hit.name}
            inputProps={{
              autoFocus: true,
              onChange: (event, { newValue }) => {
                setValue(newValue);
                onChange(newValue);
              },
              placeholder: translate('common:search.form.placeholder'),
              value
            }}
            multiSection
            onSuggestionsClearRequested={handleClearRequested}
            onSuggestionsFetchRequested={handleFetchRequested}
            renderInputComponent={inputProps => (
              <>
                <Input {...inputProps} />

                {isEmpty(value) && (
                  <HelpText>
                    {translate('common:search.form.helpText')}
                  </HelpText>
                )}
              </>
            )}
            renderSectionTitle={() => null}
            renderSuggestion={Suggestions}
            renderSuggestionsContainer={SuggestionsContainer}
            suggestions={hits}
          />
        )}
      />

      <SubmitButton
        icon={isEmpty(value) ? searchIcon : arrowRightIcon}
        type={'submit'}
      />
    </Form>
  );
};

/**
 * Export `SearchForm` component.
 */

export default connectAutoComplete(SearchForm);
