
/**
 * Module dependencies.
 */

import { color, units } from 'src/styles/utils';
import { ifProp, prop, theme } from 'styled-tools';
import React, { ReactElement, ReactNode, SyntheticEvent, forwardRef } from 'react';
import Svg from 'src/components/core/svg';
import checkedIcon from 'src/assets/svg/forms/checkbox-checked.svg';
import styled, { css } from 'styled-components';
import uncheckedIcon from 'src/assets/svg/forms/checkbox-unchecked.svg';

/**
 * `CheckboxProps` type.
 */

export type CheckboxProps = {
  alignment?: 'flex-start' | 'center' | 'flex-end',
  'aria-describedby'?: string,
  'aria-label'?: string,
  checked?: boolean,
  className?: string,
  disabled?: boolean,
  id?: string,
  label?: ReactNode,
  name: string,
  onChange?: (event: SyntheticEvent) => void,
  reverse?: boolean,
  value?: any
};

/**
 * Checkbox size.
 */

const checkboxSize = units(2.5);

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div<Pick<CheckboxProps, 'disabled'>>`
  padding: 0 10px;
  position: relative;

  ${ifProp('disabled', css`
    cursor: default;
    pointer-events: none;
  `)}
`;

/**
 * `StyledSvg` styled component.
 */

const StyledSvg = styled(Svg).attrs({ size: checkboxSize })`
  border-radius: 2px;
  grid-area: checkbox;
  transition: ${theme('animations.defaultTransition')};
  transition-property: color, opacity;
`;

/**
 * `CheckedIcon` styled component.
 */

const CheckedIcon = styled(StyledSvg).attrs({ icon: checkedIcon })`
  color: ${color('grey400')};
  opacity: 0;
`;

/**
 * `UncheckedIcon` styled component.
 */

const UncheckedIcon = styled(StyledSvg).attrs({ icon: uncheckedIcon })`
  color: ${color('grey300')};
  opacity: 1;
`;

/**
 * `Label` styled component.
 */

const Label = styled.label<Pick<CheckboxProps, 'alignment' | 'reverse'> & { hasLabel: boolean }>`
  ${theme('typography.styles.p')}

  align-items: ${prop('alignment', 'center')};
  cursor: pointer;
  display: grid;
  grid-column-gap: ${ifProp('hasLabel', 0, 10)}px;
  grid-template-areas: 'checkbox label';
  grid-template-columns: ${checkboxSize} calc(100% - ${checkboxSize} - ${ifProp('hasLabel', 0, 10)}px) ${checkboxSize};

  ${ifProp('reverse', css`
    grid-template-areas: 'label checkbox';
    grid-template-columns: calc(100% - ${checkboxSize} - ${ifProp('hasLabel', 0, 10)}px) ${checkboxSize};
  `)}
`;

/**
 * `LabelText` styled component.
 */

const LabelText = styled.span`
  color: ${color('grey400')};
  grid-area: label;
  transition: color ${theme('animations.defaultTransition')};
`;

/**
 * `Input` styled component.
 */

const Input = styled.input`
  cursor: pointer;
  grid-area: checkbox;
  height: ${checkboxSize};
  opacity: 0;
  width: ${checkboxSize};
  z-index: 1;

  &:checked {
    & ~ ${UncheckedIcon} {
      opacity: 0;
    }

    & ~ ${CheckedIcon} {
      opacity: 1;
    }
  }

  &:focus,
  &:hover {
    & ~ ${LabelText} {
      color: ${color('grey900')};
    }

    & ~ ${UncheckedIcon} {
      color: ${color('grey900')};
    }

    &:checked ~ ${CheckedIcon} {
      color: ${color('grey900')};
    }
  }

  ${ifProp('disabled', css`
    & ~ ${LabelText},
    & ~ ${UncheckedIcon},
    &:checked ~ ${CheckedIcon} {
      color: ${color('grey600')};
    }
  `)}
`;

/**
 * `Checkbox` component.
 */

const Checkbox = (props: CheckboxProps, ref: any): ReactElement => {
  const {
    alignment,
    className,
    disabled,
    id,
    label,
    name,
    reverse,
    ...rest
  } = props;

  return (
    <Wrapper
      className={className}
      disabled={disabled}
    >
      <Label
        alignment={alignment}
        hasLabel={!label}
        reverse={reverse}
      >
        <Input
          disabled={disabled}
          id={id ?? name}
          name={name}
          ref={ref}
          type={'checkbox'}
          {...rest}
        />

        {label && (
          <LabelText>
            {label}
          </LabelText>
        )}

        <UncheckedIcon />

        <CheckedIcon />
      </Label>
    </Wrapper>
  );
};

/**
 * Export `Checkbox` component.
 */

export default forwardRef<CheckboxProps, any>(Checkbox);
