
/**
 * Module dependencies.
 */

import {
  authorizationHeader,
  handleRequestError,
  request
} from 'src/core/utils/requests';

import { getApiEndpoint } from 'src/core/utils/api-url-resolver';

/**
 * `Query` type.
 */

type Query = {
  favoriteId: string,
  projectId: string,
  token: string
};

/**
 * Export `removeProjectFavorite`.
 */

export async function removeProjectFavorite(query: Query) {
  const { favoriteId, projectId, token } = query;
  const endpoint = getApiEndpoint('getUserProjectFavorite', {
    favoriteId,
    projectId
  });

  try {
    const { data } = await request.delete(endpoint, {
      headers: authorizationHeader(token)
    });

    return data?.data;
  } catch (error) {
    throw handleRequestError(error);
  }
}
