
/**
 * Module dependencies.
 */

import { color, units } from 'src/styles/utils';
import { prop } from 'styled-tools';
import RawHtml from 'src/components/core/raw-html';
import React, { ReactElement } from 'react';
import Type from 'src/components/core/typography';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  lead?: string,
  title: string
};

/**
 * `Title` styled component.
 */

const Title = styled(Type.H6).attrs({ smallH6: true })`
  margin-bottom: ${units(3)};
`;

/**
 * `Lead` styled component.
 */

const Lead = styled(Type.Small).attrs({ as: 'p' })`
  color: ${prop('color', color('grey300'))};
  line-height: ${units(3)};
  margin-bottom: ${units(3)};
`;

/**
 * `BlockHeader` component.
 */

const BlockHeader = (props: Props): ReactElement => {
  const { lead, title } = props;

  return (
    <>
      <Title>
        {title}
      </Title>

      {lead && (
        <RawHtml element={Lead}>
          {lead}
        </RawHtml>
      )}
    </>
  );
};

/**
 * Export `BlockHeader` component.
 */

export default BlockHeader;
