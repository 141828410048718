
/**
 * Module dependencies.
 */

import { QueryObserverBaseResult, useQuery } from 'react-query';
import { fetchTopBarMenu } from './fetch-top-bar-menu';
import { useRouter } from 'next/router';

/**
 * Export `NavbarOption` type.
 */

export type NavbarOption = {
  label: string,
  link: string
};

/**
 * `Props` type.
 */

type Props = QueryObserverBaseResult<NavbarOption[], unknown>;

/**
 * Action type.
 */

const actionType = ({ locale }): string => {
  return `${locale}-top-bar-menu`;
};

/**
 * `useTopBarMenu` hook.
 */

function useTopBarMenu(): Props {
  const { locale } = useRouter();
  const result = useQuery(actionType({ locale }), () => fetchTopBarMenu({ locale }));

  return result;
}

/**
 * Export `useTopBarMenu` hook.
 */

export default useTopBarMenu;
