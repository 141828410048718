
/**
 * Module dependencies.
 */

import { color, units } from 'src/styles/utils';
import { ifProp, prop, theme } from 'styled-tools';
import React, { ReactElement } from 'react';
import styled, { css } from 'styled-components';

/**
 * Current year.
 */

const currentYear = new Date().getFullYear();

/**
 * `Props` type.
 */

type Props = {
  className?: string,
  startDelay: number,
  visible: boolean
};

/**
 * Type style.
 */

const typeStyle = css`
  font-family: ${theme('typography.fontFamily.serif')};
  font-size: 12px;
  font-weight: 400;
  line-height: ${units(3)};
`;

/**
 * `Copyright` styled component.
 */

const Copyright = styled.span<{
  delay: number,
  visible: boolean
}>`
  ${typeStyle}

  color: ${color('white')};
  grid-area: copyright;
  opacity: ${ifProp('visible', 1, 0)};
  transform: translateY(${ifProp('visible', 0, units(2))});
  transition: ${theme('animations.menuTransition')};

  ${ifProp('visible', css`
    transition-delay: ${prop('delay', 0)}ms;
  `)}
`;

/**
 * `KioskFooter` component.
 */

const KioskFooter = (props: Props): ReactElement => {
  const { className, startDelay, visible } = props;

  return (
    <Copyright
      className={className}
      delay={startDelay}
      visible={visible}
    >
      {`Revigrés © ${currentYear}`}
    </Copyright>
  );
};

/**
 * Export `KioskFooter` component.
 */

export default KioskFooter;
