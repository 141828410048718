
/**
 * Module dependencies.
 */

import {
  v1 as uuidV4,
  validate as uuidValidate
} from 'uuid';

/**
 * Export `normalizeNewLine`.
 */

export function normalizeNewLine(value: string | null): string | null {
  if (!value) {
    return null;
  }

  return value.replace(/(?:\r\n|\r|\n)/g, '<br>');
}

/**
 * Export `isValidUuid`.
 */

export function isValidUuid(uuid: string): boolean {
  return uuidValidate(uuid) && uuidV4(uuid) === 4;
}

/**
 * Export `truncateString`.
 */

export function truncateString(value: string | null, limit?: number): string | null {
  if (!value) {
    return null;
  }

  const limitValue = limit ?? 90;

  if (value.length >= limitValue) {
    const truncated = value.slice(0, limitValue);
    const lastWordIndex = truncated.lastIndexOf(' ');

    return truncated.slice(0, lastWordIndex).concat('...');
  }

  return value;
}

/*
 * Export `normalizeNewLine`.
 */

export function normalizeWebsiteUrl(value: string): string {
  return `www${value.substr(value.indexOf('.'))}`;
}

/**
 * Export `splitHtml`.
 */

export function splitHtml(htmlString: string) {
  return htmlString.match(/<(h2|h3|h4|h5|h6|ol|ul|p|a)[^>]*>.*?<\/\1>/gs);
}
