
/**
 * Export `i18n` configuration.
 */

module.exports = {
  defaultLocale: process.env.NEXT_PUBLIC_DEFAULT_LOCALE,
  loadLocaleFrom: (locale, namespace) => {
    return Promise.resolve(
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require(`./public/static/locales/${locale}/${namespace}.json`)
    );
  },
  locales: process.env.NEXT_PUBLIC_LOCALES.split(','),
  pages: {
    '*': ['collections', 'common', 'footer', 'forms', 'menu', 'my-revigres'],
    '/_error': ['errors'],
    '/account/confirm': ['authentication'],
    '/password/reset': ['authentication'],
    '/perguntas-frequentes': ['faqs'],
    '/recover-password': ['authentication'],
    '/sign-in': ['authentication'],
    '/sign-up': ['authentication'],
    'rgx:^/colecoes': ['products'],
    'rgx:^/inspiracao': ['inspiration'],
    'rgx:^/kiosk/collections': ['products'],
    'rgx:^/kiosk/inspiration': ['inspiration'],
    'rgx:^/kiosk/products': ['products'],
    'rgx:^/kiosk/recommend': ['products', 'recommend'],
    'rgx:^/my-revigres/favorites': ['products'],
    'rgx:^/my-revigres/sign-up/complete': ['authentication'],
    'rgx:^/onde-comprar': ['stores'],
    'rgx:^/portfolio': ['projects'],
    'rgx:^/produtos': ['products'],
    'rgx:^/recomendador': ['recommend'],
    'rgx:^/storytiling': ['magazine']
  }
};
