
/**
 * Module dependencies.
 */

import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { prop, switchProp } from 'styled-tools';
import React, { ReactElement, ReactNode } from 'react';
import styled, { css } from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  animationDistance: string,
  children: ReactNode,
  className?: string,
  lastAction: 'initial' | 'next' | 'previous',
  onEntered?: (node: ReactNode, isAppearing: boolean) => void,
  step: string
};

/**
 * Step transition duration;
 */

const stepTransitionDuration = 500;

/**
 * `StyledTransitionGroup` styled component.
 */

const StyledTransitionGroup = styled(TransitionGroup)`
  position: relative;

  > .enter {
    opacity: 0;
  }

  > .enter-active {
    opacity: 1;
    transition-duration: ${stepTransitionDuration}ms;
  }

  > .exit {
    opacity: 1;
  }

  > .exit-active {
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition-duration: ${stepTransitionDuration}ms;
  }

  ${switchProp('action', {
    next: css`
      > .enter {
        transform: translateX(${prop('distance')});
      }

      > .enter-active {
        transform: translateX(0);
      }

      > .exit {
        transform: translateX(0);
      }

      > .exit-active {
        transform: translateX(-${prop('distance')});
      }
    `,
    previous: css`
      > .enter {
        transform: translateX(-${prop('distance')});
      }

      > .enter-active {
        transform: translateX(0);
      }

      > .exit {
        transform: translateX(0);
      }

      > .exit-active {
        transform: translateX(${prop('distance')});
      }
    `
  })}
`;

/**
 * `StepWrapper` styled component.
 */

const StepWrapper = styled.div`
  opacity: 1;
  transition-property: opacity, transform;
`;

/**
 * `Stepper` component.
 */

function Stepper(props: Props): ReactElement {
  const {
    animationDistance,
    children,
    className,
    lastAction,
    onEntered,
    step
  } = props;

  return (
    <StyledTransitionGroup
      action={lastAction}
      className={className}
      distance={animationDistance}
    >
      <CSSTransition
        key={step}
        onEntered={onEntered}
        timeout={stepTransitionDuration}
      >
        <StepWrapper>
          {children}
        </StepWrapper>
      </CSSTransition>
    </StyledTransitionGroup>
  );
}

/**
 * Export `Stepper` component.
 */

export default Stepper;
