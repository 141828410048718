
/**
 * Module dependencies.
 */

import { getApiEndpoint } from 'src/core/utils/api-url-resolver';
import {
  handleRequestError,
  request
} from 'src/core/utils/requests';

/**
 * `Query` type.
 */

type Query = {
  email: string,
  recaptchaToken: string
};

/**
 * Export `unsubscribeNewsletter`.
 */

export async function unsubscribeNewsletter(formData: Query) {
  const endpoint = getApiEndpoint('newsletter');

  try {
    return await request.delete(endpoint, { data: formData });
  } catch (error) {
    throw handleRequestError(error);
  }
}
