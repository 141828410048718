
/**
 * Module dependencies.
 */

import { ActiveResults } from 'src/types/search';
import { Index, InstantSearch } from 'react-instantsearch-dom';
import {
  client,
  indexes,
  recenteSearchesKey
} from 'src/core/search-config';

import { color, units } from 'src/styles/utils';
import { ifProp, theme } from 'styled-tools';
import { useRouter } from 'next/router';
import Fill from 'src/components/core/layout/fill';
import IconButton from 'src/components/core/buttons/icon-button';
import ModalPortal from 'src/components/core/modals/modal-portal';
import Popular from './search-results/popular';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import SearchForm, { VirtualSearchBox } from 'src/components/search/search-form';
import SearchResults from './search-results';
import closeIcon from 'src/assets/svg/close.svg';
import concat from 'lodash/concat';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import styled, { css } from 'styled-components';
import uniqWith from 'lodash/uniqWith';

/**
 * `Props` type.
 */

type Props = {
  isOpen: boolean,
  onClose: () => void
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled(Fill)<{ visible: boolean }>`
  background-color: ${color('grey100')};
  outline: none;
  overflow: auto;
  position: fixed !important;
  transition: visibility ${theme('animations.defaultTransition')};
  z-index: ${theme('zIndex.modal')};

  ${ifProp('visible', css`
    animation: ${theme('keyframes.fadeIn')} ${theme('animations.defaultTransition')} both;
    visibility: visible;
  `, css`
    animation: ${theme('keyframes.fadeOut')} ${theme('animations.defaultTransition')} both;
    pointer-events: none;
    visibility: hidden;
  `)}
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  display: grid;
  grid-template-areas:
    '. .             . closeButton'
    '. searchWrapper . .';
  grid-template-columns: 3fr 6fr 2fr 1fr;
  position: fixed;
  top: ${units(70)};
  width: 100vw;
`;

/**
 * `CloseButton` styled component.
 */

const CloseButton = styled(IconButton).attrs({
  icon: closeIcon,
  iconSize: units(4.5)
})`
  grid-area: closeButton;
  justify-self: start;
  line-height: 0;
`;

/**
 * `SearchWrapper` styled component.
 */

const SearchWrapper = styled.div`
  grid-area: searchWrapper;
`;

/**
 * Normalize recent searches.
 */

function normalizeRecentSearches(oldValues: string | null, newValue: string) {
  if (isEmpty(oldValues)) {
    return newValue;
  }

  const values = concat(newValue, oldValues.split(','));
  const removeDuplicates = uniqWith(values, isEqual);

  return removeDuplicates.slice(0, 5).join();
}

/**
 * `KioskSearch` component.
 */

const KioskSearch = (props: Props): ReactElement => {
  const { isOpen, onClose } = props;
  const router = useRouter();
  const [activeResults, setActiveResults] = useState<ActiveResults>('popular');
  const [searchValue, setSearchValue] = useState<string>('');
  const handleAddRecents = useCallback((searchQuery: string) => {
    const oldValues = window.localStorage.getItem(recenteSearchesKey);
    const newValue = normalizeRecentSearches(oldValues, searchQuery);

    window.localStorage.setItem(recenteSearchesKey, newValue);
  }, []);

  const handleClose = useCallback(() => {
    onClose();
    setSearchValue('');
    setActiveResults('popular');
  }, [onClose]);

  useEffect(() => {
    if (!isEmpty(searchValue)) {
      setActiveResults('results');

      return;
    }

    setActiveResults('popular');
  }, [searchValue]);

  useEffect(() => {
    const handleRouteChange = () => {
      if (isOpen) {
        handleClose();
      }
    };

    router.events.on('beforeHistoryChange', handleRouteChange);

    return () => {
      router.events.off('beforeHistoryChange', handleRouteChange);
    };
  }, [handleClose, isOpen, router.events]);

  return (
    <ModalPortal isOpen={isOpen}>
      <Wrapper
        aria-hidden={!isOpen}
        aria-modal
        role={'dialog'}
        tabIndex={isOpen ? -1 : undefined}
        visible={isOpen}
      >
        <Grid>
          <CloseButton onClick={handleClose} />

          <SearchWrapper>
            <InstantSearch
              indexName={indexes.productsCollections}
              searchClient={client}
            >
              <SearchForm
                initialValue={searchValue}
                onAddRecents={handleAddRecents}
                setSearchValue={setSearchValue}
              />

              {Object.values(indexes).map((indexName, index: number) => (
                <Index
                  indexName={indexName}
                  key={index}
                />
              ))}
            </InstantSearch>

            <InstantSearch
              indexName={indexes.productsCollections}
              searchClient={client}
            >
              <VirtualSearchBox defaultRefinement={searchValue} />

              {activeResults === 'results' && (
                <SearchResults currentSearch={searchValue} />
              )}
            </InstantSearch>

            {activeResults !== 'results' && (
              <Popular />
            )}
          </SearchWrapper>
        </Grid>
      </Wrapper>
    </ModalPortal>
  );
};

/**
 * Export `KioskSearch` component.
 */

export default KioskSearch;
