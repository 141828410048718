
/**
 * Module dependencies.
 */

import { color } from 'src/styles/utils';
import { theme } from 'styled-tools';
import { useTranslate } from 'src/core/utils/translator';
import PopularList from './popular-list';
import React, { ReactElement } from 'react';
import styled from 'styled-components';

/**
 * `Wrapper` styled components.
 */

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

/**
 * `Header` styled component.
 */

const Header = styled.div`
  color: ${color('grey400')};
  font-family: ${theme('typography.fontFamily.sansSerif')};
  font-size: 10.5px;
  font-weight: 400;
  letter-spacing: 0.75px;
  line-height: 18px;
  margin-bottom: 50px;
  text-transform: uppercase;
`;

/**
 * `Popular` component.
 */

const Popular = (): ReactElement => {
  const { translate } = useTranslate();

  return (
    <Wrapper>
      <Header>
        {translate('common:search.labels.popular')}
      </Header>

      <PopularList />
    </Wrapper>
  );
};

/**
 * Export `Popular` component.
 */

export default Popular;
