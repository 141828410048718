
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    

/**
 * Module dependencies.
 */

import 'intersection-observer';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Hydrate } from 'react-query/hydration';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ThemeProvider } from 'styled-components';
import { isProduction } from 'src/core/utils/environment';
import { theme } from 'src/styles/theme';
import { useRouter } from 'next/router';
import App, { AppContext } from 'next/app';
import AuthProvider from 'src/context/auth/provider';
import ComparatorProvider from 'src/context/comparator/provider';
import GlobalStyle from 'src/components/core/global-style';
import GoogleTagManager from 'src/components/analytics/google-tag-manager';
import GoogleTagManagerSetup from 'src/components/analytics/gtm-setup';
import Head from 'next/head';
import Layout from 'src/components/layout';
import NavbarThemeProvider from 'src/context/navbar-theme/provider';
import React from 'react';
import SnackbarProvider from 'src/context/snackbar/provider';
import packageJson from 'package.json';

/**
 * Google tag manager id.
 */

const googleTagManagerId = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;

/**
 * ReCaptchaKey key.
 */

const reCaptchaKey: string = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;

/**
 * Query cache.
 */

const queryClient = new QueryClient();

/**
 * `PageApp` page.
 */

const PageApp = ({ Component, pageProps }) => {
  const { locale } = useRouter();

  return (
    <>
      <Head>
        <meta
          content={'text/html;charset=utf-8'}
          httpEquiv={'Content-Type'}
        />

        <meta
          content={'IE=edge'}
          httpEquiv={'X-UA-Compatible'}
        />

        <meta
          content={'width=device-width, initial-scale=1'}
          name={'viewport'}
        />

        <meta
          content={packageJson.version}
          name={'version'}
        />

        <meta
          content={'true'}
          name={'HandheldFriendly'}
        />

        <link
          href={'/favicon.ico'}
          rel={'shortcut icon'}
          type={'image/x-icon'}
        />

        <link
          href={'/static/favicons/favicon-57x57.png'}
          rel={'apple-touch-icon'}
          sizes={'57x57'}
        />

        <link
          href={'/static/favicons/favicon-60x60.png'}
          rel={'apple-touch-icon'}
          sizes={'60x60'}
        />

        <link
          href={'/static/favicons/favicon-72x72.png'}
          rel={'apple-touch-icon'}
          sizes={'72x72'}
        />

        <link
          href={'/static/favicons/favicon-76x76.png'}
          rel={'apple-touch-icon'}
          sizes={'76x76'}
        />

        <link
          href={'/static/favicons/favicon-114x114.png'}
          rel={'apple-touch-icon'}
          sizes={'114x114'}
        />

        <link
          href={'/static/favicons/favicon-120x120.png'}
          rel={'apple-touch-icon'}
          sizes={'120x120'}
        />

        <link
          href={'/static/favicons/favicon-144x144.png'}
          rel={'apple-touch-icon'}
          sizes={'144x144'}
        />

        <link
          href={'/static/favicons/favicon-152x152.png'}
          rel={'apple-touch-icon'}
          sizes={'152x152'}
        />

        <link
          href={'/static/favicons/favicon-180x180.png'}
          rel={'apple-touch-icon'}
          sizes={'180x180'}
        />

        <link
          href={'/static/favicons/favicon.svg'}
          rel={'icon'}
          type={'image/svg+xml'}
        />

        <link
          href={'/static/favicons/favicon-16x16.png'}
          rel={'icon'}
          sizes={'16x16'}
          type={'image/png'}
        />

        <link
          href={'/static/favicons/favicon-32x32.png'}
          rel={'icon'}
          sizes={'32x32'}
          type={'image/png'}
        />

        <link
          href={'/static/favicons/favicon-96x96.png'}
          rel={'icon'}
          sizes={'96x96'}
          type={'image/png'}
        />

        <link
          href={'/static/favicons/favicon-192x192.png'}
          rel={'icon'}
          sizes={'192x192'}
          type={'image/png'}
        />

        <meta
          content={'#ffffff'}
          name={'msapplication-TileColor'}
        />

        <meta
          content={'/static/favicons/favicon-144x144.png'}
          name={'msapplication-TileImage'}
        />

        <meta
          content={'/browserconfig.xml'}
          name={'msapplication-config'}
        />
      </Head>

      {googleTagManagerId && (
        <GoogleTagManagerSetup googleTagManagerId={googleTagManagerId} />
      )}

      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <ThemeProvider theme={theme}>
            <NavbarThemeProvider>
              <AuthProvider>
                <GoogleTagManager>
                  <SnackbarProvider>
                    <ComparatorProvider isKiosk={pageProps?.isKiosk}>
                      <GoogleReCaptchaProvider
                        language={locale}
                        reCaptchaKey={reCaptchaKey}
                        scriptProps={{ defer: true }}
                      >
                        <GlobalStyle />

                        <Layout isKiosk={pageProps?.isKiosk}>
                          <Component {...pageProps} />
                        </Layout>
                      </GoogleReCaptchaProvider>
                    </ComparatorProvider>
                  </SnackbarProvider>
                </GoogleTagManager>
              </AuthProvider>
            </NavbarThemeProvider>
          </ThemeProvider>

          <ReactQueryDevtools initialIsOpen={!isProduction()} />
        </Hydrate>
      </QueryClientProvider>
    </>
  );
};

/**
 * Get initial props.
 */

PageApp.getInitialProps = async (appContext: AppContext) => {
  return {
    ...await App.getInitialProps(appContext)
  };
};

/**
 * Export `PageApp` page.
 */

const __Page_Next_Translate__ = PageApp;


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: false,
// @ts-ignore
      
// @ts-ignore
    });
// @ts-ignore
  