
/**
 * Module dependencies.
 */

import { ComparatorContext } from './context';
import { ComparatorProduct } from 'src/types/products';
import Comparator from 'src/components/comparator';
import FavoritesProvider from 'src/context/favorites/provider';
import KioskComparator from 'src/components/kiosk/comparator';
import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useState
} from 'react';

import compact from 'lodash/compact';
import concat from 'lodash/concat';
import drop from 'lodash/drop';
import escape from 'lodash/escape';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import unescape from 'lodash/unescape';
import useBreakpoint from 'src/hooks/use-breakpoint';

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode,
  isKiosk?: boolean
};

/**
 * Update local storage.
 */

function updateLocalStorage(value: ComparatorProduct[] | null) {
  window.localStorage.setItem('comparator', escape(JSON.stringify(value)));
}

/**
 * `ComparatorProvider` provider.
 */

const ComparatorProvider = (props: Props): ReactElement => {
  const { children, isKiosk } = props;
  const [products, setProducts] = useState<ComparatorProduct[] | null>();
  const [isOpen, setIsOpen] = useState<boolean>();
  const isMobile = useBreakpoint('ms', 'max');
  const maxCards = isMobile ? 2 : 3;
  const handleAdd = useCallback((product: ComparatorProduct) => {
    let newValue = compact(products);

    newValue = concat(newValue, product);
    const total = size(newValue);

    if (total > maxCards) {
      newValue = drop(newValue);
    }

    updateLocalStorage(newValue);
    setProducts(newValue);
    setIsOpen(true);
  }, [maxCards, products]);

  const handleRemove = useCallback((id: string) => {
    const newValue = filter(products, product => product?.id !== id);

    updateLocalStorage(newValue);
    setProducts(newValue);
    setIsOpen(true);
  }, [products]);

  useEffect(() => {
    const oldValues = window.localStorage.getItem('comparator');

    if (!isEmpty(oldValues) && !products) {
      setProducts(JSON.parse(unescape(oldValues)));
    }
  }, [products]);

  useEffect(() => {
    if (isMobile && size(products) > 2) {
      setProducts(drop(products));
    }
  }, [isMobile, products]);

  return (
    <ComparatorContext.Provider
      value={{
        isOpen,
        onAdd: handleAdd,
        onClose: () => setIsOpen(false),
        onOpen: () => setIsOpen(true),
        onRemove: handleRemove,
        products
      }}
    >
      {children}

      <FavoritesProvider>
        {isKiosk ? <KioskComparator /> : <Comparator />}
      </FavoritesProvider>
    </ComparatorContext.Provider>
  );
};

/**
 * Export `ComparatorProvider` provider.
 */

export default ComparatorProvider;
