
/**
 * Module dependencies.
 */

import { color } from 'src/styles/utils/colors';
import { createGlobalStyle } from 'styled-components';
import { typography } from 'src/styles/type';
import styledNormalize from 'styled-normalize';

/**
 * Export `GlobalStyle` component.
 */

export default createGlobalStyle`
  ${styledNormalize}

  * {
    box-sizing: border-box;
  }

  body {
    color: ${color('textColor')};
    font-family: ${typography.fontFamily.sansSerif};

    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizelegibility;
  }

  ol,
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  b,
  strong {
    font-weight: 700;
  }

  svg {
    display: block;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    appearance: none !important;
    background: none !important;
    background-color: transparent !important;
    transition: color 9999s ease-out, background-color 9999s ease-out;
    transition-delay: 9999s;
  }

  .grecaptcha-badge {
    visibility: hidden;
  }
`;
