
/**
 * Module dependencies.
 */

import { QueryObserverBaseResult, useQuery } from 'react-query';
import { fetchUserProjects } from './fetch-user-projects';
import { useMemo } from 'react';
import { useRouter } from 'next/router';

/**
 * `Props` type.
 */

type Props<P> = QueryObserverBaseResult<P, unknown>;

/**
 * `Options` type.
 */

type Options<P> = {
  initialData?: P | null,
  token: string
};

/**
 * Action type.
 */

const actionType = ({ locale }): string => {
  return `${locale}-user-projects`;
};

/**
 * Export `useUserProjects` hook.
 */

function useUserProjects<P>(options?: Options<P>): Props<P> {
  const { locale } = useRouter();
  const variables = useMemo(() => ({
    locale,
    token: options?.token
  }), [locale, options.token]);

  const result = useQuery([actionType(variables), variables], () => {
    return fetchUserProjects(variables);
  }, {
    initialData: options?.initialData
  });

  return result;
}

/**
 * Export `useUserProjects` hook.
 */

export default useUserProjects;
