
/**
 * Module dependencies.
 */

import {
  authorizationHeader,
  handleRequestError,
  request
} from 'src/core/utils/requests';

import { getApiEndpoint } from 'src/core/utils/api-url-resolver';

/**
 * `Query` type.
 */

type Query = {
  clientId: string,
  projectId: string,
  token: string
};

/**
 * Export `addProjectClient`.
 */

export async function addProjectClient({ clientId, projectId, token }: Query) {
  const endpoint = getApiEndpoint('getUserProjectClients', { id: projectId });

  try {
    const { data } = await request.post(endpoint, { clientId }, {
      headers: authorizationHeader(token)
    });

    return data?.data;
  } catch (error) {
    throw handleRequestError(error);
  }
}
