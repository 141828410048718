
/**
 * Module dependencies.
 */

import { getApiEndpoint } from 'src/core/utils/api-url-resolver';
import { handleRequestError, request } from 'src/core/utils/requests';

/**
 * Export `fetchConfigurations`.
 */

export async function fetchConfigurations() {
  const endpoint = getApiEndpoint('getConfigurations');

  try {
    const { data } = await request.get(endpoint);

    return data?.data;
  } catch (error) {
    handleRequestError(error);
  }
}
