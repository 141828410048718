
/**
 * Module dependencies.
 */

import { useRouter } from 'next/router';
import React, { ReactElement, ReactNode, useEffect } from 'react';

/**
 * Google tag manager dd.
 */

const googleTagManagerId = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode
};

/**
 * `GoogleTagManager` component.
 */

const GoogleTagManager = ({ children }: Props): ReactElement => {
  const router = useRouter();

  useEffect(() => {
    if (!googleTagManagerId) {
      return;
    }

    const handlePageView = url => {
      // @ts-ignore
      window.dataLayer.push({
        event: 'pageview',
        page: url
      });
    };

    router.events.on('routeChangeComplete', handlePageView);

    return () => {
      router.events.off('routeChangeComplete', handlePageView);
    };
  }, [router]);

  return (
    <>
      {children}
    </>
  );
};

/**
 * Export `GoogleTagManager` component.
 */

export default GoogleTagManager;
