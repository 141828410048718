
/**
 * Module dependencies.
 */

import { useCallback } from 'react';

/**
 * Events.
 */

const events = {
  contact: 'contact_request',
  contactsSuccess: 'contacts_form_success',
  downloadComparator: 'download-comparation-sheet',
  newsletter: 'newsletter-inscription-success'
};

/**
 * Google tag manager id.
 */

const googleTagManagerId = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;

/**
 * `EventName` type.
 */

type EventName = keyof typeof events;

/**
 * `Result` type.
 */

type Result = (eventName: EventName, url?: string) => void;

/**
 * `useGTMEvent` hook.
 */

function useGTMEvent(): Result {
  const handleEvent = useCallback((eventName: EventName, url?: string) => {
    if (!googleTagManagerId) {
      return;
    }

    // @ts-ignore
    window.dataLayer.push({
      event: events[eventName],
      ...(url && {
        page: url
      })
    });
  }, []);

  return handleEvent;
}

/**
 * Export `useGTMEvent` hook.
 */

export default useGTMEvent;
