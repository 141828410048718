
/**
 * Module dependencies.
 */

import { ComparatorProduct } from 'src/types/products';
import { Context, createContext, useContext } from 'react';

/**
 * `Props` type.
 */

type Props = {
  isOpen?: boolean;
  onAdd?: (product: ComparatorProduct) => void;
  onClose?: () => void;
  onOpen?: () => void;
  onRemove?: (id: string) => void;
  products?: ComparatorProduct[];
}

/**
 * Export `ComparatorContext` context.
 */

export const ComparatorContext: Context<Props> = createContext({});

/**
 * Export `useComparator` hook.
 */

export const useComparator = () => useContext(ComparatorContext);
