
/**
 * Module dependencies.
 */

import { ifNotProp, theme } from 'styled-tools';
import { media } from 'src/styles/utils';
import styled, { css } from 'styled-components';

/**
 * `Container` component.
 */

const Container = styled.div<{
  fluid?: boolean,
  hasPadding?: boolean
}>`
  margin-left: auto;
  margin-right: auto;
  padding: 0 ${theme('grid.gutterMobile')}px;
  position: relative;
  width: 100%;

  ${ifNotProp('fluid', css`
    max-width: ${theme('breakpoints.xxl')}px;
  `)}

  ${media.min('ms')`
    padding: 0 ${theme('grid.gutter')}px;
  `}

  ${ifNotProp('hasPadding', css`
    padding: 0 !important;
  `)}

  &::after,
  &::before {
    box-sizing: border-box;
    content: ' ';
    display: table;
  }

  &::after {
    clear: both;
  }
`;

/**
 * Default props.
 */

Container.defaultProps = {
  hasPadding: true
};

/**
 * Export `Container` component.
 */

export default Container;
