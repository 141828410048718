
/**
 * Export `i18nRoutes`.
 */

module.exports = {
  de: {
    aboutUs: '/uber-revigres',
    aboutUsSgiPolicy: '/uber-revigres/politik-des-integrierten-managementsystems',
    application: '/verlegungen/:slug',
    applications: '/verlegungen',
    careers: '/bewerbung',
    collection: '/kollektionen/:slug',
    collections: '/kollektionen',
    contacts: '/kontakt',
    cookiesPolicy: '/legal/cookie-richtlinie',
    downloads: '/downloads',
    environment: '/umwelt',
    faq: '/haufig-gestellte-frage/:slug',
    faqs: '/haufig-gestellte-fragen',
    fundedProjects: null,
    highlights: '/highlights',
    history: '/geschichte',
    home: '/',
    innovation: '/innovation',
    inspiration: '/inspiration',
    inspirationDetails: '/inspiration/:id',
    landingPage: '/p/:slug',
    media: '/medien',
    portfolio: '/portfolio',
    portfolioDetails: '/portfolio/:slug',
    privacyPolicy: '/legal/datenschutzpolitik',
    productContact: '/produkte/:slug/kontakt',
    productDetails: '/produkte/:slug',
    recommend: '/empfehlungstool',
    recommendResults: '/empfehlungstool/ergebnisse',
    reportingChannel: '/berichtskanal',
    socialResponsability: '/soziale-verantwortung',
    socialResponsabilityAspects: null,
    socialResponsabilityCollaboratingStandards: null,
    socialResponsabilitySuppliers: null,
    storytiling: '/storytiling',
    storytilingDetails: '/storytiling/:slug',
    termsConditions: '/legal/allgemeine-geschaftsbedingungen',
    toolsBimObjects: '/tools/bim-objekte',
    toolsEnvironmentSimulator: '/tools/360vr-umgebungssimulator',
    toolsProductComparator: '/tools/produktvergleichs-tool',
    toolsQuantitiesCalculator: '/tools/mengenrechner',
    toolsSystem3d: '/tools/revigres-3d-design',
    whereToBuy: '/verkaufsstellen',
    whereToBuyResults: '/verkaufsstellen/:filterType/:pageLabel/:filterValue'
  },
  en: {
    aboutUs: '/about-us',
    aboutUsSgiPolicy: '/about-us/policy-of-the-integrated-management-system',
    application: '/applications/:slug',
    applications: '/applications',
    careers: '/careers',
    collection: '/collections/:slug',
    collections: '/collections',
    contacts: '/contacts-us',
    cookiesPolicy: '/legal/cookies-policy',
    downloads: '/downloads',
    environment: '/environment',
    faq: '/frequently-asked-questions/:slug',
    faqs: '/frequently-asked-questions',
    fundedProjects: '/funded-projects',
    highlights: '/highlights',
    history: '/history',
    home: '/',
    innovation: '/innovation',
    inspiration: '/get-inspired',
    inspirationDetails: '/get-inspired/:id',
    landingPage: '/p/:slug',
    media: '/media',
    portfolio: '/portfolio',
    portfolioDetails: '/portfolio/:slug',
    privacyPolicy: '/legal/privacy-policy',
    productContact: '/products/:slug/contact',
    productDetails: '/products/:slug',
    recommend: '/recommend',
    recommendResults: '/recommend/results',
    reportingChannel: '/reporting-channel',
    socialResponsability: '/social-accountability',
    socialResponsabilityAspects: '/social-accountability/aspects',
    socialResponsabilityCollaboratingStandards: '/social-accountability/collaborating-standards',
    socialResponsabilitySuppliers: '/social-accountability/suppliers',
    storytiling: '/storytiling',
    storytilingDetails: '/storytiling/:slug',
    termsConditions: '/legal/terms-and-conditions',
    toolsBimObjects: '/tools/bim-objects',
    toolsEnvironmentSimulator: '/tools/environment-simulator-360vr',
    toolsProductComparator: '/tools/product-comparator',
    toolsQuantitiesCalculator: '/tools/quantities-calculator',
    toolsSystem3d: '/tools/revigres-3d-design',
    whereToBuy: '/where-to-buy',
    whereToBuyResults: '/where-to-buy/:filterType/:pageLabel/:filterValue'
  },
  es: {
    aboutUs: '/sobre-nosotros',
    aboutUsSgiPolicy: '/sobre-nosotros/politica-del-sistema-de-gestion-integrado',
    application: '/aplicaciones/:slug',
    applications: '/aplicaciones',
    careers: '/contratacion',
    collection: '/colecciones/:slug',
    collections: '/colecciones',
    contacts: '/contacto',
    cookiesPolicy: '/legal/politica-de-cookies',
    downloads: '/descargas',
    environment: '/medioambiente',
    faq: '/faq/:slug',
    faqs: '/faq',
    fundedProjects: null,
    highlights: '/lo-mas-destacado',
    history: '/historia',
    home: '/',
    innovation: '/innovacion',
    inspiration: '/inspiracion',
    inspirationDetails: '/inspiracion/:id',
    landingPage: '/p/:slug',
    media: '/medios',
    portfolio: '/cartera',
    portfolioDetails: '/cartera/:slug',
    privacyPolicy: '/legal/politica-de-privacidad',
    productContact: '/productos/:slug/contacto',
    productDetails: '/productos/:slug',
    recommend: '/recomendador',
    recommendResults: '/recomendador/resultados',
    reportingChannel: '/canal-de-denuncia',
    socialResponsability: '/responsabilidad-social',
    socialResponsabilityAspects: null,
    socialResponsabilityCollaboratingStandards: null,
    socialResponsabilitySuppliers: null,
    storytiling: '/storytiling',
    storytilingDetails: '/storytiling/:slug',
    termsConditions: '/herramientas/condiciones',
    toolsBimObjects: '/herramientas/objetos-bim',
    toolsEnvironmentSimulator: '/herramientas/simulador-ambientes-360-vr',
    toolsProductComparator: '/herramientas/comparador-productos',
    toolsQuantitiesCalculator: '/herramientas/calculadora-cantidades',
    toolsSystem3d: '/herramientas/revigres-3d-design',
    whereToBuy: '/donde-comprar',
    whereToBuyResults: '/donde-comprar/:filterType/:pageLabel/:filterValue'
  },
  fr: {
    aboutUs: '/qui-sommes-nous',
    aboutUsSgiPolicy: '/qui-sommes-nous/politique-du-systeme-de-gestion-integre',
    application: '/applications/:slug',
    applications: '/applications',
    careers: '/recrutement',
    collection: '/collections/:slug',
    collections: '/collections',
    contacts: '/contacts',
    cookiesPolicy: '/legal/politique-cookies',
    downloads: '/telechargements',
    environment: '/environnement',
    faq: '/faq/:slug',
    faqs: '/faq',
    fundedProjects: null,
    highlights: '/actualites',
    history: '/histoire',
    home: '/',
    innovation: '/innovation',
    inspiration: '/inspiration',
    inspirationDetails: '/inspiration/:id',
    landingPage: '/p/:slug',
    media: '/medias',
    portfolio: '/portfolio',
    portfolioDetails: '/portfolio/:slug',
    privacyPolicy: '/legal/politique-confidentialite',
    productContact: '/produits/:slug/contact',
    productDetails: '/produits/:slug',
    recommend: '/conseiller',
    recommendResults: '/conseiller/resultats',
    reportingChannel: '/canal-de-signalement',
    socialResponsability: '/responsabilite-sociale',
    socialResponsabilityAspects: null,
    socialResponsabilityCollaboratingStandards: null,
    socialResponsabilitySuppliers: null,
    storytiling: '/storytiling',
    storytilingDetails: '/storytiling/:slug',
    termsConditions: '/legal/termes-et-conditions',
    toolsBimObjects: '/outils/objets-bim',
    toolsEnvironmentSimulator: '/outils/simulateur-environnements-360vr',
    toolsProductComparator: '/outils/comparateur-produits',
    toolsQuantitiesCalculator: '/outils/calculateur-quantites',
    toolsSystem3d: '/outils/revigres-3d-design',
    whereToBuy: '/ou-acheter',
    whereToBuyResults: '/ou-acheter/:filterType/:pageLabel/:filterValue'
  },
  pt: {
    aboutUs: '/sobre-nos',
    aboutUsSgiPolicy: '/sobre-nos/politica-do-sistema-de-gestao-integrado',
    application: '/aplicacoes/:slug',
    applications: '/aplicacoes',
    careers: '/recrutamento',
    collection: '/colecoes/:slug',
    collections: '/colecoes',
    contacts: '/contactos',
    cookiesPolicy: '/legal/politica-de-cookies',
    downloads: '/downloads',
    environment: '/ambiente',
    faq: '/perguntas-frequentes/:slug',
    faqs: '/perguntas-frequentes',
    fundedProjects: '/projectos-financiados',
    highlights: '/destaques',
    history: '/historia',
    home: '/',
    innovation: '/inovacao',
    inspiration: '/inspiracao',
    inspirationDetails: '/inspiracao/:id',
    landingPage: '/p/:slug',
    media: '/media',
    portfolio: '/portfolio',
    portfolioDetails: '/portfolio/:slug',
    privacyPolicy: '/legal/politica-de-privacidade',
    productContact: '/produtos/:slug/contacto',
    productDetails: '/produtos/:slug',
    recommend: '/recomendador',
    recommendResults: '/recomendador/resultados',
    reportingChannel: '/canal-de-denuncias',
    socialResponsability: '/responsabilidade-social',
    socialResponsabilityAspects: '/responsabilidade-social/aspectos',
    socialResponsabilityCollaboratingStandards: '/responsabilidade-social/normas-colaboradores',
    socialResponsabilitySuppliers: '/responsabilidade-social/fornecedores',
    storytiling: '/storytiling',
    storytilingDetails: '/storytiling/:slug',
    termsConditions: '/legal/termos-e-condicoes',
    toolsBimObjects: '/ferramentas/objectos-bim',
    toolsEnvironmentSimulator: '/ferramentas/simulador-de-ambientes-360vr',
    toolsProductComparator: '/ferramentas/comparador-de-produtos',
    toolsQuantitiesCalculator: '/ferramentas/calculadora-de-quantidades',
    toolsSystem3d: '/ferramentas/sistema-3d',
    whereToBuy: '/onde-comprar',
    whereToBuyResults: '/onde-comprar/:filterType/:pageLabel/:filterValue'
  }
};
