
/**
 * Module dependencies.
 */

import { Context, createContext, useContext } from 'react';
import { User } from 'src/types/user';

/**
 * Export `Token` type.
 */

export type Token = string | null;

/**
 * `Props` type.
 */

type Props = {
  isAuthenticated?: boolean;
  isSignUpCompleted?: boolean;
  onClearToken?: () => void;
  onUpdateToken?: (token: Token) => void;
  token?: string | null;
  updateUser?: () => void;
  user?: User;
}

/**
 * Export `AuthContext` context.
 */

export const AuthContext: Context<Props> = createContext({});

/**
 * Export `useAuth` hook.
 */

export const useAuth = () => useContext(AuthContext);
