
/**
 * Module dependencies.
 */

import { getKeyFromObject } from './objects';

/**
 * Network errors.
 */

const networkErrors = {
  changePassword: {
    validationFailed: 'validation_failed'
  },
  confirmAccount: {
    forbidden: 'forbidden',
    notFound: 'not_found'
  },
  newsletter: {
    alreadySubscribed: '409'
  },
  resetPassword: {
    notFound: 'not_found'
  },
  signIn: {
    notFount: 'not_found',
    unauthorized: 'unauthorized'
  },
  signUp: {
    unauthorized: 'unauthorized'
  }
} as const;

/**
 * `Error` type.
 */

type Error = keyof typeof networkErrors;

/**
 * Export `getNetworkErrorTranslatedKey`.
 *
 * Shape on translations: ${key}.errors.network.${error}.
 * The default ${error} key is `default`;
 */

export function getNetworkErrorTranslatedKey(error: Error, errorCode: string | null) {
  if (!errorCode) {
    return `${error}.errors.network.default`;
  }

  const networkError = networkErrors[error];
  const hasErrorCode = Object
    .values(networkError)
  // @ts-ignore
    .includes(errorCode);

  const errorTranslatedKey = hasErrorCode ? getKeyFromObject(networkError, errorCode) : 'default';

  return `${error}.errors.network.${errorTranslatedKey}`;
}
