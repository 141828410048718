
/**
 * Module dependencies.
 */

import { truncateString } from 'src/core/utils/strings';
import { useLocalizedRoute } from 'src/core/utils/routes';
import Card from './card';
import React, { ReactElement } from 'react';
import useSearchTranslations from 'src/hooks/use-search-translations';

/**
 * `ProjectCard` component.
 */

const ProjectCard = (props): ReactElement => {
  const {
    image_url, // eslint-disable-line id-match
    ...rest
  } = props;

  const { getTranslations } = useSearchTranslations();
  const routeResolver = useLocalizedRoute();
  const {
    description_collections, // eslint-disable-line id-match
    name,
    slug
  } = getTranslations(rest);

  return (
    <Card
      description={truncateString(description_collections, 50)}
      href={routeResolver('portfolioDetails', { slug })}
      imageUrl={image_url}
      title={name}
    />
  );
};

/**
 * Export `ProjectCard` component.
 */

export default ProjectCard;
