
/**
 * Module dependencies.
 */

import algoliasearch from 'algoliasearch';

/**
 * Export `recenteSearchesKey`.
 *
 * Key on localstorage.
 */

export const recenteSearchesKey = 'recent-searches';

/**
 * Export `client` instance.
 */

export const client = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID as string,
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_KEY as string
);

/**
 * Export `indexes`.
 */

export const indexes = {
  commercialContacts: 'commercial_contacts',
  contacts: 'contacts',
  downloads: 'downloads',
  faqs: 'faqs',
  magazines: 'magazines',
  products: 'products',
  productsCollections: 'product_collections',
  projects: 'projects'
} as const;

/**
 * Export `hitsPerPage`.
 */

export const hitsPerPage = 5;
