
/**
 * Module dependencies.
 */

import { color, media, units } from 'src/styles/utils';
import { ifNotProp, ifProp, theme } from 'styled-tools';
import { useTranslate } from 'src/core/utils/translator';
import Button from 'src/components/core/buttons/button';
import Fill from 'src/components/core/layout/fill';
import IconButton from 'src/components/core/buttons/icon-button';
import React, { ReactElement, ReactNode } from 'react';
import Type from 'src/components/core/typography/index';
import closeIcon from 'src/assets/svg/close.svg';
import styled, { css } from 'styled-components';
import useBodyScroll from 'src/hooks/use-body-scroll';

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode,
  className?: string,
  isOpen: boolean,
  isSubmitDisabled?: boolean
  isSubmitting: boolean,
  onClose: () => void,
  onSubmit: () => void,
  submitButtonLabel?: string,
  title: string
};

/**
 * `Overlay` styled component.
 */

const Overlay = styled(Fill)<{ isOpen: boolean }>`
  position: fixed !important;
  transition: ${theme('animations.defaultTransition')};
  transition-property: background-color, visibility;
  z-index: ${theme('zIndex.filters')};

  ${ifNotProp('isOpen', css`
    background-color: transparent;
    pointer-events: none;
    visibility: hidden;
  `, css`
    background-color: ${color.transparentize('black', 0.3)};
    visibility: visible;
  `)}
`;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.form<{ isOpen: boolean }>`
  background-color: ${color('white')};
  bottom: 0;
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 0;
  top: 0;
  transition: visibility ${theme('animations.defaultTransition')};
  width: 100%;

  ${media.min('ms')`
    width: ${units(74)};
  `}

  ${ifProp('isOpen', css`
    animation: ${theme('keyframes.slideInLeft')} ${theme('animations.defaultTransition')} both;
    visibility: visible;
  `, css`
    animation: ${theme('keyframes.slideInRight')} ${theme('animations.defaultTransition')} both;
    pointer-events: none;
    visibility: hidden;
  `)}
`;

/**
 * `Header` styled component.
 */

const Header = styled.div`
  align-items: center;
  display: grid;
  grid-template-areas: '. title button';
  grid-template-columns: ${units(4)} 1fr ${units(4)};
  height: ${units(13)};
  justify-items: center;
  padding: 0 ${theme('grid.gutterMobile')}px;

  ${media.min('ms')`
    padding: 0 49px;
  `}
`;

/**
 * `StyledButton` styled component.
 */

const StyledButton = styled(IconButton)`
  grid-area: button;
`;

/**
 * `HeaderLabel` styled component.
 */

const HeaderLabel = styled(Type.Paragraph).attrs({ as: 'div' })`
  grid-area: title;
`;

/**
 * `Divider` styled component.
 */

const Divider = styled.div`
  background-color: ${color('grey100')};
  height: 1px;
  margin: 0 ${units(6)};

  ${media.max('ms')`
    margin: 0 ${theme('grid.gutterMobile')}px;
  `}
`;

/**
 * `ContentWrapper` styled component.
 */

const ContentWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
`;

/**
 * `Content` styled component.
 */

const Content = styled.div`
  flex: 1;
  padding: ${units(5)} ${theme('grid.gutterMobile')}px;

  ${media.min('ms')`
    padding: ${units(6)};
  `}
`;

/**
 * `Footer` styled component.
 */

const Footer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: ${units(3)} ${units(6)};

  button:not(:last-child) {
    margin-right: ${units(2)};
  }

  ${media.max('ms')`
    align-items: flex-start;
    flex-direction: column-reverse;
    justify-content: flex-end;
    padding: ${units(3)} ${theme('grid.gutterMobile')}px;
    width: 100%;

    button {
      width: 100%;

      &:last-child {
        margin-bottom: ${units(2)};
      }
    }
  `}
`;

/**
 * `Sidebar` component.
 */

const Sidebar = (props: Props): ReactElement => {
  const { translate } = useTranslate();
  const {
    children,
    isOpen,
    isSubmitDisabled,
    isSubmitting,
    onClose,
    onSubmit,
    submitButtonLabel,
    title
  } = props;

  useBodyScroll({ off: isOpen });

  return (
    <Overlay isOpen={isOpen}>
      <Wrapper
        isOpen={isOpen}
        onSubmit={onSubmit}
      >
        <Header>
          <HeaderLabel>
            {title}
          </HeaderLabel>

          <StyledButton
            icon={closeIcon}
            iconSize={units(3.5)}
            onClick={onClose}
          />
        </Header>

        <Divider />

        <ContentWrapper>
          <Content>
            {children}
          </Content>
        </ContentWrapper>

        <Divider />

        <Footer>
          <Button
            onClick={onClose}
            variant={'outlined'}
          >
            {translate('common:actions.cancel')}
          </Button>

          <Button
            disabled={isSubmitDisabled || isSubmitting}
            isLoading={isSubmitting}
            type={'submit'}
          >
            {submitButtonLabel ?? translate('common:actions.saveChanges')}
          </Button>
        </Footer>
      </Wrapper>
    </Overlay>
  );
};

/**
 * Export `Sidebar` component.
 */

export default Sidebar;
