
/**
 * Module dependencies.
 */

import { assetUrlResolve } from 'src/core/utils/url-resolver';
import { color, media, units } from 'src/styles/utils';
import { ifNotProp, ifProp, theme } from 'styled-tools';
import { useAuth } from 'src/context/auth/context';
import { useProjectActions } from 'src/api/app/my-revigres/projects/use-project-actions';
import { useTranslate } from 'src/core/utils/translator';
import BaseCheckbox from 'src/components/core/checkbox';
import Button from 'src/components/core/buttons/button';
import Fill from 'src/components/core/layout/fill';
import FilterItemLabel from 'src/components/filters/filter-item-label';
import IconButton from 'src/components/core/buttons/icon-button';
import Input from 'src/components/core/forms/fields/input';
import Loading from 'src/components/core/loading';
import RawHtml from 'src/components/core/raw-html';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import Type from 'src/components/core/typography/index';
import arrowLeftIcon from 'src/assets/svg/arrow-left.svg';
import closeIcon from 'src/assets/svg/close.svg';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import map from 'lodash/map';
import styled, { css } from 'styled-components';
import useBodyScroll from 'src/hooks/use-body-scroll';
import useUserProjects from 'src/api/app/my-revigres/projects/use-user-projects';

/**
 * Export `ProjectCheckbox` type.
 */

export type ProjectCheckbox = {
  checked: boolean,
  id: string,
  image: string,
  title: string
}

type Props = {
  favoriteId: string,
  isOpenProjectsSidebar: boolean,
  onCloseProjectsSidebar: () => void,
  productName: string
}

/**
 * `Overlay` styled component.
 */

const Overlay = styled(Fill)<{ isOpen: boolean }>`
  background-color: ${color.transparentize('black', 0.3)};
  position: fixed !important;
  transition: opacity ${theme('animations.easeOutQuadTransition')};
  z-index: ${theme('zIndex.filters')};

  ${ifNotProp('isOpen', css`
    pointer-events: none;
    visibility: hidden;
  `)}
`;

/**
 * `Sidebar` styled component.
 */

const Sidebar = styled.div<{ isOpen: boolean }>`
  background-color: ${color('white')};
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  padding: 0 ${theme('grid.gutterMobile')}px;
  position: fixed;
  right: 0;
  top: 0;
  transition: visibility ${theme('animations.defaultTransition')};
  width: 100%;

  ${media.min('ms')`
    padding: 0 ${units(6)};
    width: ${units(74)};
  `}

  ${ifProp('isOpen', css`
    animation: ${theme('keyframes.slideInLeft')} ${theme('animations.defaultTransition')} both;
    visibility: visible;
  `, css`
    animation: ${theme('keyframes.slideInRight')} ${theme('animations.defaultTransition')} both;
    pointer-events: none;
    visibility: hidden;
  `)}
`;

/**
 * `SidebarHeader` styled component.
 */

const SidebarHeader = styled.div<{ isList: boolean }>`
  align-items: center;
  display: grid;
  grid-template-areas: 'button title .';
  grid-template-columns: ${units(4)} 1fr ${units(4)};
  height: ${units(13)};
  justify-items: center;

  ${ifProp('isList', css`
    ${media.min('ms')`
      grid-template-areas: '. title button';
    `}
  `)}
`;

/**
 * `StyledButton` styled component.
 */

const StyledButton = styled(IconButton)`
  grid-area: button;
`;

/**
 * `HeaderLabel` styled component.
 */

const HeaderLabel = styled(Type.Paragraph).attrs({ as: 'div' })`
  grid-area: title;
`;

/**
 * `SidebarContent` styled component.
 */

const SidebarContent = styled.div<{ isNewProject: boolean }>`
  border-bottom: 1px solid ${color('grey100')};
  border-top: 1px solid ${color('grey100')};
  flex: 1;
  overflow-y: auto;

  ${ifProp('isNewProject', css`
    ${media.max('ms')`
      border-bottom: 0;
    `}
  `)}
`;

/**
 * `SidebarFooter` styled component.
 */

const SidebarFooter = styled.div<{ isNewProject: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: ${units(3)} 0;

  ${ifProp('isNewProject', css`
    button:not(:last-child) {
      margin-right: ${units(2)};
    }

    ${media.max('ms')`
      align-items: flex-start;
      flex: 1;
      flex-direction: column-reverse;
      justify-content: flex-end;
      padding: 0;
      width: max-content;

      button {
        width: 100%;

        &:first-child {
          margin-top: ${units(2)};
        }
      }
    `}
  `)}
`;

/**
 * `StyledCheckbox` styled component.
 */

const StyledCheckbox = styled(BaseCheckbox).attrs({ reverse: true })`
  padding: ${units(1)} 0;
`;

/**
 * `Description` styled component.
 */

const Description = styled(Type.H6).attrs({
  as: 'p',
  small: true
})`
  color: ${color('grey400')};
  margin-bottom: ${units(6)};
  padding-top: ${units(6)};
`;

/**
 * `ProjectsSidebar` component.
 */

const ProjectsSidebar = (props: Props): ReactElement => {
  const {
    favoriteId,
    isOpenProjectsSidebar,
    onCloseProjectsSidebar,
    productName
  } = props;

  const { translate } = useTranslate();
  const { token } = useAuth();
  const [projectName, setProjectName] = useState<string>('');
  const [isNewProject, setIsNewProject] = useState<boolean>();
  const [isUpdatingCheckboxes, setIsUpdatingCheckboxes] = useState<boolean>();
  const [checkBoxesStates, setCheckBoxesStates] = useState<ProjectCheckbox[] | null>();
  const {
    data: projects,
    isLoading,
    isSuccess,
    refetch
  } = useUserProjects({ token });

  const {
    onAddProjectFavorite,
    onCreateProject,
    onRemoveProjectFavorite
  } = useProjectActions();

  const handleUpdateCheckBoxesStates = useCallback((id: string) => {
    const newStates = checkBoxesStates;
    const index = findIndex(newStates, ['id', id]);

    newStates[index].checked = !newStates[index].checked;

    setCheckBoxesStates(newStates);
  }, [checkBoxesStates]);

  const handleCheckboxClick = useCallback((projectId: string) => {
    handleUpdateCheckBoxesStates(projectId);
    setIsUpdatingCheckboxes(true);

    const project = find(projects, ['id', projectId]);
    const isProjectFavorite = find(project?.favorites, ['id', favoriteId]);

    if (!isProjectFavorite) {
      onAddProjectFavorite({
        favoriteId,
        productName,
        projectId,
        projectTitle: project?.title
      }).then(() => {
        refetch();
        setIsUpdatingCheckboxes(false);
      });

      return;
    }

    onRemoveProjectFavorite({
      favoriteId,
      productName,
      projectId,
      projectTitle: project?.title
    }).then(() => {
      refetch();
      setIsUpdatingCheckboxes(false);
    });
  }, [
    favoriteId,
    handleUpdateCheckBoxesStates,
    onAddProjectFavorite,
    onRemoveProjectFavorite,
    productName,
    projects,
    refetch
  ]);

  const handleCloseAll = useCallback(() => {
    onCloseProjectsSidebar();
    setIsNewProject(false);
  }, [onCloseProjectsSidebar]);

  const handleOpenNewProject = useCallback(() => {
    setIsNewProject(true);
  }, []);

  const handleCloseNewProject = useCallback(() => {
    setIsNewProject(false);
  }, []);

  const handleCreateProject = useCallback(() => {
    onCreateProject({ title: projectName })
      .then(() => {
        refetch();
        handleCloseNewProject();
        setProjectName(null);
      });
  }, [handleCloseNewProject, onCreateProject, projectName, refetch]);

  useBodyScroll({ off: isOpenProjectsSidebar });

  useEffect(() => {
    if (isSuccess) {
      const checkBoxes = map(projects, ({ favorites, id, media, title }) => {
        return {
          checked: !!find(favorites, ['id', favoriteId]) ?? false,
          id,
          image: media[0]?.fileUrl ?? assetUrlResolve('/static/images/empty/list.jpg'),
          title
        };
      });

      setCheckBoxesStates(checkBoxes);
    }
  }, [favoriteId, isSuccess, projects]);

  return (
    <Overlay isOpen={isOpenProjectsSidebar}>
      <Sidebar isOpen={isOpenProjectsSidebar}>
        <SidebarHeader isList={!isNewProject}>
          {isNewProject && (
            <StyledButton
              icon={arrowLeftIcon}
              iconSize={units(4)}
              onClick={handleCloseNewProject}
            />
          )}

          <HeaderLabel>
            {!isNewProject ? translate('my-revigres:projects.addFavorite.addToProjects') : translate('my-revigres:projects.addProject.title')}
          </HeaderLabel>

          {!isNewProject && (
            <StyledButton
              icon={closeIcon}
              iconSize={units(4)}
              onClick={handleCloseAll}
            />
          )}
        </SidebarHeader>

        <SidebarContent isNewProject={isNewProject}>
          {isSuccess && (
            <>
              <Loading active={isLoading} />

              {!isNewProject ? (
                <>
                  {checkBoxesStates ? map(checkBoxesStates, ({ checked, id, image, title }) => (
                    <StyledCheckbox
                      checked={checked}
                      disabled={isUpdatingCheckboxes}
                      key={id}
                      label={
                        <FilterItemLabel
                          title={title}
                          type={'image'}
                          value={image}
                        />
                      }
                      name={title}
                      onChange={() => handleCheckboxClick(id)}
                    />
                  )) : (
                    <Type.Paragraph paddingTop={units(2)}>
                      <RawHtml>
                        {translate('my-revigres:projects.emptyList')}
                      </RawHtml>
                    </Type.Paragraph>
                  )}
                </>
              ) : (
                <>
                  <Description>
                    {translate('my-revigres:projects.addProject.description')}
                  </Description>

                  <Input
                    helpText={translate('forms:projectNameHelpText')}
                    label={translate('forms:projectNameLabel')}
                    name={'name'}
                    onChange={event => setProjectName(event.target.value)}
                    required
                    value={projectName}
                  />
                </>
              )}
            </>
          )}
        </SidebarContent>

        <SidebarFooter isNewProject={isNewProject}>
          {!isNewProject ? (
            <Button onClick={handleOpenNewProject}>
              {translate('my-revigres:projects.newProject')}
            </Button>
          ) : (
            <>
              <Button
                onClick={handleCloseNewProject}
                variant={'outlined'}
              >
                {translate('common:actions.cancel')}
              </Button>

              <Button onClick={handleCreateProject}>
                {translate('my-revigres:projects.saveProject')}
              </Button>
            </>
          )}
        </SidebarFooter>
      </Sidebar>
    </Overlay>
  );
};

/**
 * Export `ProjectsSidebar` component.
 */

export default ProjectsSidebar;
