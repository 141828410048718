
/**
 * Module dependencies.
 */

import { color, units } from 'src/styles/utils';
import { ifProp, theme } from 'styled-tools';
import { kioskNavbarLinks } from 'src/core/kiosk-menu';
import { kioskRoutes } from 'src/core/routes';
import { useRouter } from 'next/router';
import { useTranslate } from 'src/core/utils/translator';
import Container from 'src/components/core/layout/container';
import HamburgerMenu from 'src/components/kiosk/navbar/hamburger-menu';
import IconButton from 'src/components/core/buttons/icon-button';
import KioskSearch from './search';
import KioskSidebar from './sidebar';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import RouterLink from 'src/components/core/links/router-link';
import Svg from 'src/components/core/svg';
import logoIcon from 'src/assets/svg/logo.svg';
import map from 'lodash/map';
import searchIcon from 'src/assets/svg/search.svg';
import styled, { css } from 'styled-components';
import useBodyScroll from 'src/hooks/use-body-scroll';

/**
 * `Nav` styled component.
 */

const Nav = styled.nav`
  background-color: ${color('beige100')};
  bottom: 0;
  box-shadow: 0 -2px ${units(2)} 0 ${color.transparentize('black', 0.08)};
  color: ${color('black')};
  height: ${theme('dimensions.kioskNavbarHeight')}px;
  left: 0;
  padding: ${units(4)} ${units(4.5)};
  position: fixed;
  right: 0;
  z-index: ${theme('zIndex.navbar')};
`;

/**
 * `StyledContainer` styled component.
 */

const StyledContainer = styled(Container).attrs({ fluid: true })`
  align-items: center;
  display: grid;
  grid-column-gap: ${units(2)};
  grid-template-areas: 'logo . actions';
  grid-template-columns: auto 1fr auto;
  padding: 0;
`;

/**
 * `LogoLink` styled component.
 */

const LogoLink = styled(RouterLink)`
  color: ${color('black')};
  grid-area: logo;
  transition: opacity ${theme('animations.defaultTransition')};
`;

/**
 * `Actions` styled component.
 */

const Actions = styled.div`
  align-items: center;
  display: flex;
  grid-area: actions;

  > * {
    &:not(:last-child) {
      margin-right: ${units(4)};
    }
  }
`;

/**
 * `NavbarLink` styled component.
 */

const NavbarLink = styled(RouterLink)<{ disabled?: boolean }>`
  align-items: center;
  color: ${color('black')};
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  padding: ${units(1)} 0 ${units(0.5)};
  text-decoration: none;
  text-transform: uppercase;
  transition: opacity 0.3s ease-out;

  ${ifProp('disabled', css`
    pointer-events: none;
  `)}
`;

/**
 * `SearchIcon` styled component.
 */

const SearchIcon = styled(IconButton)<{ disabled?: boolean }>`
  color: ${color('black')};
  line-height: 0;
  margin: 0 ${units(5)} 0 ${units(1)};
  transition: opacity 0.3s ease-out;

  ${ifProp('disabled', css`
    margin-right: ${units(3)};
    pointer-events: none;
  `)}
`;

/**
 * `KioskNavbar` component.
 */

const KioskNavbar = (): ReactElement => {
  const { locale, translate } = useTranslate();
  const router = useRouter();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>();
  const [isSearchOpen, setIsSearchOpen] = useState<boolean>();
  const toggle = useCallback(() => {
    setIsMenuOpen(isOpen => !isOpen);
  }, []);

  useBodyScroll({ off: isMenuOpen });

  useEffect(() => {
    const handleRouteChange = () => {
      if (isMenuOpen) {
        setIsMenuOpen(false);
      }
    };

    router.events.on('beforeHistoryChange', handleRouteChange);

    return () => {
      router.events.off('beforeHistoryChange', handleRouteChange);
    };
  }, [isMenuOpen, router.events]);

  return (
    <Nav>
      <StyledContainer hasPadding={false}>
        <LogoLink
          href={kioskRoutes.home}
          locale={locale}
        >
          <Svg
            icon={logoIcon}
            size={units(13)}
          />
        </LogoLink>

        <Actions>
          {map(kioskNavbarLinks, ({ label, link }) => (
            <NavbarLink
              disabled={isMenuOpen}
              href={link}
              key={label}
              locale={locale}
            >
              {translate(label)}
            </NavbarLink>
          ))}

          <SearchIcon
            icon={searchIcon}
            iconSize={units(3)}
            onClick={() => setIsSearchOpen(true)}
          />

          <HamburgerMenu
            isOpen={isMenuOpen}
            onClick={toggle}
          />
        </Actions>
      </StyledContainer>

      <KioskSidebar
        isOpen={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
      />

      <KioskSearch
        isOpen={isSearchOpen}
        onClose={() => setIsSearchOpen(false)}
      />
    </Nav>
  );
};

/**
 * Export `KioskNavbar` component.
 */

export default KioskNavbar;
