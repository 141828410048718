
/**
 * Module dependencies.
 */

import { NavbarProps } from 'src/types/navbar';
import { NavbarThemeContext } from './context';
import React, { ReactElement, ReactNode, useState } from 'react';

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode
};

/**
 * Export `darkTheme`.
 */

export const darkTheme: NavbarProps = {
  colorTheme: 'dark',
  hasShadow: false
};

/**
 * Export `lightTheme`.
 */

export const lightTheme: NavbarProps = {
  colorTheme: 'light',
  hasShadow: false
};

/**
 * `NavbarThemeProvider` provider.
 */

const NavbarThemeProvider = ({ children }: Props): ReactElement => {
  const [theme, setTheme] = useState<NavbarProps>(darkTheme);

  return (
    <NavbarThemeContext.Provider
      value={{
        setTheme,
        theme
      }}
    >
      {children}
    </NavbarThemeContext.Provider>
  );
};

/**
 * Export `NavbarThemeProvider` provider.
 */

export default NavbarThemeProvider;
