
/**
 * Module dependencies.
 */

import { Context, createContext, useContext } from 'react';

/**
 * `Props` type.
 */

type Props = {
  favoriteId?: string;
  isOpenProjectsSidebar?: boolean;
  onCloseProjectsSidebar?: () => void;
  onOpenProjectsSidebar?: () => void;
  onUpdateFavoriteId?: (id: string) => void;
  onUpdateProductName?: (name: string) => void;
  productName?: string;
}

/**
 * Export `FavoritesContext` context.
 */

export const FavoritesContext: Context<Props> = createContext({});

/**
 * Export `useFavorites` hook.
 */

export const useFavorites = () => useContext(FavoritesContext);
