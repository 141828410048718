
/**
 * Module dependencies.
 */

import i18nRoutes, { I18nRoutes } from 'i18n-routes';

/**
 * Export `routes`.
 */

export const routes: I18nRoutes = i18nRoutes;

/**
 * Export `eLearningRoutes`.
 */

export const eLearningRoutes = {
  eLearning: '/e-learning/:slug',
  eLearningLogin: '/e-learning/:slug/login'
};

/**
 * Export `authRoutes`.
 */

export const authRoutes = {
  confirmAccount: '/account/confirm',
  newPassword: '/password/confirm',
  recoverPassword: '/recover-password',
  resetPassword: '/reset-password',
  signIn: '/sign-in',
  signUp: '/sign-up'
};

/**
 * Export `myRevigresRoutes`.
 */

export const myRevigresRoutes = {
  client: '/my-revigres/clients/:id',
  clients: '/my-revigres/clients',
  favorite: '/my-revigres/favorites/:id',
  favorites: '/my-revigres/favorites',
  home: '/my-revigres',
  project: '/my-revigres/projects/:id',
  projects: '/my-revigres/projects',
  signUpComplete: '/my-revigres/sign-up/complete'
};

/**
 * Export `kioskRoutes`.
 */

export const kioskRoutes = {
  aboutUs: '/kiosk/about-us',
  collection: '/kiosk/collections/:slug',
  collections: '/kiosk/collections',
  contacts: '/kiosk/contacts',
  environment: '/kiosk/environment',
  highlights: '/kiosk/highlights',
  history: '/kiosk/history',
  home: '/kiosk',
  innovation: '/kiosk/innovation',
  inspiration: '/kiosk/inspiration',
  inspirationDetails: '/kiosk/inspiration/:id',
  privacyPolicy: '/kiosk/privacy-policy',
  productDetails: '/kiosk/products/:slug',
  recommend: '/kiosk/recommend',
  recommendResults: '/kiosk/recommend/results',
  socialResponsability: '/kiosk/social-accountability',
  toolsEnvironmentSimulator: '/kiosk/environment-simulator'
};
