
/**
 * Module dependencies.
 */

import { keyframes as keyframesCss } from 'styled-components';

/**
 * `slideInLeft` keyframes.
 */

const slideInLeft = keyframesCss`
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
`;

/**
 * `slideInRight` keyframes.
 */

const slideInRight = keyframesCss`
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(100%);
  }
`;

/**
 * `slideInUp` keyframes.
 */

const slideInUp = keyframesCss`
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(100%);
  }
`;

/**
 * `slideInDown` keyframes.
 */

const slideInDown = keyframesCss`
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
`;

/**
 * `fadeIn` keyframes.
 */

const fadeIn = keyframesCss`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

/**
 * `fadeInUp` keyframes.
 */

const fadeInUp = keyframesCss`
  from {
    opacity: 0;
    transform: translateY(100%);
  }

  10% {
    opacity: 1;
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

/**
 * `fadeOut` keyframes.
 */

const fadeOut = keyframesCss`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

/**
 * `fadeOutUp` keyframes.
 */

const fadeOutUp = keyframesCss`
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(-100%);
  }
`;

/**
 * `pageAnimationEnd` keyframes.
 */

const pageAnimationEnd = keyframesCss`
  from {
    transform: scaleX(1);
  }

  to {
    transform: scaleX(0);
  }
`;

/**
 * `pageAnimationStart` keyframes.
 */

const pageAnimationStart = keyframesCss`
  from {
    transform: scaleX(0);
  }

  to {
    transform: scaleX(1);
  }
`;

/**
 * `spin` keyframes.
 */

const spin = keyframesCss`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

/**
 * `keyframes`.
 */

const keyframes = {
  fadeIn,
  fadeInUp,
  fadeOut,
  fadeOutUp,
  pageAnimationEnd,
  pageAnimationStart,
  slideInDown,
  slideInLeft,
  slideInRight,
  slideInUp,
  spin
};

/**
 * Export `keyframes`.
 */

export default keyframes;
