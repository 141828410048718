
/**
 * Module dependencies.
 */

import { ReactElement, ReactNode, createElement } from 'react';

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode,
  element?: string
};

/**
 * `RawHtml` component.
 */

const RawHtml = (props: Props): ReactElement => {
  const { children, element, ...rest } = props;

  return createElement(element, {
    ...rest,
    dangerouslySetInnerHTML: { __html: children } // eslint-disable-line id-match
  });
};

/**
 * Default props.
 */

RawHtml.defaultProps = {
  element: 'span'
};

/**
 * Export `RawHtml` component.
 */

export default RawHtml;
