
/**
 * `kioskApiPages`.
 */

const kioskApiPages = {
  kioskHome: 'kiosk-homepage'
};

/**
 * Export `en` api page routes.
 */

export const en = {
  ...kioskApiPages,
  aboutUs: 'about-us',
  aboutUsSgiPolicy: 'policy-of-the-integrated-management-system',
  careers: 'recruitment',
  collection: null,
  collections: 'collections',
  contacts: 'contacts',
  cookiesPolicy: 'cookies-policy',
  downloads: 'downloads',
  environment: 'environment',
  faq: null,
  faqs: 'faq',
  fundedProjects: 'funded-projects',
  highlights: 'highlights',
  history: 'history',
  home: 'homepage',
  innovation: 'innovation',
  inspiration: 'inspiration',
  inspirationDetails: 'inspiration-detail',
  media: 'media',
  portfolio: 'portfolio',
  portfolioDetails: 'portfolio-detail',
  privacyPolicy: 'privacy-policy',
  productContact: 'product-contact-us',
  productDetails: null,
  recommend: 'recommend',
  recommendResults: null,
  reportingChannel: 'reporting-channel',
  socialResponsability: 'social-accountability',
  socialResponsabilityAspects: 'social-accountability-aspects',
  socialResponsabilityCollaboratingStandards: 'social-accountability-collaborating-standards',
  socialResponsabilitySuppliers: 'social-accountability-suppliers',
  storytiling: 'storytiling',
  storytilingDetails: 'storytiling-detail',
  termsConditions: 'terms-and-conditions',
  toolsBimObjects: 'bim',
  toolsEnvironmentSimulator: 'environment-simulator',
  toolsProductComparator: 'comparator',
  toolsQuantitiesCalculator: 'calculator',
  toolsSystem3d: '3d-system',
  whereToBuy: 'where-to-buy',
  whereToBuyResults: 'where-to-buy-results'
};
