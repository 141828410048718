
/**
 * Module dependencies.
 */

import { color, units } from 'src/styles/utils';
import { ifProp, prop, switchProp, theme } from 'styled-tools';
import { useRouter } from 'next/router';
import Fill from 'src/components/core/layout/fill';
import LoadingLogo from 'src/components/core/loading/loading-logo';
import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import useBodyScroll from 'src/hooks/use-body-scroll';

/**
 * `Animation` type.
 */

type Animation = 'start' | 'end';

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div<{ visible: boolean }>`
  overflow: hidden;
  position: relative;
  transition: ${theme('animations.defaultTransition')};
  transition-property: opacity, transform;

  ${ifProp('visible', css`
    opacity: 1;
    transform: none;
    transition-delay: 0s;
  `, css`
    opacity: 0;
    transform: translateY(${units(6)});
    transition-delay: 0.6s;
  `)}
`;

/**
 * `AnimatedBackground` styled component.
 */

const AnimatedBackground = styled(Fill)<{
  animation: Animation,
  bgColor: 'light' | 'dark',
  delay?: number
}>`
  align-items: center;
  animation-duration: 0.6s;
  animation-fill-mode: both;
  animation-timing-function: ${theme('animations.bezier.easeOutQuad')};
  display: flex;
  justify-content: center;
  pointer-events: none;
  position: fixed;
  transform: scaleX(0);
  z-index: ${theme('zIndex.pageTransition')};

  ${switchProp('bgColor', {
    dark: css`
      background-color: ${color('green400')};
    `,
    light: css`
      background-color: ${color('green300')};
    `
  })}

  ${switchProp('animation', {
    end: css`
      animation-delay: 0s;
      animation-name: ${theme('keyframes.pageAnimationEnd')};
      pointer-events: none;
      transform-origin: center left;
    `,
    start: css`
      animation-delay: ${prop('delay', 0)}ms;
      animation-name: ${theme('keyframes.pageAnimationStart')};
      pointer-events: all;
      transform-origin: center right;
    `
  })}
`;

/**
 * `StyledLoadingLogo` styled component.
 */

const StyledLoadingLogo = styled(LoadingLogo).attrs({ size: '100px' })`
  position: relative;
  z-index: 1000;
`;

/**
 * `PageTransition` component.
 */

const PageTransition = ({ children }: Props): ReactElement => {
  const router = useRouter();
  const [animation, setAnimation] = useState<Animation>();

  useEffect(() => {
    setAnimation('end');
  }, []);

  useEffect(() => {
    const handleRouteChangeStart = (url, { shallow }) => {
      if (shallow) {
        return;
      }

      setAnimation('start');
    };

    router.events.on('routeChangeStart', handleRouteChangeStart);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
    };
  }, [router.events, router.pathname]);

  useEffect(() => {
    const handleRouteChangeComplete = () => {
      setAnimation('end');
    };

    router.events.on('routeChangeComplete', handleRouteChangeComplete);
    router.events.on('routeChangeError', handleRouteChangeComplete);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
      router.events.on('routeChangeError', handleRouteChangeComplete);
    };
  }, [router.events]);

  useBodyScroll({ off: animation === 'start' });

  return (
    <>
      <AnimatedBackground
        animation={animation}
        bgColor={'light'}
      >
        <AnimatedBackground
          animation={animation}
          bgColor={'dark'}
          delay={250}
        />

        <StyledLoadingLogo active={animation === 'start'} />
      </AnimatedBackground>

      <Wrapper visible={animation === 'end'}>
        {children}
      </Wrapper>
    </>
  );
};

/**
 * Export `PageTransition` component.
 */

export default PageTransition;
